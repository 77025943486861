import {SelectFileToUpload, UploadableFile} from "../../utils/SelectFileToUpload";
import {Accordion, AccordionContent, AccordionTitle, Form, Header, Icon, Segment} from "semantic-ui-react";
import {useContext, useState} from "react";
import {LanguageContext} from "../../contexts/LanguageContext";
import {APIContext} from "../../contexts/APIContext";
import {GaugeContext} from "../../contexts/GaugeContext";
import {LogsContext} from "../../contexts/LogsContext";
import {messageQueue} from "@ametektci/ametek.stcappscommon";
import {DataConversions} from "../datalogging/DataConversions";

export function UploadLogFromFile() {
    const translate = useContext(LanguageContext)
    const api = useContext(APIContext)
    const gaugeContext = useContext(GaugeContext)
    const logsContext = useContext(LogsContext)
    const [file, setFile] = useState<UploadableFile>({
        fileContent: null,
        fileName: "",
        filePath: ""
    })
    const [uploading, setUploading] = useState(false)
    const [showUpload, setShowUpload] = useState(false)
    //The file field doesn't play nice with managing its internal value, so a key is needed to clear it.
    const [reloadKey, setReloadKey] = useState(1)
    const submit = async () => {
        if (file.fileContent == null)
            return
        let singleFile = file.fileContent[0]
        if (singleFile.type != "text/csv")
        {
            console.error(`Unable to process log ${file.fileName}`,"BAD TYPE", singleFile.type)
            messageQueue.sendError(translate.getString("errorProcessingLog").replace("###",file.fileName))
            return;
        }
        try {
            setUploading(true)
            let fixed = await DataConversions.ExtractLogs(singleFile, (newLog) => {
                if (newLog.startTime == "" || newLog.endTime == "")
                {
                    console.error(`Unable to process log ${file.fileName}`,"Could not find start and end time.", newLog)
                    messageQueue.sendError(translate.getString("errorProcessingLog"))
                    return
                }
                newLog.name = file.fileName
                api.UploadLog(fixed, newLog, gaugeContext.Gauge.serialNumber)
                    .then(() => {
                        setFile({
                            fileContent: null,
                            fileName: "",
                            filePath: "",
                        })
                        setReloadKey((k) => 1-k)
                        setUploading(false)
                        logsContext.reloadGauge(gaugeContext.Gauge.serialNumber)
                        messageQueue.sendSuccess(translate.getString("successfullyUploadedLog").replace("***", newLog.name ?? newLog.filename))
                    })
                    .catch((reason) => {
                        setUploading(false)
                        console.error(`Failed to upload file ${newLog.name}`, reason)
                        messageQueue.sendError(translate.getString("errorUploadingLog"))
                    })
            })
        }
        catch (e)
        {
            console.error(`Unable to process log ${file.fileName}`,e)
            messageQueue.sendError(translate.getString("errorProcessingLog").replace("###",file.fileName))
            setUploading(false)
        }
        
    }
    return <Segment>
        <Accordion>
            <AccordionTitle onClick={() => setShowUpload(!showUpload)}>
                <Header textAlign={"center"}>
                   <Icon name={showUpload? "triangle down" : "triangle right"} /> {translate.getString("uploadLogFromComputer")}
                </Header>
            </AccordionTitle>
            <AccordionContent active={showUpload}>
                <Form>
                    <SelectFileToUpload acceptableFileTypes={"CSV"} file={file} setUploadableFile={setFile} key={reloadKey} />
                    &nbsp;
                    <Form.Button 
                        onClick={submit} 
                        loading={uploading} disabled={uploading || file.fileName == "" || file.fileContent == null} 
                        content={translate.getString("upload")}
                    />
                </Form>
            </AccordionContent>
        </Accordion>
    </Segment>
    
}