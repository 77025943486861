import {useContext, useEffect} from "react";
import {Dropdown, Label} from "semantic-ui-react";
import {GaugeContext} from "../../contexts/GaugeContext";

export interface UnitSelectorProps {
    availableUnits : Array<string>
    currentUnit: string
    setNewCurrentUnit: (newCurrentUnit: string) => void
    label: string
}
export default function UnitSelector(props : UnitSelectorProps) {
    let units = props.availableUnits
    const gaugeContext = useContext(GaugeContext)
    const disableControls = gaugeContext.lock.lockConfig
    useEffect(() => {
        if (!units.includes(props.currentUnit))
        {
            setUnit(units[0])
        }
    })
    const setUnit = (newCurrent : string) => {
        props.setNewCurrentUnit(newCurrent)
    }
    return <div>
        <Label content={props.label} />
            <Dropdown 
                value={props.currentUnit} 
                options={units.map((u) => ({value: u, text: u, key: u}))} 
                disabled={disableControls}
            />
    </div>
}