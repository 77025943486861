import {useContext} from "react";
import {GaugeContext} from "../../contexts/GaugeContext";
import {LanguageContext} from "../../contexts/LanguageContext";
import {Header, Icon, Message} from "semantic-ui-react";
import {GaugePasswordEntry} from "./GaugePasswordEntry";

export function AccessWarnings() {
    const gaugeContext = useContext(GaugeContext)
    const translate = useContext(LanguageContext)
    if (gaugeContext.Config == null)
    {
        return (
            <Message 
                error={true} 
                 header={<Header textAlign={"center"}>{translate.getString("errorLoadingConfiguration")}</Header> }
                content={<div style={{textAlign:"center"} }>{translate.getString("reconnectOrTryAgainLater")}</div>}
                />
        )
    }
    if (gaugeContext.badConfig)
    {
        return <Message
            error={true}
            header={<Header textAlign={"center"}> {translate.getString("errorInConfiguration")} </Header>}
            content={<div style={{textAlign: "center"}}>{translate.getString("somethingInConfigurationIsWrong")}</div>}
            />
    }
    if (!gaugeContext.Gauge.connected)
        return (
            <Message
                header={<Header textAlign={"center"}>{translate.getString("deviceDisconnected")} </Header>}
                content={<div style={ {textAlign:"center"}}> {translate.getString("readOnlyAccessToConfiguration")} </div>}
            />
        )
    if (gaugeContext.Gauge.locked)
        return (
            <Message warning icon={<Icon name={"lock"}/>} content={<div>
                <Header>{translate.getString("gaugeLockedWarningHeader")}</Header>
                {translate.getString("gaugeLockedWarningBody")}
                <br/>
                <GaugePasswordEntry/>
            </div>}/>
        )
    return null
}