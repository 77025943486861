import {Button, Form, Modal, Popup} from "semantic-ui-react";
import React, {useContext, useEffect, useState} from "react";
import {GaugeContext} from "../contexts/GaugeContext";
import {LanguageContext} from "../contexts/LanguageContext";

export interface PasswordModalProps {
    trigger: React.JSX.Element
    visible: boolean
    setVisible: (visible: boolean) => void
}

export function PasswordModal(props: PasswordModalProps) {
    const translate = useContext(LanguageContext)
    const gaugeContext = useContext(GaugeContext)
    const [password, setPassword] = useState("")
    const [verifyPassword, setVerifyPassword] = useState("")
    const [locked, setLocked] = useState(gaugeContext.Gauge.locked || false)
    const minPassLen = 3
    let problem = ""
    if (password != verifyPassword && !gaugeContext.Gauge.locked)
        problem = translate.getString("passwordsDoNotMatch")
    if (password.length < minPassLen)
        problem = translate.getString("passwordMinimumLength").replaceAll("#", minPassLen.toString())
        
    useEffect(() => {
        setLocked(gaugeContext.Gauge.locked || false)
    }, [gaugeContext.Gauge.locked])
    useEffect(() => {
        setPassword("")
        setVerifyPassword("")
        }, [props.visible])
    const onSetPassword = (newPass: string) => {
        if (!gaugeContext.Gauge.locked) {
            setLocked((newPass != verifyPassword) || newPass.length < minPassLen)
        }
        else 
            setLocked(newPass.length < minPassLen)
        setPassword(newPass)
    }
    const onSetVerifyPassword = (newVerifyPass: string) => {
        if (!gaugeContext.Gauge.locked) {
            setLocked((newVerifyPass != password) || newVerifyPass.length < minPassLen)
        }
        else 
            setLocked(newVerifyPass.length < minPassLen)
        setVerifyPassword(newVerifyPass)
    }
    const onClick = () => {
        if (gaugeContext.Gauge.locked)
        {
            gaugeContext.lock.changePassword(password, "")
        }
        else
        {
            gaugeContext.lock.changePassword("", password)
        }
    }
    return (
        <Modal
            trigger={props.trigger}
            open={props.visible}
            onOpen={() => props.setVisible(true)}
            onClose={() => props.setVisible(false)}
            size={"tiny"}
        >
            <Modal.Header>
                {gaugeContext.Gauge.locked ? translate.getString("unlockGauge") : translate.getString("lockGauge")}
            </Modal.Header>
            <Modal.Content>
                <PasswordModalContent
                    password={password}
                    setPassword={onSetPassword}
                    verifyPassword={verifyPassword}
                    setVerifyPassword={onSetVerifyPassword}
                />
            </Modal.Content>
            <Modal.Actions>
                <Popup trigger={
                    <span>
                <Button 
                    onClick={onClick} 
                    disabled={gaugeContext.lock.passwordSettingChangeInProgress || locked || problem != ""} 
                    loading={gaugeContext.lock.passwordSettingChangeInProgress}
                    color={"blue"}
                >
                    {translate.getString("submit")}
                </Button>
                        </span>
                } openOnTriggerMouseEnter={true} disabled={problem == ""} content={problem}
                />
                <Button onClick={() => props.setVisible(false)}>
                    {translate.getString("close")}
                </Button>
            </Modal.Actions>
        </Modal>
    )
}
interface PasswordModalContentProps {
    password: string
    setPassword: (pass: string) => void
    verifyPassword: string
    setVerifyPassword: (pass: string) => void
}
function PasswordModalContent(props: PasswordModalContentProps) {
    const gaugeContext = useContext(GaugeContext)
    const translate = useContext(LanguageContext)
    const validCharsRegex = RegExp("^[A-Za-z0-9]$")
    const toPassString = (input: string) => {
        var newPass = input.split("", 16).filter(c => validCharsRegex.test(c)).join("")
        return newPass
    }
    if (!gaugeContext.Gauge.connected)
        return (<div>
            {translate.getString("connectToUseFeature")}
        </div>)
    if (gaugeContext.Gauge.locked)
        return <div>
            {translate.getString("enterPasswordToUnlock")}
            <Form>
                <Form.Input
                    type={"password"}
                    value={props.password}
                    onChange={(e, {value}) => props.setPassword(toPassString(value))}
                    label={translate.getString("password")}
                />
            </Form>
        </div>
    return (
        <div>
            <Form>
                {translate.getString("setPasswordToLock")}
                <Form.Group grouped={true} widths={"equal"}>
                    <Form.Input
                        type={"password"}
                        value={props.password}
                        onChange={(e, {value}) => props.setPassword(toPassString(value))}
                        label={translate.getString("password")}
                    />
                    <Form.Input
                        type={"password"}
                        value={props.verifyPassword}
                        label={translate.getString("verifyPassword")}
                        onChange={(e, {value}) => props.setVerifyPassword(toPassString(value))}
                    />
                </Form.Group>
            </Form>
        </div>
    )
}