import {createContext} from "react";
import {Country, State} from "../../Models/LocationModels";
import {BlueSnapShopper, DefaultShopper} from "../../Models/BlueSnapShopper";
import {Translatable} from "../../contexts/LanguageContext";

export const ShopperContext = createContext({
    shopper: DefaultShopper,
    updateShopper: (shopper: BlueSnapShopper) => Promise.resolve(),
    countries: [] as Array<Country>,
    states: [] as Array<State>,
    translate: (a : Translatable) => a as string
})