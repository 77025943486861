import {Button, Grid, Header, Message} from "semantic-ui-react";
import localeStore from "../language/LocaleStore";
import {CreditCardInfo} from "@ametektci/ametek.stcappscommon";
import {BillingPlan} from "@ametektci/ametek.stcappscommon";
import {useContext, useEffect, useState} from "react";
import {GaugeContext} from "../contexts/GaugeContext";
import {TaxQuoteResponse} from "../Models/Requests/TaxQuote";

interface ConfirmPurchaseProps {
    onPurchaseUpgrade: () => void
    model: string
    visible: boolean
    card?: CreditCardInfo
    plan?: BillingPlan
}

export function ConfirmPurchase(props: ConfirmPurchaseProps) {
    const gaugeContext = useContext(GaugeContext)
    const [loading, setLoading] = useState(false)
    const [taxInfo, setTaxInfo] = useState<TaxQuoteResponse>({tax: 0, price: 0, priceWithTax: 0})
    const moneyFormat = Intl.NumberFormat("en-us", {currency:"USD", style:"currency", minimumFractionDigits: 2})
    const [lookupFailure, setLookupFailure] = useState(false)
    useEffect(() => {
        if (props.visible != null)
            gaugeContext.getDataLoggerTax()
                .then((tax) => {
                    setTaxInfo(tax)
                    setLookupFailure(false)
                })
                .catch((e) => {
                        setLookupFailure(true)
                        console.error(e)
                    }
                )
    }, [ props.visible])
    const purchaseUpgrade = () => {
        setLoading(true)
        gaugeContext.purchaseDL(props.card!, props.plan!)
            .then(() => {
                props.onPurchaseUpgrade()
            })
            .finally(() => setLoading(false));
    };
    if (!props.visible)
        return null
    if (lookupFailure)
    {
        return (<>
            <Header size='small' color='blue' content={localeStore.Strings.cart} dividing/>
            <Message>
                <Message.Header>{localeStore.Strings.priceLookupFailureHeader}</Message.Header>
                <Message.Content>
                    <br/>
                    {localeStore.Strings.priceLookupFailureMessage}
                </Message.Content>
            </Message>
        </>)
    }
    if (taxInfo.price == 0)
    {
       return (<>
            <Header size='small' color='blue' content={localeStore.Strings.cart} dividing/>
            <Message>
                <Message.Header>{localeStore.Strings.fetchingPriceInformation}</Message.Header>
                <Message.Content>
                    <br/>
                    {localeStore.Strings.checkAddressOrTryAgainLater}
                </Message.Content>
            </Message>
        </>)
    }
    return (
        <>
            <Header size='small' color='blue' content={localeStore.Strings.cart} dividing/>

            <Grid verticalAlign='middle'>
                <Grid.Row >
                    <Grid.Column width={13}>
                        <Header size={"small"} content={localeStore.Strings.ActivationFee.replace("****", props.model)}/>
                    </Grid.Column>
                    <Grid.Column width={3} textAlign='right'>
                        <Header size={"small"} content={moneyFormat.format(taxInfo.price)}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={13} >
                        <Header content={localeStore.Strings.taxLabel} size={"small"}/>
                    </Grid.Column>
                    <Grid.Column width={3} textAlign='right'>
                        <Header size={"small"} content={moneyFormat.format(taxInfo.tax)}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={13}>
                        <Header content={localeStore.Strings.total}/>
                    </Grid.Column>
                    <Grid.Column width={3} textAlign='right'>
                        <Header content={moneyFormat.format(taxInfo.priceWithTax)}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <div style={{textAlign: "right"}}>{localeStore.Strings.priceInUsd}</div>
            <Button onClick={purchaseUpgrade} content={localeStore.Strings.confirm} color='green' loading={loading}
                    disabled={loading || props.card == null}/>
        </>
    )
}