import {useContext, useState} from 'react';
import {Button, Icon, Label, Input, Divider, Message} from 'semantic-ui-react';
import localeStore from "../../language/LocaleStore";
import {GaugeContext} from "../../contexts/GaugeContext";

export interface EditRunTagsProps {
    handleChange : (e: null, obj:  {name: string, value: string}) => void
    runTags: string
}
export default function EditRunTags(props: EditRunTagsProps){
    const gaugeContext = useContext(GaugeContext)
    const disableControls = gaugeContext.lock.lockConfig || !gaugeContext.Gauge.connected
    const [editRunTags, setEditRunTags] = useState(false)
    const [newTag, setNewTag] = useState('')
    const [newTagValid, setNewTagValid] = useState(false)
    //xp3i supports 240 characters in the run tags field (including commas). Each new run tag is likely to introduce a comma. Thus, 239 - number of already used characters.
    const charsLeft = 239-props.runTags.length;
    
    const toggleEditRunTags = () => setEditRunTags(!editRunTags)
    
    const onNewTagInput = (tag: string) => {
        setNewTag(gaugeContext.toAcceptedString(tag).substring(0, charsLeft))
        setNewTagValid(!props.runTags.split(',').includes(tag) && !!tag)
    }
    const addTag = () => {
        setNewTag('')
        setNewTagValid(false)
        props.handleChange(null,{name : 'runTags', value : props.runTags.length > 0? props.runTags+','+newTag : newTag} )
    }

    const deleteTag = (runTagName : string) => props.handleChange(null, {name : 'runTags', value : props.runTags!.split(',').filter(rt => rt !== runTagName).join(',')});

    
        return (
            <div>
                {props.runTags?.split(',').filter(rt => rt.length > 0).map(runTag => (
                    <Label key={runTag} size='large'>
                        {runTag}
                        {editRunTags ?
                            <Icon name='delete' onClick={() => deleteTag(runTag)} />
                            : null
                        }
                    </Label>
                ))}
                {editRunTags ?
                    <div>
                        <Divider hidden />
                            <Input fluid placeholder={localeStore.Strings.newRunTag} onChange={(e, {value}) => onNewTagInput(value)}
                                   value={newTag}
                                   name='newRunTag' label={<Button color='green' content={localeStore.Strings.add} onClick={addTag} disabled={!newTagValid}/>}
                                   labelPosition='right'/>
                        <Message warning content={localeStore.Strings.noCharactersLeft} hidden={(charsLeft + newTag.length) > 5} />
                        </div>
                    : null
                }
                <Divider hidden />
                <Button
                    content={editRunTags ? localeStore.Strings.done : localeStore.Strings.edit}
                    fluid onClick={toggleEditRunTags}
                    disabled={disableControls}
                />
            </div>
        )
}
