import {Dropdown, Header} from "semantic-ui-react";
import localeStore from "../../language/LocaleStore";
import {LoggingConfig} from "../../Models/Config/LoggingConfig";
import {useContext} from "react";
import {GaugeContext} from "../../contexts/GaugeContext";

export interface LoggingTypeControlProps {
    config: LoggingConfig
    onChange: (newType: string) => void
}
export function LoggingTypeControl(props: LoggingTypeControlProps) {
    const gaugeContext = useContext(GaugeContext)
    const disableControls = gaugeContext.lock.lockConfig || !gaugeContext.Gauge.connected
    if (props.config.loggingType == null)
        return null
    const LoggingTypes = ["Actual","Average", "AvgPeaks","ULP", "OnDemand"]
    
    return (
        <div>
            <Header content={localeStore.Strings.loggingType} dividing size='small'/>
            <Dropdown
                selection
                options={LoggingTypes.map(t =>({key:t, value: t, text: localeStore.getTextDynamic(`logType_${t}`)}))}
                value={props.config.loggingType}
                onChange={(e, data) => props.onChange(data.value as string) }
                disabled={disableControls}
            />
        </div>
        )
}