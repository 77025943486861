import { useContext, useState} from 'react';
import {
    Icon,
    Popup,
    Label,
    Button,
    Modal,
    Grid,
    Header,
    Segment,
} from 'semantic-ui-react';
import localeStore from "../language/LocaleStore";
import {LogContext} from "../contexts/LogContext";
import {AddRunTag} from "../logs/LogList/AddRunTag";
export default function LogRunTags() {
    const logContext = useContext(LogContext)
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState<boolean | string>(false)
    const runTags = logContext.log.runTags ? logContext.log.runTags.split(",") : []
    const open  = () => setVisible(true)
    const close = () => { if ( ! loading) setVisible(false)}

    const showAllTags = () => {
        return runTags.map(runTag => (
            <Label key={runTag}>
                <Icon color='blue' name='tag' size='mini' />
                {runTag}
            </Label>
        ));
    }

    const showPopupWithTags = () => {
        return (
            <Popup position='top right'
                    trigger={
                        <Label >
                            <Icon color='blue' name='tags' size='mini'/>
                            {runTags.length + ' ' + localeStore.Strings.runTags}
                            <Label.Detail>
                                {localeStore.Strings.show}
                            </Label.Detail>
                        </Label>
                    }>
                        {runTags.map(runTag => (
                            <Label content={runTag} key={runTag} style={{margin : '5px'}}/>
                        ))}
            </Popup>
        );
    }

    const deleteTag = (runTagName: string) => {
        setLoading('delete')

        let newRunTags = runTags.filter(rt => rt !== runTagName);

        logContext.updateLogTags(newRunTags).then(() => setLoading(false));
    };

        return (
                <Modal open={visible} onOpen={open}
                       trigger={
                           <Button basic fluid className='runTagButton'>
                               {runTags.length > 2
                                   ? showPopupWithTags()
                                   : showAllTags()
                               }

                               {runTags.length === 0 ?
                                   <Label content={localeStore.Strings.noRunTagsSet} style={{margin : '5px'}} basic/>
                                   : null
                               }
                           </Button>}>
                <Modal.Header>{localeStore.Strings.runTagsForLog.replace("***", logContext.log.name ?? logContext.log.filename)}</Modal.Header>
                <Modal.Content>
                    <Grid>
                        <Grid.Column width={10}>
                            <Header content={localeStore.Strings.currentTags} color='blue' dividing/>

                            { runTags.map(runTag => (
                                <Label key={runTag} size='large'>
                                    {runTag}

                                    <Icon name='delete' onClick={() => deleteTag(runTag)} />
                                </Label>
                            ))}

                            { runTags.length === 0 ?
                                <Segment textAlign='center' size='tiny' >
                                    <Header content={localeStore.Strings.noRunTagsSet} disabled/>
                                </Segment>
                                : null
                            }
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Header content={localeStore.Strings.addRunTag} color='green' dividing/>
                            <AddRunTag loading={loading} setLoading={setLoading} />
                        </Grid.Column>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button disabled={loading != false} onClick={close} content={localeStore.Strings.close}/>
                </Modal.Actions>
            </Modal>
        )
}
