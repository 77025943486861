import rg4js from "raygun4js";
import {AppVersion} from "./AppVersion";
import {GetEnvironment, GetEnvironmentSetting} from "./EnvironmentSettings";

export const InitializeRayGun = function() {
    // send ray gun the app version
    if (AppVersion as string !== '#APP_IN_DEVELOPMENT')
        rg4js('setVersion', AppVersion);
    rg4js('apiKey', GetEnvironmentSetting('rayGunApiKey') as string);
    rg4js('enableCrashReporting', true);
    rg4js('enablePulse', true);
    rg4js('trackEvent', { type: 'pageView', path: '/' });
    rg4js('withTags', [GetEnvironment() as string])
};
