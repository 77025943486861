import {PeaksAndAveraging} from "../../Models/Config/PeaksAndAveraging";
import {Checkbox, Header, Input, Segment} from "semantic-ui-react";
import localeStore from "../../language/LocaleStore";
import {GaugeContext} from "../../contexts/GaugeContext";
import {useContext} from "react";
import {LanguageContext} from "../../contexts/LanguageContext";

interface PeaksAndAveragesProps {
    peakAndAverage: PeaksAndAveraging
    onChange: (peaks: PeaksAndAveraging) => void
}

export default function PeaksAndAverages(props: PeaksAndAveragesProps) {
    const gaugeContext = useContext(GaugeContext)
    const translate = useContext(LanguageContext)
    const disableControls = gaugeContext.lock.lockConfig || !gaugeContext.Gauge.connected
    const togglePeaks = () => {
        let newPeaks = Object.assign({}, props.peakAndAverage)
        newPeaks.peaks = !props.peakAndAverage.peaks
        props.onChange(newPeaks)
    }
    const toggleAverages = () => {
        let newPeaks = Object.assign({}, props.peakAndAverage)
        newPeaks.average = !props.peakAndAverage.average
        props.onChange(newPeaks)
    }
    const setAverageCount = (newCount: number) => {
        //The maximum here is arbitrary. 
        if (Number.isNaN(newCount) || newCount > props.peakAndAverage.maxSamplesToAverage || newCount < 2)
            return
        let newPeaks = Object.assign({}, props.peakAndAverage)
        newPeaks.averageSamples = newCount
        props.onChange(newPeaks)
    }
    return (
        <Segment>
            <Header content={localeStore.Strings.peaksAndAveraging}/>

            <Checkbox checked={props.peakAndAverage.peaks} label={translate.getString("peakingFeatureDescription")}
                      value={"Allow Peaking"} disabled={disableControls} onChange={togglePeaks}/> <br/>
            <Checkbox label={translate.getString("averageFeatureDescription")} checked={props.peakAndAverage.average}
                      value={"Allow Averaging"} onChange={toggleAverages} disabled={disableControls}/>
            <Input type={"number"} disabled={(!props.peakAndAverage.average) || disableControls}
                   value={props.peakAndAverage.averageSamples}
                   label={translate.getString("samplesLabel")}
                   fluid
                   onChange={(e, data) => setAverageCount(Number(data.value))}/>
        </Segment>
    )
}