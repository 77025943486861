import {useContext} from 'react';
import {Segment, Header, Button, Card, Divider} from 'semantic-ui-react';
import localeStore from "../language/LocaleStore";
import RecentLogs from "../home/RecentLogs";
import GaugeManagement from "./GaugeManagement";
import {updatesStore, Products} from "@ametektci/ametek.stcappscommon";
import {GaugesContext} from "../contexts/GaugesContext";
export default function WelcomePanel() {
    const gaugeContext = useContext(GaugesContext)
    //const [hasOfflinePermission,] = useState(permissionStore.hasPermission(PermissionDescriptions.OfflineClient))
    
    const onDemoClicked = () => {
        if ( gaugeContext.demoMode)
        {
            gaugeContext.setDemoMode(false)
        }
        else
        {
            gaugeContext.setDemoMode(true)
        }
            
    };
    
        return (
            <Segment padded>
                    <RecentLogs/>

                <GaugeManagement />

                <Divider hidden/>

                <Card.Group itemsPerRow={2} stackable>
                    <Card color='red'>
                        <Card.Content>
                            <Header color='red' content={localeStore.Strings.deviceAgent} />
                            <p>{localeStore.Strings.deviceAgentDescription}</p>
                        </Card.Content>
                        <Card.Content extra>
                            <Button content={localeStore.Strings.downloadDeviceAgent} color='red' icon='download'
                                    fluid size='tiny' onClick={() => updatesStore.downloadUpdateFile(Products.DeviceAgent)}/>
                        </Card.Content>
                    </Card>
                    <Card color='yellow'>
                        <Card.Content>
                            <Header color='yellow' content={localeStore.Strings.demoMode}/>
                            <p>{localeStore.Strings.demoModeDescription}</p>
                        </Card.Content>
                        <Card.Content extra>
                            <Button content={gaugeContext.demoMode ? localeStore.Strings.exitDemoMode : localeStore.Strings.enterDemoMode} color='yellow'
                                    icon='sliders horizontal' fluid size='tiny' onClick={onDemoClicked}/>
                        </Card.Content>
                    </Card>
                    {/* The Offline Client is not yet ready! Hiding the option now!
                    <Card color='violet'>
                        <Card.Content>
                            <Header color='blue' content={localeStore.Strings.offlineAppName}/>
                            <p>{localeStore.Strings.offlineAppDescription}</p>
                        </Card.Content>
                        <Card.Content extra>
                            <Button content={hasOfflinePermission ? localeStore.Strings.downloadOfflineApp : localeStore.Strings.notIncludedInSubscription}
                                    color='blue' disabled={!hasOfflinePermission}
                                    icon='download' fluid size='tiny' onClick={() => updatesStore.downloadUpdateFile(Products.OfflineClient)}/>
                        </Card.Content>
                    </Card>
                    */}
                </Card.Group>

            </Segment>
        )
}
