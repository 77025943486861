import {Grid, Button, ButtonProps} from "semantic-ui-react";
import {convertUnitToReadable} from "../../utils/UnitDisplay";
import {useContext} from "react";
import {GaugeContext} from "../../contexts/GaugeContext";

export interface UnitSelectionProps {
    handleChange: (availableUnits: Array<string>) => void
    availableUnits: Array<string>
    currentUnits: Array<string>
}
export default function UnitSelection(props: UnitSelectionProps) {
    const gaugeContext = useContext(GaugeContext)
    const disableControls = gaugeContext.lock.lockConfig || !gaugeContext.Gauge.connected
    const handleClick = (e: any, {name}: ButtonProps) => {
        if (isAvailable(name))
            props.handleChange(props.currentUnits.filter(u => u.toUpperCase() !== name.toUpperCase()))
        else
            props.handleChange(props.currentUnits.concat([name]))
    }
    const isAvailable = (name : string) => props.currentUnits.some(b => b.toLowerCase() == name.toLowerCase())
    //Required to force blue coloring
    
    return (
            <Grid textAlign='center'>
                {props.availableUnits.map(unit => {
                    let foundUnit = {unit:unit, display: convertUnitToReadable(unit)}
                    //It would be better to use a flexbox with items that manage their own size, but I don't have that working yet.
                    return <Grid.Column tablet={8} computer={5} largeScreen={5} key={unit} >
                        <Button fluid key={unit}
                                content={foundUnit.display}
                                color='blue' onClick={handleClick} name={unit}
                                basic={!isAvailable(unit)}
                                disabled={disableControls}
                        />
                    </Grid.Column>
                    }
                    
                )}
            </Grid>
        )
    
}
