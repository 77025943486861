import {Grid, Header, Segment} from "semantic-ui-react";
import {useContext} from "react";
import {UpdatePasswordSection} from "./QuickActions/UpdatePasswordSection";
import {LanguageContext} from "../contexts/LanguageContext";
import UpgradeFirmware from "./UpgradeFirmware";

export function QuickActions()
{
    const translate = useContext(LanguageContext)
    return (
        <Segment>
            <Header textAlign={"center"}>{translate.getString("QuickActions")}</Header>
            
            <Grid columns={"equal"} textAlign={"center"}>
                <Grid.Row>
                    <Grid.Column>
                        <UpdatePasswordSection />
                    </Grid.Column>
                    <Grid.Column>
                        <UpgradeFirmware/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    ) 
}