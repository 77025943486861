import {Grid} from "semantic-ui-react";
import {ConfigBase} from "../../Models/Config/ConfigBase";
import GeneralChasisConfig from "../GeneralComponents/GeneralChasisConfig";
import DataLoggingConfig from "../GeneralComponents/DataLoggingConfig";
import {CalibrationDates} from "../../Models/Config/CalibrationDates";
import {GeneralConfig} from "../../Models/Config/GeneralConfig";
import {LoggingConfig} from "../../Models/Config/LoggingConfig";
import GaugeCalibrationDates from "../GeneralComponents/GaugeCalibrationDates";
import {useState} from "react";
import UserSpanSegment from "../GeneralComponents/UserSpanSegment";
import {SensorConfig} from "../../Models/Config/SensorConfig";
import {GaugeBase} from "../../Models/GaugeBase";

export interface GeneralPageProps {
    config: ConfigBase
    gauge: GaugeBase
    handleChange: (newConfig: ConfigBase, valid: boolean) => void
}
export default function GeneralPage(props: GeneralPageProps){
    const [calibrationDatesValid, setCalibrationDatesValid] = useState(true)
    const [userspanValid, setUserspanValid] = useState(true)
    let sensor = Object.keys(props.config.sensors)[0]
    const allValid = () =>
    {
        return userspanValid && calibrationDatesValid
    }
    const handleCalibrationDatesChange = (newConfig: CalibrationDates, valid: boolean) => {
        setCalibrationDatesValid(valid)
        props.handleChange({...props.config, calibration: newConfig}, valid)
    }
    const handleGeneralSettingsChange = (newConfig: GeneralConfig) => {
        //At the moment, we are just passing in the entire config object
        //So it makes sense to pass the entire object up.
        props.handleChange(newConfig as ConfigBase, allValid())
    }
    const handleDataLoggingSettingsChange = (newConfig: LoggingConfig) => {
        //At the moment, we are just passing in the entire config object
        //So it makes sense to pass the entire object up.
        props.handleChange(newConfig as ConfigBase, allValid())
    }

    const onUserSpanChange = (newSensor: SensorConfig, valid: boolean) => {
        setUserspanValid(valid)
        let sensorName = Object.keys(props.config.sensors)[0]
        let newConfig = {...props.config, 
            sensors: {...props.config.sensors,[sensorName]:newSensor }
        }
        props.handleChange(newConfig, allValid())
    }
    return (
        <Grid>
            <Grid.Column width={10}>
                <GeneralChasisConfig config={props.config} handleChange={handleGeneralSettingsChange} />
                {props.gauge.dataLogEnabled? 
                    <DataLoggingConfig config={props.config} handleChange={handleDataLoggingSettingsChange}/> 
                    : null
                }
            </Grid.Column>
            <Grid.Column width={6}>
                <UserSpanSegment sensor={props.config.sensors[sensor]} onUserSpanChange={onUserSpanChange} />
                <GaugeCalibrationDates config={props.config.calibration} handleChange={handleCalibrationDatesChange}/>
            </Grid.Column>
        </Grid>
    )
}