import {useContext} from 'react';
import {Segment, Header, Table, Message} from 'semantic-ui-react';
import { DateUtils } from '@ametektci/ametek.stcappscommon';
import localeStore from "../language/LocaleStore";
import {GaugesContext} from "../contexts/GaugesContext";
import {LanguageContext} from "../contexts/LanguageContext";

export default function GaugeManagement() {
    const translate = useContext(LanguageContext)
    const gaugesContext = useContext(GaugesContext)
        return (
            <span>
                <Header size='large' content={localeStore.Strings.deviceOverview} dividing/>

                {gaugesContext.gauges.length < 1 ?
                    (!gaugesContext.gaugesLoaded && !gaugesContext.demoMode) ?
                    <Segment>
                        <Header>{translate.getString("loadingOrganizationHeader")}</Header>
                        <Message>{translate.getString("loadingOrganizationMessage")}</Message>
                    </Segment>  :
                    // no gauges
                    <Segment basic>
                        <Header disabled content={localeStore.Strings.noDevicesFound} textAlign='center'/>
                    </Segment> :

                    // any gauges
                    <Table striped unstackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell singleLine>{localeStore.Strings.device}</Table.HeaderCell>
                                <Table.HeaderCell singleLine>{localeStore.Strings.firmwareVersion}</Table.HeaderCell>
                                <Table.HeaderCell singleLine>{localeStore.Strings.calibrationDate}</Table.HeaderCell>
                                <Table.HeaderCell singleLine>{localeStore.Strings.calibrationDue}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {gaugesContext.gauges.map(gauge => {
                                let updateAvailable = gaugesContext.firmwareUpdateAvailable(gauge.model.shortName, gauge.firmwareVersion) && !gaugesContext.demoMode
                                return (
                                    <Table.Row key={gauge.serialNumber} >
                                        <Table.Cell textAlign='center' content={
                                            <Header size={"small"} content={gauge.name ? gauge.name : gauge.model.name} 
                                                    subheader={gauge.serialNumber}/>
                                        }
                                        />

                                        <Table.Cell>
                                            <Header size='small'
                                                    content={gauge.firmwareVersion.split(".", 4).filter((f, idx) => idx < 3).join(".")}
                                                    subheader={updateAvailable ? localeStore.Strings.upgradeAvailable : ''}
                                                    color={updateAvailable ? 'yellow' : 'green'}/>
                                        </Table.Cell>

                                        <Table.Cell content={DateUtils.FormatDate(gauge.calibrationDate)} />

                                        <Table.Cell content={DateUtils.FormatDate(gauge.calibrationDue)} />

                                    </Table.Row>
                            )})}
                        </Table.Body>
                    </Table> }
            </span>
        )
}
