import {createContext, PropsWithChildren, useContext, useEffect, useState} from "react";
import {Certificate} from "../Models/Graphing/Certificate";
import axios from "axios";
import {AuthContext, messageQueue} from "@ametektci/ametek.stcappscommon";
import localeStore from "../language/LocaleStore";
import {APIContext} from "./APIContext";
import {GetEnvironmentSetting} from "../utils/EnvironmentSettings";

export const CertificateContext = createContext({
    certificates: [] as Array<Certificate>,
    uploadCertificate: (certificateName: string, certificateFile: any, expiresAt: any) => Promise.resolve(false),
    deleteCertificate: (certificateId: number) => Promise.resolve(false),
})

export function CertificateContextWrapper(props: PropsWithChildren) {
    const auth = useContext(AuthContext)
    const api = useContext(APIContext)
    const [certificates, setCertificates] = useState<Array<Certificate>>([])
    useEffect(() => {
        loadCertificates()
    }, [])
    const loadCertificates = async () => {
        api.LoadCertificates().then(async (response) => {
            setCertificates(response.data.certificates ?? [])
            // get connected devices

        }).catch((error) => {
            setTimeout(() => {
                messageQueue.sendError(localeStore.Strings.errorLoadingData);
            }, 1000);
        })
    }
    const uploadCertificate = async (certificateName: string, certificateFile: any, expiresAt: any): Promise<boolean> => {
        let formData = new FormData();

        formData.append('CertificateName', certificateName);
        formData.append('CertificateFile', certificateFile);
        formData.append('ExpiresAt', expiresAt);
        console.log('form data ready')

        try {
            let response = await axios.post(GetEnvironmentSetting('CcwApiUrl') + '/UploadCertificate', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": "bearer " + await auth.getAccessToken()
                }
            })
            if (response.data.success) {
                // push to certificates
                setCertificates(certificates.concat([response.data.certificate]))
                // show success alert
                messageQueue.sendSuccess(localeStore.Strings.addedCertificate);
                return true;
            } else {
                messageQueue.sendError(localeStore.Strings.errorAddingCertificate);
                return false;
            }
        } catch (error) {
            messageQueue.sendError(localeStore.Strings.errorAddingCertificate);
            return false
        }
    }
    const deleteCertificate = async (certificateId: number) => {
        try {
            let response = await axios.post(GetEnvironmentSetting('CcwApiUrl') + "/DeleteCertificate", {
                    CertificateId: certificateId
                },
                {
                    headers: {
                        "Authorization": "bearer " + await auth.getAccessToken()
                    }
                }
            )
            if (response.data.success) {
                // remove the cert from the list
                setCertificates(certificates.filter(cert => cert.certificateId !== certificateId))
                return true
            } else {
                messageQueue.sendError(localeStore.Strings.errorDeletingCertificate);
                return false
            }
        } catch (error) {
            reportError(error)
            messageQueue.sendError(localeStore.Strings.errorDeletingCertificate);
            return false
        }
    }
    
    return (
        <CertificateContext.Provider value={ {
            certificates,
            uploadCertificate,
            deleteCertificate
        } }>
            {props.children}
        </CertificateContext.Provider>
    )
}