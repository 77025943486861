import {FallbackProps} from "react-error-boundary";
import {useContext, useEffect, useState} from "react";
import {Header, Message, Segment} from "semantic-ui-react";
import {GaugesContext} from "../contexts/GaugesContext";
import {LanguageContext} from "../contexts/LanguageContext";
import {useParams} from "react-router";

export function GaugeLoadFallback(props: FallbackProps) {
    const {serial} = useParams()
    const translate = useContext(LanguageContext)
    const gaugesContext = useContext(GaugesContext)
    const [firstRunBlocker, setFirstRunBlocker] = useState(false)
    useEffect(() => {
        if (firstRunBlocker)
            props.resetErrorBoundary()
        else 
            setFirstRunBlocker(true)
    }, [gaugesContext.gauges, serial])
    let message = translate.getString("unknownProblem")
    if (gaugesContext.gauges.length == 0)
        message = translate.getString("gaugesNotLoaded")
    else if (!gaugesContext.gauges.find((g) => g.serialNumber == (serial ?? "")))
        message = translate.getString("gaugeNotFound").replace("***", serial ?? "")
    else
        console.error("Unable to load gauge",props.error, gaugesContext.gauges, serial)
    return (
        <Segment>
            <Header>
            {translate.getString("errorDisplayingGaugeDetails")}
            </Header>
            <Message content={message} />
        </Segment>
    )
}