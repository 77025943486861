import {useContext} from 'react';
import {Button, Grid, Header, Icon, Segment} from 'semantic-ui-react';
import RenameLog from "./RenameLog";
import LogRunTags from "./LogRunTags";
import GraphModal from "../logs/DataModal/GraphModal";
import ManageLogModal from "../logs/ManageLogModal";
import localeStore from "../language/LocaleStore";
import {Log} from "@ametektci/ametek.stcappscommon";
import {GaugeBase} from "../Models/GaugeBase";
import {GaugeContext} from "../contexts/GaugeContext";
import moment from "moment";
import {LanguageContext, Translatable} from "../contexts/LanguageContext";

export interface LogListItemProps {
    log: Log
    gauge: GaugeBase
}
export default function LogListItem(props: LogListItemProps) {
    const gaugeContext = useContext(GaugeContext)
    const translate = useContext(LanguageContext)
    const uploadLog = () => {
        gaugeContext.uploadLog(props.log.filename)
    };
    translate.getString("logType_Average")
    const logInfoLabel = <Header.Subheader> {moment(props.log.startTime).format("MM/DD/YYYY HH:mm")}<br/>{translate.getString("logType_" + (props.log.logType ?? "Average") as Translatable)}</Header.Subheader>
        return (
            <Segment key={props.log.logId} >
                <Grid verticalAlign='middle'>
                    <Grid.Column largeScreen={1} computer={1} tablet={1} mobile={2}>
                        <Icon name='area chart' size='large' color='black' />
                    </Grid.Column>
                    <Grid.Column largeScreen={4} computer={4} tablet={6} mobile={12}>
                        { !! props.log.name ?
                            <Header size='small' content={props.log.name} subheader={logInfoLabel}/>
                            :
                            <Header size='small' content={logInfoLabel} subheader={localeStore.Strings.startTime}/>
                        }
                    </Grid.Column>
                    <Grid.Column largeScreen={1} computer={1} tablet={2} mobile={2}>
                        <RenameLog/>
                    </Grid.Column>
                    <Grid.Column largeScreen={5} computer={4} tablet={7} mobile={16}>
                        <LogRunTags />
                    </Grid.Column>
                    <Grid.Column largeScreen={5} computer={6} tablet={16} mobile={16}>
                        { props.log.logId == null || props.log.logId == 0 ?
                            <Button color='green' size='tiny' fluid
                                    content={localeStore.Strings.upload}
                                    loading={props.log.uploading}
                                    disabled={props.log.uploading}
                                    onClick={uploadLog}/>
                            :
                            <Grid columns={2}>
                                <Grid.Column>
                                    <ManageLogModal />
                                </Grid.Column>
                                <Grid.Column>
                                    <GraphModal log={props.log} />
                                </Grid.Column>
                            </Grid>
                        }
                    </Grid.Column>
                </Grid>
            </Segment>
        )
}
