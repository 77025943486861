import {createContext, PropsWithChildren, useContext, useEffect, useState} from "react";
import {GaugeBase} from "../Models/GaugeBase";
import {GaugesContext} from "./GaugesContext";
import {BillingPlan, messageQueue, ManagementConsoleAPIContext} from "@ametektci/ametek.stcappscommon";
import {CreditCardInfo} from "@ametektci/ametek.stcappscommon";
import localeStore from "../language/LocaleStore";
import deviceHubConnection from "../signalr/DeviceHubConnection";
import {ConfigBase} from "../Models/Config/ConfigBase";
import {DemoData} from "../utils/DemoData";
import {LanguageContext} from "./LanguageContext";
import {Update} from "./UpdatesContext";
import {LogsContext} from "./LogsContext";
import {CustomUnit} from "../Models/Config/CustomUnit";
import {SensorConfig} from "../Models/Config/SensorConfig";
import {PressureSensorConfigBase} from "../Models/Config/PressureSensorConfigBase";
import {APIContext} from "./APIContext";
import {TaxQuoteResponse} from "../Models/Requests/TaxQuote";
export interface GaugeContextData {
    Config?: ConfigBase | null
    badConfig: boolean
    Gauge: GaugeBase
    dualDisplay: boolean
    updatingFirmware: boolean
    hasPermission: (permission: string) => boolean
    purchaseDL: (paymentCard: CreditCardInfo, paymentPlan: BillingPlan) => Promise<void>
    getDataLoggerTax: () => Promise<TaxQuoteResponse>
    updateFirmware: (firmware: Update) => void
    updateAvailable: boolean
    updateLogName: (logId: number, name: string) => void
    updateLogTags: (logId: number, tags: string) => void
    updateSensorName: (logId: number, sensorPosition: number, name: string) => void
    uploadLog: (fileName: string) => void
    uploadAllLogs: () => void
    deleteLog: (logId: number) => void
    updateConfig: (newConfig: ConfigBase, password: string | undefined) => void,
    toAcceptedString: (original: string) => string
    lock: {
        setLockStatus: (status: boolean) => void,
        password: string
        changePassword: (oldPass: string,newPass: string) => void,
        setPassword: (pass: string) => void,
        lockConfig: boolean,
        challengePassword: () => void
        passwordSettingChangeInProgress: boolean
    }
    getCustomUnits: () => Array<CustomUnit>;
}

export const GaugeContext = createContext<GaugeContextData>({
    Config: DemoData.config[DemoData.gauges[0].serialNumber],
    badConfig: false,
    Gauge: DemoData.gauges[0],
    dualDisplay: false,
    updatingFirmware: false,
    updateAvailable: false,
    purchaseDL: async () => {},
    getDataLoggerTax: async () => Promise.resolve({price: 0, tax: 0, priceWithTax: 0}),
    hasPermission: () => false,
    updateFirmware: () => {},
    updateLogName: () => {},
    updateLogTags: () => {},
    updateSensorName: () => {},
    uploadLog: () => {},
    uploadAllLogs: () => {},
    deleteLog: () => {},
    updateConfig: () =>{},
    toAcceptedString: (char: string) => char,
    lock: {
        setLockStatus: () => {},
        password: "",
        setPassword: () => {},
        changePassword: () => {},
        lockConfig: false,
        challengePassword: () => {},
        passwordSettingChangeInProgress: false,
    },
    getCustomUnits: () => [],
})

export interface GaugeContextWrapperProps extends PropsWithChildren {
    Sn: string,
    mainFocus: boolean,
}
let passwordNonce = "" //needs to be outside of React in order to be updated immediately.
let connectedToHub = false
export function GaugeContextWrapper(props: GaugeContextWrapperProps) {
    const translate = useContext(LanguageContext)
    const mgmtApi = useContext(ManagementConsoleAPIContext)
    const gaugesContext = useContext(GaugesContext)
    const logsContext = useContext(LogsContext)
    const api = useContext(APIContext)
    let gauge = gaugesContext.getGauge(props.Sn)!
    let config = gaugesContext.getConfig(props.Sn)
    const gaugePermissions = gaugesContext.getPermissions(props.Sn)
    const [updatingFirmware, setUpdatingFirmware] = useState(false)
    const [password, setPassword] = useState("")
    const [lockConfig, setLockConfig] = useState(gauge?.locked ?? false)
    const [passwordSettingChangeInProgress, setPasswordSettingChangeInProgress] = useState(false)
    const updateAvailable = gaugesContext.firmwareUpdateAvailable(gauge?.model?.shortName, gauge?.firmwareVersion) && !gaugesContext.demoMode
    const isConfigOk = () => {
        if (config == null)
            return true
        var sensors = Object.keys(config.sensors)
        var missingSerial = sensors.some(s =>config?.sensors[s].serialNumber == null)
        if (missingSerial)
            return false
        return true;
    }

    const badConfig = !isConfigOk()
    useEffect(() => {
        //prevent confusion if gauge is disconnected and reconnected in a new state.
        setLockConfig(gauge?.locked ?? false)
        setPasswordSettingChangeInProgress(() => false)
        passwordNonce = ""
    }, [gauge?.locked])
    const getForbiddenCharacterCodes = () =>
    {
        let forbidden = [] as Array<number>
        if (gauge.model.shortName == "XP3I")
        {
            forbidden = [
                34, // "
                47, // /
                92, // \
                60, // < - possible xml/html manipulation
                62, // > - possible xml/html manipulation
                123, // { - possible json manipulation
                125, // } - possible json manipulation
            ]
            if (gauge.firmwareVersion.includes("1.0.0"))
                forbidden.push(39) //Earlier firmware versions did not support the apostrophe.
        }
            
        return forbidden
    }
    const isCharacterLegal = (char: string) => {
        if (gauge?.model.shortName == "XP3I")
        {
            let charCode = char.charCodeAt(0);
            return (32 <= charCode && charCode <= 126 && !getForbiddenCharacterCodes().some(code => code == charCode))
        }
        return true
    }
    
    /* Basic utility functions. These don't update the gauge, but do rely on knowing information about the gauge */
    ///Ensures strings only have accepted characters.
    const toAcceptedString = (original: string) => {
        return original.split("").filter(c => isCharacterLegal(c)).join("")
    }
    const hasPermission = (permission: string) => {
        if (!gaugesContext.demoMode)
            return gaugePermissions.some(p => p == permission)
        return true
    }
    /* Firmware */
    const translateUpdateToString = (version: Update) => {
        return `${version.majorVersion}.${version.minorVersion}.${version.patchVersion}`
    }
    const updateFirmware = (firmware: Update) => {
        setUpdatingFirmware(true)
        messageQueue.sendMessage(localeStore.Strings.firmwareUpgradeStartedThroughDeviceAgent, 6000)
        deviceHubConnection.requestFirmwareUpgrade(props.Sn,
            gauge!.model.shortName, translateUpdateToString(firmware))
    }
    /* Logs */
    const updateLogName = (logId: number, newName: string) =>
    {
        logsContext.updateName(props.Sn, logId, newName)
    }
    const updateLogTags = (logId: number, tags: string) => {
        logsContext.updateTags(props.Sn, logId, tags)
    }
    const updateSensorName = (logId: number, sensorPosition: number, name: string) => {
        logsContext.updateSensorName(props.Sn, logId, sensorPosition, name)
    }
    const purchaseDL = async (paymentCard: CreditCardInfo, paymentPlan: BillingPlan) => {
        await api.EnableDataLogger(paymentCard, paymentPlan, props.Sn, gauge.model.shortName)
            .then((response) => {
            if (response.status == 200 || response.status == 204) {
                deviceHubConnection.enableDataLoggingOnDevice(props.Sn, gauge.model.shortName);
                gaugesContext.updateGauge({...gauge!, dataLogPurchased: "BOUGHT"})

                messageQueue.sendSuccess(localeStore.Strings.dataLoggingPurchased, localeStore.Strings.purchaseComplete)
            }
            else {
                messageQueue.sendError(localeStore.Strings.errorOccurredWhileMakingPurchase);
            }

        }).catch ((error) => {
            messageQueue.sendError(localeStore.Strings.errorOccurredWhileMakingPurchase);
        })
    }
    const getDataLoggerTax = async() => {
        return await mgmtApi.GetDataLoggerQuote(gauge.model.shortName)
    }
    const uploadLog = (fileName: string) => {
        logsContext.requestUploadLog(props.Sn, fileName);
    }
    const uploadAllLogs = () => {
        let relevant = logsContext.gaugeLogs[props.Sn]?.filter(l => (l.logId == null || l.logId == 0) && !l.uploading) ?? []
        relevant.map(l => l.filename)
            .forEach(name => {
                console.log("uploading", name)
                uploadLog(name)
            })
    }
    const deleteLog = (logId: number) => {
        if (logId == null || logId == 0)
            return;
        let log = logsContext.gaugeLogs[props.Sn].find(l => l.logId == logId)
        if (log == null)
            return;
        deviceHubConnection.requestDeleteLog(props.Sn, log.logId, log.filename, password )
    }
    /* Configuration */
    const updateConfig = (config: ConfigBase, password: string | undefined) => {
        messageQueue.sendMessage(localeStore.Strings.deviceConfigurationUpdate, 5000)
        if (gaugesContext.demoMode)
        {
            gaugesContext.setConfig(config, props.Sn)
            return
        }
        // invoke signal r request, unless in demo mode
        if ( ! gaugesContext.demoMode)
            deviceHubConnection.updateDeviceConfiguration(props.Sn, config, gauge.model.shortName,password);
    }
    /* Configuration Password */
    const onPasswordChange = (pass: string) => {
        let updatedPass = toAcceptedString(pass);
        setLockConfig(true)
        setPassword(() => updatedPass)
    }
    const changePassOnGauge = (oldPass: string, newPass: string) => {
        setPasswordSettingChangeInProgress(() => true)
        deviceHubConnection.updatePassword(gauge?.serialNumber!, newPass, oldPass)
    }
    const onPasswordUpdate = (success : boolean, serialNumber : string, locked : boolean) => {
        if (serialNumber == props.Sn)
        {
            setPassword(() => "")
            setPasswordSettingChangeInProgress(() => false)
            setLockConfig(() => locked)
        }
    }
    
    const onPasswordChallengeResponse = (serialNumber: string, nonce: string, passwordValid: boolean, gaugeLocked: boolean) =>
    {
        if (serialNumber != serialNumber)
        {
            //This is not our gauge, ignore it.
            return;
        }
        if (nonce != passwordNonce)
        {
            console.log("old nonce - response is either old or not for me")
            //This is not our challenge, so we should not respond.
            return
        }
        console.log("password response received")
         if (passwordValid || !gaugeLocked)
         {
             console.log("password verified")
             setLockConfig(false)
         }
         else
         {
             messageQueue.sendError(translate.getString("passwordCouldNotBeVerified"))
         }
         console.log("Setting password change to no longer in progress")
        setPasswordSettingChangeInProgress(() => false)
    }
    const reportFirmwareUpgradeComplete = (serialNumber:string,updatedGauge:GaugeBase,success:boolean,errorMessage:string) => {
        if (serialNumber == props.Sn)
            setUpdatingFirmware(false)
    }
    const connectToSignals = () => {
        if (connectedToHub)
            return
        connectedToHub = true
        deviceHubConnection.connection?.on("passwordVerified", onPasswordChallengeResponse)
        deviceHubConnection.connection?.on("passwordUpdated", onPasswordUpdate)
        deviceHubConnection.connection?.on('ReportFirmwareUpgradeComplete', reportFirmwareUpgradeComplete)
    }
    const breakFromSignals = () => {
        connectedToHub = false
        deviceHubConnection.connection?.off("passwordVerified", onPasswordChallengeResponse)
        deviceHubConnection.connection?.off("passwordUpdated", onPasswordUpdate)
        deviceHubConnection.connection?.off('ReportFirmwareUpgradeComplete', reportFirmwareUpgradeComplete)

    }
    const checkIfReady = () => {
        if (deviceHubConnection.connection?.state == "Connected")
        {
            connectToSignals()
        }
        else
        {
            breakFromSignals()
        }
    }
    useEffect(() => {
        if (!props.mainFocus)
            return //Any GaugeContext that is not the primary view does not need to be connected. GaugesContext will let us know about anything important.
        deviceHubConnection.on("ConnectionStatusChange",checkIfReady)
        checkIfReady()
        return () => {
            deviceHubConnection.off("ConnectionStatusChange",checkIfReady)
            checkIfReady()
        }
    }, [gauge, props.Sn])
    
    const challengePassword = () => {
        let newNonce = ""
        while (newNonce.length == 0)
        {
            //This will typically provide a 5 character string, though there is a small probability of a short or empty string.
            //In the event this creates an empty string, generating a new string should fix it.
            newNonce = Math.random().toString(36).slice(2, 7);
        }
        deviceHubConnection.ChallengePassword(props.Sn, password, newNonce)
        setPasswordSettingChangeInProgress(() => true)
        passwordNonce = newNonce
    }
    const setLockStatus = (locked: boolean) => {
        setLockConfig(locked)
    }
    const getCustomUnits = () : CustomUnit[] => {
        if (config == undefined)
            return []
        return Object.keys(config.sensors).map(s => {
            let sensor = config!.sensors[s] as SensorConfig
            let customUnit = {
                slope: "1",
                offset: "0",
                resolution: 1
            }
            if ("units" in sensor)
            {
                customUnit = (sensor as PressureSensorConfigBase).units.userDefinedUnit ?? customUnit
            }
            return customUnit
        })
    }
    return (
        <GaugeContext.Provider value={{
            Config: config,
            badConfig: badConfig,
            Gauge: gauge,
            dualDisplay: gauge?.model.name.includes("-DD"),
            updatingFirmware,
            updateAvailable,
            purchaseDL,
            getDataLoggerTax,
            hasPermission,
            updateFirmware,
            updateLogName,
            updateLogTags,
            updateSensorName,
            uploadLog,
            uploadAllLogs,
            deleteLog,
            updateConfig,
            toAcceptedString,
            lock: {
                setLockStatus,
                password,
                changePassword: changePassOnGauge,
                setPassword: onPasswordChange,
                lockConfig,
                passwordSettingChangeInProgress: passwordSettingChangeInProgress,
                challengePassword
            },
            getCustomUnits,
        }}>
            {props.children}
        </GaugeContext.Provider>
    )
}
