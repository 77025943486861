import {useEffect} from 'react';
import App from "./App";
import {InitializeRayGun} from "./utils/WindowUtils";
import {UserContextWrapper} from "./contexts/UserContext";
import {APIContextWrapper} from "./contexts/APIContext";
import {LanguageContextWrapper} from "./contexts/LanguageContext";
import {AmetekOAuth, ManagementConsoleAPI, EnvironmentConfig} from "@ametektci/ametek.stcappscommon";
import {ExportEnvironmentSettings} from "./utils/EnvironmentSettings";

export default function AppWithAuth() {
    useEffect(() => {
        InitializeRayGun()
    },[])
        return (
            <LanguageContextWrapper>
                <EnvironmentConfig env={ExportEnvironmentSettings()} >
                <AmetekOAuth>
                    <ManagementConsoleAPI>
                    <APIContextWrapper>
                        <UserContextWrapper>
                            <App/>
                        </UserContextWrapper>
                    </APIContextWrapper>
                    </ManagementConsoleAPI>
                </AmetekOAuth>
                </EnvironmentConfig>
            </LanguageContextWrapper>
        )
}