import {useContext, useState} from 'react';
import {Header, Modal, Button, Divider, Popup} from 'semantic-ui-react';
import DeleteLog from "./DeleteLog";
import localeStore from "../language/LocaleStore";
import {LogContext} from "../contexts/LogContext";
import CreateReportModal from "./CreateReportModal";
import {CertificateContextWrapper} from "../contexts/CertificateContext";
export default function ManageLogModal() {
    const logContext = useContext(LogContext)
    const [modalOpen, setModalOpen] = useState(false)
    const [loading, setLoading] = useState<null | string>(null)
    
    const open = () => {
        setModalOpen(true)
        setLoading(null)
    };

    const close = () => {
        if (!loading)
        {
            setModalOpen(false)
        }
    };

    const downloadCSV = () => {
        setLoading('csvDownload');

        logContext.downloadLogCsvFile()
            .then(() => setLoading(null))
    };
    
    const downloadExcel = () => {
        setLoading('excelDownload');
        logContext.downloadLogExcelFile()
            .then(() => setLoading(null))
    }

    const archiveLog = () => {
        setLoading("archive")

        logContext.archiveLog().then((success) => {
            setModalOpen(!success)
            setLoading(null)
        });
    };
        return (
            <Modal open={modalOpen} size='small'
                   trigger={<Button content={localeStore.Strings.manage} size='tiny' fluid icon='window maximize' onClick={open}/>}>
                <Modal.Header>{localeStore.Strings.manageLog}</Modal.Header>
                <Modal.Content>

                    <Header size='small' content={localeStore.Strings.exportFile} dividing/>
                    <Button
                        color='blue'
                        disabled={!!loading}
                        loading={loading === 'csvDownload'}
                        icon='file'
                        onClick={downloadCSV}
                        content={localeStore.Strings.downloadCsvFile}
                    />
                    <Popup content={localeStore.Strings.logTooLargeToConvert} disabled={logContext.supportedSize}
                           openOnTriggerMouseEnter trigger={
                        <span>
                            <Button
                                color='blue'
                                disabled={!!loading || !logContext.supportedSize}
                                loading={loading === 'excelDownload'}
                                onClick={downloadExcel}
                                icon='file excel'
                                content={localeStore.Strings.downloadExcelFile}
                            />
                        </span>}/>
                    <CertificateContextWrapper >
                        <CreateReportModal log={logContext.log} disabled={!!loading} zoomInfo={undefined}/>
                    </CertificateContextWrapper>
                            

                    <Header size='small' content={localeStore.Strings.archiveAndDelete} dividing/>

                    <p><i>{localeStore.Strings.archiveDescription}</i></p>

                    {logContext.log.archived ?
                        <Button color='red' basic size='small'
                                onClick={archiveLog} disabled={!!loading} loading={loading === 'archive'}
                            content={localeStore.Strings.removeFromArchive}
                                icon={'archive'}
                        />
                        :
                        <Button color='blue' size='small'
                                onClick={archiveLog} disabled={!!loading || logContext.log.isDemo}
                                loading={loading === 'archive'} content={localeStore.Strings.archiveLog}
                            icon={'archive'}
                        />
                    }

                    <Divider hidden/>

                    <p><i>{localeStore.Strings.deletionIsPermanent}</i></p>

                    <DeleteLog closeModal={close} disabled={!!loading || logContext.log.isDemo}/>

                </Modal.Content>
                <Modal.Actions>
                    <Button disabled={!!loading} onClick={close} content={localeStore.Strings.close}/>
                </Modal.Actions>
            </Modal>
        )
}
