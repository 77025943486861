import {Grid, Menu} from "semantic-ui-react";
import {ConfigBase} from "../../Models/Config/ConfigBase";
import {useContext, useState} from "react";
import ReadingsPage from "./ReadingsPage";
import {PressureSensorConfigBase} from "../../Models/Config/PressureSensorConfigBase";
import {SensorConfig} from "../../Models/Config/SensorConfig";
import {PeaksAndAveraging} from "../../Models/Config/PeaksAndAveraging";
import GeneralPage from "./GeneralPage";
import {GaugeBase} from "../../Models/GaugeBase";
import {LanguageContext} from "../../contexts/LanguageContext";

export interface SingleSensorLayoutProps {
    config: ConfigBase

    onConfigUpdate(newConfig: ConfigBase, valid: boolean): void

    gauge: GaugeBase
}

export default function SingleSensorLayout(props: SingleSensorLayoutProps) {
    const translate = useContext(LanguageContext)
    const [Page, setPage] = useState<"General" | "Readings">("General")
    const [readingsPageValid, setReadingsPageValid] = useState(true)
    const [generalPageValid, setGeneralPageValid] = useState(true)
    

    let sensor = Object.keys(props.config.sensors)[0]
    let VisiblePage = <div>{Page}</div>
    const onUnitChange = (newSensor: SensorConfig, valid: boolean) => {
        setReadingsPageValid(valid)
        let newConfig = {...props.config}
        newConfig.sensors = {...props.config.sensors}
        newConfig.sensors[sensor] = newSensor
        props.onConfigUpdate(newConfig, valid && generalPageValid)
    }
    const onGeneralPageChange = (newConfig: ConfigBase, valid: boolean) => {
        setGeneralPageValid(valid)
        props.onConfigUpdate(newConfig, readingsPageValid && valid)
    }
    const onPeaksAndAveragesChange = (peaksAndAverages: PeaksAndAveraging) => {
        let newConfig = {...props.config}
        newConfig.peaksAndAveraging = peaksAndAverages;
        props.onConfigUpdate(newConfig, readingsPageValid && generalPageValid)
    }

    switch (Page) {
        case "Readings":
            VisiblePage = <ReadingsPage
                sensor={props.config.sensors[sensor] as PressureSensorConfigBase}
                onUnitChange={onUnitChange}
                peaksAndAverages={props.config.peaksAndAveraging}
                onUpdatePeaksAndAverages={onPeaksAndAveragesChange}
            />
            break;
        case "General":
            VisiblePage = <GeneralPage
                config={props.config}
                gauge={props.gauge}
                handleChange={onGeneralPageChange}
            />
    }


    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Menu fluid size='large' tabular={true} pointing={true}>
                        <Menu.Item
                            onClick={() => setPage("General")}
                            name={ translate.getString("singleSensorGaugeSettings")}
                            active={Page === "General"}/>
                        <Menu.Item
                            onClick={() => setPage("Readings")}
                            name={translate.getString("singleSensorSensorSettings")}
                            active={Page === "Readings"}/>
                    </Menu>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    {VisiblePage}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}