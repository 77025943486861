import {Button, Form, Grid, Icon, Segment, Table} from "semantic-ui-react";
import {useContext, useState} from "react";
import {LanguageContext} from "../../../contexts/LanguageContext";
import {LogContext} from "../../../contexts/LogContext";
import {ChartUtils} from "../../datalogging/ChartUtils";
import {TimestampedMeasurements} from "../../../Models/DataLogging";
import {UserContext} from "../../../contexts/UserContext";
import {LogDetailsHeader} from "../LogDetailsHeader";
import React from "react";
import {convertUnitToReadable} from "../../../utils/UnitDisplay";

export interface DataViewProps {
    error: any
    dataLoaded: boolean
    loading: boolean
}

export function DataView(props: DataViewProps) {
    const userContext = useContext(UserContext)
    const translate = useContext(LanguageContext)
    const logContext = useContext(LogContext)
    const fileData =  logContext.loadedFileData
    const startTime = (fileData == undefined || fileData?.length) == 0 ? new Date() : fileData![0].date
    const endTime = (fileData == undefined || fileData?.length) == 0 ? new Date() : fileData![fileData!.length-1].date
    const [showCount, setShowCount] = useState(10)
    const [page, setPage] = useState(0)
    const firstPoint = page * showCount
    const lastPage = Math.ceil((fileData?.length ?? 0) / showCount) -1
    const showPeaks = logContext.log.logType?.includes("Peaks") ?? false
    const updateShowCount = (newCount: number) => {
        setShowCount(newCount)
        let firstOnPage = page * showCount
        let newPage = Math.floor(firstOnPage / newCount)
        console.log(newPage)
        setPage(newPage)
    }
    return (
        <div style={{paddingTop:"1em"}}>
            <Grid columns={"equal"}>
                <Grid.Row>
                    <Grid.Column>
                        <LogDetailsHeader/>
                    </Grid.Column>
                    <Grid.Column textAlign={"right"}>
                        {translate.getString("dataPointsLabel")} {fileData?.length ?? 0} <br/>
                        {translate.getString("unitLabel").replace("###",convertUnitToReadable(userContext.graphUnit??userContext.pressureUnit).toString())}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            
            
            <Segment.Group>
                <Segment basic>
                    <Form>
                        <Form.Dropdown
                            label={translate.getString("maxEntriesToShow")}
                            inline={true}
                            value={showCount}
                            options={[
                                {value: 20, text: "20"},
                                {value: 10, text: "10"}
                            ]}
                            onChange={(e, {value}) => updateShowCount(value as number)}
                        />
                    </Form>
                </Segment>
                <Segment basic>
                    <Table attached>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>
                                    {translate.getString("time")}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                </Table.HeaderCell>
                                {
                                    logContext.log.sensors.map((s) =>
                                    showPeaks?  <>
                                            <Table.HeaderCell
                                                key={s.name+"min"}>{s.name} {translate.getString("minimum-short")}</Table.HeaderCell>
                                            <Table.HeaderCell
                                                key={s.name+"avg"}>{s.name} {translate.getString("average-short")}</Table.HeaderCell>
                                            <Table.HeaderCell
                                                key={s.name+"max"}>{s.name} {translate.getString("maximum-short")}</Table.HeaderCell>
                                        </>: 
                                        <Table.HeaderCell
                                        key={s.name}>{s.name}</Table.HeaderCell>
                                    
                                    )
                                }
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {fileData?.slice(firstPoint, firstPoint + showCount).map((fd, idx) =>
                                <DataRow measurement={fd} first={startTime} last={endTime} showPeaks={showPeaks} key={idx} units={[convertUnitToReadable(userContext.graphUnit??userContext.pressureUnit).toString()]}/>
                            )}
                        </Table.Body>
                    </Table>
                </Segment>
                <Segment basic>
                    <Grid padded={false}>
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <Button
                                    disabled={page == 0}
                                    onClick={() => setPage(page - 1)}
                                    icon={<Icon name={"arrow left"}/>}
                                    fluid
                                />
                            </Grid.Column>
                            <Grid.Column width={8} textAlign={"center"}>
                                {translate.getString("pageAofB").replace("A", (page + 1).toString()).replace("B", (lastPage + 1).toString())}
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <Button
                                    disabled={page >= lastPage}
                                    onClick={() => setPage(page + 1)}
                                    icon={<Icon name={"arrow right"}/>}
                                    fluid
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </Segment.Group>

        </div>
    )
}

function DataRow(props: { measurement: TimestampedMeasurements, first: Date, last:Date, showPeaks: boolean, units: Array<string> }) {
    const userContext = useContext(UserContext)
    let dateFormat = ChartUtils.GetDateTimeValueFormatter(userContext.xAxisFormat, props.first, props.last)
    let numberFormat =    Intl.NumberFormat(userContext.numberLocale).format
    return (
        <Table.Row>
            <Table.Cell>
                {dateFormat(props.measurement.date)}
            </Table.Cell>
            <Table.Cell></Table.Cell>
                {props.showPeaks? <React.Fragment key={0}>
                        <Table.Cell>
                            {numberFormat(props.measurement.min)} &nbsp; {props.units[0]} 
                        </Table.Cell>
                        <Table.Cell >
                            {numberFormat(props.measurement.avg)} &nbsp; {props.units[0]}
                        </Table.Cell>
                        <Table.Cell >
                            {numberFormat(props.measurement.max)} &nbsp; {props.units[0]}
                        </Table.Cell>
                    </React.Fragment>
                :
                    <Table.Cell key={`${0} 0`}>
                        {numberFormat(props.measurement.avg)} &nbsp;  {props.units[0]}
                    </Table.Cell>
                }
        </Table.Row>
    )
}