import {createContext, PropsWithChildren, useContext, useEffect, useState} from "react";
import {ConfigBase} from "../Models/Config/ConfigBase";
import axios, {AxiosHeaders, AxiosResponse, InternalAxiosRequestConfig} from "axios";
import {
    AuthContext,
    BillingPlan,
    CreditCardInfo,
    Log,
    SetEnvironment,
    EnvironmentConfigurationContext
} from "@ametektci/ametek.stcappscommon";
import {ExportEnvironmentSettings} from "../utils/EnvironmentSettings";
import {LanguageContext} from "./LanguageContext";
import {Loader} from "semantic-ui-react";

const fakeAxiosResponse : AxiosResponse = {
    status: 404,
    statusText: "no",
    config: {
        headers: {} as unknown as AxiosHeaders, //I need this for typing reasons but should never use it.
    },
    data: {},
    headers: {}
}
export const APIContext = createContext({
    getGaugeConfig: (sn: string) => Promise.resolve(null as ConfigBase | null),
    GetFileURL: (fileName: string,serialNumber: string, demo: boolean) => Promise.resolve(["",0] as [string, number]),
    DownloadExcel: (logid: number) => Promise.resolve(""),
    GetLogs: (sn: string, archived: boolean) => Promise.resolve([] as Array<Log>),
    GetRecentLogs: (count: number, days: number) => Promise.resolve([] as Array<Log>),
    UploadLog: async (file: File, newLog: Log, serial:string) => Promise.resolve(),
    LoadCertificates: () => Promise.resolve(fakeAxiosResponse),
    EnableDataLogger : (paymentCard: CreditCardInfo, paymentPlan: BillingPlan, serial : string, shortName: string) => Promise.resolve(fakeAxiosResponse),
    ccwVersion: "",
})

export function APIContextWrapper (props: PropsWithChildren) {
    const globalEnv = useContext(EnvironmentConfigurationContext)
    const translate = useContext(LanguageContext)
    const authContext = useContext(AuthContext)
    const managementConsoleApi = globalEnv.env['managementConsoleApi']
    const ccwAxios = axios.create({
        baseURL: globalEnv.env["CcwApiUrl"],
    })
    const [ccwVersion, setCCWVersion] = useState("")
    const generateAuthHeader = async (request: InternalAxiosRequestConfig) => {
        request.headers.Authorization = "bearer " + await authContext.getAccessToken()
        return request
    }
    ccwAxios.interceptors.request.use(generateAuthHeader)
    useEffect(() => {
        getCrystalControlEnvironment()
    },[])
    const getCrystalControlEnvironment = async () => {
        var env = await ccwAxios.get("/Environment")
        setCCWVersion(env.data.version ?? translate.getString("unknown"))
        let oldEnv = ExportEnvironmentSettings()
        SetEnvironment({...oldEnv
            ,managementConsoleApi:env.data.managementConsoleApi,documentationUrl: env.data.documentationUrl,
            managementConsoleUrl:env.data.managementConsoleUrl, updatesUrl: env.data.updatesUrl})
        globalEnv.addValues({
            updatesUrl: env.data.updatesUrl,
            managementConsoleApi:env.data.managementConsoleApi,
            documentationUrl: env.data.documentationUrl,
            managementConsoleUrl:env.data.managementConsoleUrl,
            cognito_region: env.data.cognitoRegion,
            cognito_user_pool_id: env.data.cognitoUserPoolId,
            cognito_client_id: env.data.cognitoClientId,
            cognito_client_secret: env.data.cognitoClientSecret,
            cognito_callback_url: env.data.cognitoCallbackUrl,
            cognito_logOut_target: env.data.cognitoLogOutTarget,
        })
        
    }
    const getGaugeConfig = async (sn: string) => {
        let response = await ccwAxios.post("/GaugeConfig", {
            serialNumber: sn
        }, {validateStatus: () => true})
        return response.data.gaugeConfig ?? null
    }
    const GetFileURL = async (fileName: string,serialNumber: string, demo: boolean) : Promise<[string, number]> => {
        let response = await ccwAxios.post("/Log", {
            LogFilename: fileName,
            SerialNumber: serialNumber,
            IsDemoData: demo,
        })
        return [response.data.preSignedFileUrl, response.data.fileSize]
    }
    const DownloadExcel = async (logId: number) => {
        return (await ccwAxios.post("/Log/Export/Excel", {logId: logId})).data
    }
    const GetLogs = async (serialNumber: string, archived: boolean) : Promise<Array<Log>> =>
    {
        return (await ccwAxios.post("/Logs", {serialNumber: serialNumber, archived: archived})).data
    }
    const GetRecentLogs = async (count: number, days: number)     : Promise<Array<Log>> => {
        return (await ccwAxios.post("/RecentLogs", {count:count, maxDays: days})).data
    }
    const UploadLog = async (file: File, log:Log, serial: string) : Promise<void> => {
        let uploadURL = (await ccwAxios.post("/Log/Upload", {
            serialNumber: serial,
            log
        })).data.url
        await axios.put(uploadURL, file, {headers: {
            "Content-Type": "text/csv",
            }} )
    }
    const LoadCertificates = async () => {
        return await ccwAxios.get("/Certificates")
    }
    const EnableDataLogger = async (paymentCard: CreditCardInfo, paymentPlan: BillingPlan, serial : string, shortName: string) => {
        return await ccwAxios.post("/EnableDataLogger",
        {
            DeviceSerialNumber : serial,
            Series: shortName,
                CardLastFourDigits: paymentCard.creditCard.cardLastFourDigits,
                CardType: paymentCard.creditCard.cardType,
                PlanId: paymentPlan.planId,
                PurchaseDescription: paymentPlan.description,
        }, {validateStatus: () => true})
    }
    
    if (managementConsoleApi == "")
    return (
        <Loader content={translate.getString("connectingToCrystalControlWeb")} style={{height:"100%",centered: true}} size={"large"} active/>
    )
    return (
        <APIContext.Provider value={{
            GetFileURL,
             getGaugeConfig,
            DownloadExcel,
            GetLogs,
            GetRecentLogs,
            UploadLog,
            LoadCertificates,
            EnableDataLogger,
            ccwVersion,
            }}>
            {props.children}
        </APIContext.Provider>
    )
}