import {useContext, useEffect, useState} from 'react';
import {Button, Container, Header, Icon, Menu, Modal} from 'semantic-ui-react';
import localeStore from "../language/LocaleStore";
import { updatesStore, Products, EnvironmentConfigurationContext } from '@ametektci/ametek.stcappscommon';
import {GaugesContext} from "../contexts/GaugesContext";

export default function WelcomeTutorial() {
    const env = useContext(EnvironmentConfigurationContext)
    const [visible, setVisible] = useState(false)
    const gaugeContext = useContext(GaugesContext)
    useEffect(() => {
        if (gaugeContext.gaugesLoaded)
        {
            setVisible(gaugeContext.gauges.length == 0)
        }
    }, [gaugeContext.gaugesLoaded])
    const open = () => {
        setVisible(true)
    }

    const close = () => setVisible(false)
        return (
            <Modal open={visible} size='large'
                   trigger={
                       <Menu.Item onClick={open}>
                           <Icon name='help circle'/>
                           {localeStore.Strings.help}
                       </Menu.Item>
                   }>
                <Modal.Header>{localeStore.Strings.welcomeToApp}</Modal.Header>
                <Modal.Content>
                    <Header color='blue' dividing content={localeStore.Strings.getStarted} />
                    <Container style={{"white-space":"pre-line"}} content={localeStore.Strings.getStartedContent} />

                    <Header color='red' dividing content={localeStore.Strings.deviceAgent} />
                    <Container>
                        {localeStore.Strings.deviceAgentDescription}
                        <br/><br/>
                        <Button size='tiny' color='red' content={localeStore.Strings.downloadDeviceAgent} icon='download' onClick={() => updatesStore.downloadUpdateFile(Products.DeviceAgent)}/>
                    </Container>

                    <Header color='blue' dividing content={localeStore.Strings.documentation} />
                    <Container>
                        {localeStore.Strings.documentationDescription}
                        <br/><br/>
                        <Button size='tiny' color='blue' content={localeStore.Strings.documentation} icon='book'
                                as='a' href={env.env.documentationUrl} target='_blank'/>
                    </Container>
                    
                    <br/>
                    {localeStore.Strings.datalogerXPRequiredExplainer}
                </Modal.Content>
                <Modal.Actions>
                            <Button onClick={close} color='green' content={localeStore.Strings.startUsingApp}/>
                </Modal.Actions>
            </Modal>
        )
}