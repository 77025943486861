import {useCallback, useContext, useEffect, useState} from 'react';
import {Modal, Button, Icon, Menu, Popup} from 'semantic-ui-react';
import {Log} from "@ametektci/ametek.stcappscommon";
import GraphSettings from "../GraphSettings";
import CreateReportModal from "../CreateReportModal";
import localeStore from "../../language/LocaleStore";
import {LogContext} from "../../contexts/LogContext";
import {UserContext} from "../../contexts/UserContext";
import {DataView} from "./DataView/DataView";
import {LanguageContext} from "../../contexts/LanguageContext";
import {CertificateContextWrapper} from "../../contexts/CertificateContext";
import {GraphModalContent} from "./GraphView/GraphModalContent";
import {ZoomInfo} from "../../Models/Requests/GeneratePDFRequest";

export interface GraphModalProps {
    log: Log
}

export const enum pages {
    GRAPH,
    DATA,
}

export default function GraphModal(props: GraphModalProps) {
    const userContext = useContext(UserContext)
    const logContext = useContext(LogContext)
    const translate = useContext(LanguageContext)
    const [modalOpen, setModalOpen] = useState(false)
    const loading = logContext.loadedLogId != logContext.log.logId
    const dataLoaded = logContext.loadedFileData != null && logContext.logLoadError == null
    const error = logContext.logLoadError
    const pressureUnit = (userContext.graphUnit??userContext.pressureUnit)
    const temperatureUnit = userContext.temperatureUnit
    const [page, setPage] = useState(pages.GRAPH)
    const [timeRange, setTimeRange] = useState<[Date,Date]>([new Date(), new Date()])
    const [pressureRange, setPressureRange] = useState<[number, number]>([0,0])
    let zoomInfo = undefined as ZoomInfo | undefined
    if (timeRange && timeRange.length == 2 && pressureRange && pressureRange.length == 2)
        zoomInfo = {timeRange, pressureRange}
    useEffect(() => {
        if (!modalOpen)
            return
        logContext.loadLog();
    }, [pressureUnit, temperatureUnit, modalOpen])
    const open = () => {
        setModalOpen(true)
        logContext.loadLog();
    };
    const onTimeChange = useCallback((tr : [Date, Date]) => {setTimeRange(tr)}, [])
    const onPressureChange = useCallback((pr : [number, number]) => {setPressureRange(pr)}, [])

    const close = () => setModalOpen(false);

    return (
        <Modal open={modalOpen} size={page==pages.GRAPH? 'fullscreen' : undefined}
               trigger={
            <Popup content={localeStore.Strings.logTooLargeToShow} disabled={logContext.supportedSize} openOnTriggerMouseEnter trigger={
                <div> 
                <Button animated color='blue' size='tiny' fluid onClick={open} disabled={!logContext.supportedSize}>
                       <Button.Content visible>
                           <Icon name='eye'/>
                           {localeStore.Strings.graph}
                       </Button.Content>
                       <Button.Content hidden>
                           <Icon name='eye'/>
                       </Button.Content>
                   </Button>
                </div>
            } />
               }>
            <Modal.Content>
                <Menu attached={"top"} tabular>
                    <Menu.Item
                        active={page == pages.GRAPH}
                        name={localeStore.Strings.graph}
                        icon={<Icon name={"line graph"}/>}
                        onClick={() => setPage(pages.GRAPH)}
                        disabled={!!error}
                    />
                    <Menu.Item 
                        active={page == pages.DATA}
                        name={translate.getString("rawLogData")}
                        icon={<Icon name={"table"} />}
                        onClick={() => setPage(pages.DATA)}
                        disabled={!!error}
                    />
                </Menu>
                {page == pages.DATA ?
                    <DataView error={error} dataLoaded={dataLoaded} loading={loading}/> :
                    <GraphModalContent
                        timeRange={timeRange}
                        setTimeRange={onTimeChange}
                        pressureRange={pressureRange}
                        setPressureRange={onPressureChange}
                        dataLoaded={dataLoaded}
                        error={error}
                        loading={loading}
                    />
                }


            </Modal.Content>
            <Modal.Actions>
                    <CertificateContextWrapper >
                        <CreateReportModal log={props.log} disabled={loading || !!error} zoomInfo={zoomInfo}/>
                    </CertificateContextWrapper>

                <GraphSettings
                    disabled={loading || !!error}/>
                <Button onClick={close} content={localeStore.Strings.close}/>
            </Modal.Actions>
        </Modal>
    )
}
