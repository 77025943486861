import { Header} from "semantic-ui-react";
import localeStore from "../../language/LocaleStore";
import {useContext} from "react";
import {LogContext} from "../../contexts/LogContext";
import moment from "moment/moment";

export function LogDetailsHeader() {
    const logContext = useContext(LogContext)
    return (
        <Header>
            <Header.Content>{logContext.log.name || localeStore.Strings.unNamedLog}</Header.Content>
            <Header.Subheader
                content={localeStore.Strings.startTime + " : " + moment(logContext.log.startTime).format("MM/DD/YYYY HH:mm")}/>
            <Header.Subheader
                content={localeStore.Strings.loggingType + " : " + (logContext.log.logType ? localeStore.getTextDynamic(logContext.log.logType) : localeStore.Strings.unknown)}/>
        </Header>
    )
}