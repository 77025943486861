import {Button, Grid} from "semantic-ui-react";
import {useContext} from "react";
import {GaugeContext} from "../../contexts/GaugeContext";
import {LogsContext} from "../../contexts/LogsContext";
import {LanguageContext} from "../../contexts/LanguageContext";
import {ConfirmDeleteLogs} from "./ConfirmDeleteLogs";

export function MassLogManagement() {
    const gaugeContext = useContext(GaugeContext)
    const logsContext = useContext(LogsContext)
    const translate = useContext(LanguageContext)
    const gaugeLogs = logsContext.gaugeLogs[gaugeContext.Gauge.serialNumber] ?? []
    let showUploadAll = false;
    let showDeleteLogs = false
    if (gaugeLogs.some(l => l.logId == 0 || l.logId == undefined))
        showUploadAll = true
    if (gaugeLogs.some(l => l.logId != 0 && l.logId != null))
        showDeleteLogs = true;
    return (
        <Grid>
            <Grid.Row columns={"equal"}>
                {showUploadAll? <Grid.Column>
                    <Button fluid color={"green"} content={translate.getString("uploadAll")} onClick={() => gaugeContext.uploadAllLogs()} />
                </Grid.Column> : null}
                {showDeleteLogs && !gaugeContext.lock.lockConfig? <Grid.Column>
                    <ConfirmDeleteLogs />
                </Grid.Column> : null}
            </Grid.Row>
        </Grid>
    )
}