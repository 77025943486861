import {Table} from "semantic-ui-react";
import {TimestampedMeasurements} from "../../../Models/DataLogging";
import {LanguageContext} from "../../../contexts/LanguageContext";
import {useContext} from "react";
import {UserContext} from "../../../contexts/UserContext";
import {ChartUtils} from "../../datalogging/ChartUtils";
import {LogContext} from "../../../contexts/LogContext";
import RenameSensorModal from "../../RenameSensorModal";

interface SelectedPointViewerProps {
    point: TimestampedMeasurements | undefined
    firstDate: Date
    lastDate: Date
}
export function SelectedPointViewer(props: SelectedPointViewerProps)
{
    const logContext = useContext(LogContext)
    const translate = useContext(LanguageContext)
    const userContext = useContext(UserContext)
    const dateFormat = ChartUtils.GetDateTimeValueFormatter(userContext.xAxisFormat, props.firstDate, props.lastDate)
    return (
        <Table fixed celled size='small' unstackable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>
                        {translate.getString("time")}
                    </Table.HeaderCell>
                    {logContext.log.sensors.map((s,idx) => <Table.HeaderCell key={idx}>
                        {s.name ?? "-"} &nbsp; <RenameSensorModal sensorPosition={s.sensorPosition} />
                    </Table.HeaderCell>)}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>
                        {props.point ? dateFormat(props.point.date) : "-"}
                    </Table.Cell>
                    {logContext.log.sensors.map((s, idx) => <Table.Cell key={idx}>
                        {props.point?.avg? Intl.NumberFormat(userContext.numberLocale).format(props.point.avg) : "-"}
                    </Table.Cell>)}
                </Table.Row>
            </Table.Body>
        </Table>
    )
}