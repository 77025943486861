import {useContext} from 'react';
//@ts-expect-error
import {Dropdown, DropdownProps, Form, Grid} from 'semantic-ui-react';
import localeStore from "../language/LocaleStore";
import {GaugeBase} from "../Models/GaugeBase";
import {GaugeContext} from "../contexts/GaugeContext";
import {LogsContext} from "../contexts/LogsContext";

const logsToDisplayOptions = [
    {key: 1, text: '5', value: 5},
    {key: 2, text: '10', value: 10},
    {key: 3, text: '15', value: 15}
];

const filterOptions = [
    {key: 'all', text: localeStore.Strings.allLogs, value: 'all'},
    {key: 'uploaded', text: localeStore.Strings.uploaded, value: 'uploaded'},
    {key: 'onDevice', text: localeStore.Strings.onDevice, value: 'onDevice'},
    {key: 'archived', text: localeStore.Strings.archived, value: 'archived'}
];

const sortOptions = [
    {key: 'recordTime', text: localeStore.Strings.newestLogs, value: 'recordTime'},
    {key: 'oldLogs', text: localeStore.Strings.oldestLogs, value: 'oldLogs'},
    {key: 'name', text: localeStore.Strings.nameAZ, value: 'name a-z'},
    {key: 'nameReverse', text: localeStore.Strings.nameZA, value: 'name z-a'}
];

export interface LogControlsProps {
    numberOfLogsToDisplay: number
    gauge: GaugeBase
    changeDisplayedLogs: (value: number) => void
    sortLogs: (value: string) => void
    sortBy: string
    changeRunTagFilter: (value: Array<string>) => void

    filterDisplayedLogs: (value: string) => void;
    filter: string
}

export default function LogControls(props: LogControlsProps) {
    const gaugeContext = useContext(GaugeContext)
    const logsContext = useContext(LogsContext)
    const availableRunTags = logsContext.getAllRunTags(gaugeContext.Gauge.serialNumber, props.filter == "archived")
    const onLogsToDisplayChange = (e: any, {value}: DropdownProps) => {
        props.changeDisplayedLogs(value as number)
    };

    const onSortChange = (e: any, {value}: DropdownProps) => {
        props.sortLogs(value as string);
    };

    const onFilterClick = (e: any, {value}: DropdownProps) => {
        props.filterDisplayedLogs(value as string);
    };

    const handleRunTagChange = (e: any, {value}: DropdownProps) => {
        props.changeRunTagFilter(value as Array<string>)
    };

    return (
        <Form>
            <Form.Group inline={true} widths={"equal"}>
                <Form.Dropdown
                    selection fluid options={filterOptions}
                    onChange={onFilterClick}
                    value={props.filter}
                    label={localeStore.Strings.show}
                />
                <Form.Dropdown
                    label={localeStore.Strings.runTags}
                    placeholder={localeStore.Strings.filterByRunTag}
                    noResultsMessage={localeStore.Strings.noRunTagsFound}
                    fluid multiple selection search
                    onChange={handleRunTagChange}
                    options={availableRunTags.map(rt => ({value: rt, text: rt}))}/>
                <Form.Dropdown
                    selection fluid
                    onChange={onSortChange}
                    options={sortOptions}
                    value={props.sortBy}
                    label={localeStore.Strings.sortBy}
                />
                <Form.Dropdown 
                    selection fluid
                               onChange={onLogsToDisplayChange}
                               options={logsToDisplayOptions}
                               value={props.numberOfLogsToDisplay}
                               label={localeStore.Strings.numberOfLogsToDisplay}/>
            </Form.Group>
        </Form>
    )
}
