import rg4js from 'raygun4js';
//UpdatesSTore still needs the UpdatesURL here.
const localEnv = {
    'CcwApiUrl' : 'https://localhost:5002',
    'rayGunApiKey' : '',
}


export const GetEnvironmentSetting = function(key: string) {
    let value = ExportEnvironmentSettings()[key];
    // if we couldn't find it, alert ray gun, something is missing
    if ( value === undefined) {
        console.error('unknown environment variable', key);
        rg4js('send', new Error(`Unknown environment variable for KEY ${key}. (running at location : ${window.location.href})`))
    }
    return value;
};

/**
 * @return {string}
 */
export const GetEnvironment = function() {
    if (window.location.hostname.includes('localhost'))
        return 'local';
    else if (window.location.hostname.includes('dev'))
        return 'dev';
    else if (window.location.hostname.includes('staging'))
        return 'staging';
    else if (window.location.hostname.includes('feature'))
        return 'feature'
    return 'production';
};

export const ExportEnvironmentSettings = function() {
    let env = GetEnvironment();
    if (env == 'local')
        return localEnv
    else 
        return window.ENV
};
