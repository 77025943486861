import {Button, Container, Image, Menu} from "semantic-ui-react";
import {AuthContext, Images} from "@ametektci/ametek.stcappscommon";
import localeStore from "../language/LocaleStore";
import {useContext} from "react";

export function HeaderBox() {
    const auth = useContext(AuthContext)
    const signIn = async () => {
        let url = await auth.authorize()
        window.location.href = url!
    }
    const signUp = async () => {
        let url = await auth.GetSignUpLink()
        window.location.href = url!
    }
    return (<Menu attached={"top"} inverted borderless size='huge' style={{flexGrow:0}} >
        <Container>
            <Menu.Item>
                <Image src={Images.ccwLogoWhite} size='large'/>
            </Menu.Item>

            <Menu.Menu position='right'>
                <Menu.Item>
                    <Button content={localeStore.Strings.signIn} inverted onClick={signIn} disabled={!auth.ready}/>
                </Menu.Item>
                <Menu.Item>
                    <Button content={localeStore.Strings.signUp} inverted color={"green"} onClick={signUp} disabled={!auth.ready}/>
                </Menu.Item>
            </Menu.Menu>
        </Container>
    </Menu>)
}