import {GaugeManagementPage} from "./GaugeManagementPage";
import {GaugeContextWrapper} from "../contexts/GaugeContext";
import {useParams} from "react-router";
import {ErrorBoundary} from "react-error-boundary";
import {GaugeLoadFallback} from "../global/GaugeLoadFallback";
export function GaugePage() {
    const {serial} = useParams()
    return (
        <ErrorBoundary FallbackComponent={GaugeLoadFallback} key={serial}>
            <GaugeContextWrapper mainFocus={true} Sn={serial ?? ""}>
                <GaugeManagementPage/>
            </GaugeContextWrapper>
        </ErrorBoundary>)
}