import {useContext} from 'react';
import {Button, Divider, Grid, Header, Segment} from 'semantic-ui-react';
import localeStore from "../language/LocaleStore";
import GraphModal from "../logs/DataModal/GraphModal";
import {GaugesContext} from "../contexts/GaugesContext";
import {LogContextWrapper} from "../contexts/LogContext";
import { GaugeContextWrapper} from "../contexts/GaugeContext";
import {useNavigate} from "react-router-dom";
import {LogsContext} from "../contexts/LogsContext";
import {LanguageContext} from "../contexts/LanguageContext";
import moment from "moment/moment";

export default function RecentLogs() {
    const translate = useContext(LanguageContext)
    const gaugesContext = useContext(GaugesContext)
    const logsContext = useContext(LogsContext)
    const recentLogs = logsContext.recentLogs.map(l => {
        return {
            log: l,
            gauge: gaugesContext.gauges.find(g => g.gaugeId == l.gaugeId)
        }
    })
    const navigate = useNavigate()
    const openDevice = (serialNumber: string) => {
        navigate(`/gauge/${serialNumber}`)
    };

    if (recentLogs.length === 0) return null;

    return (
        <span>
                <Header size='large' content={localeStore.Strings.recentLogs} dividing/>

            {recentLogs.map((recent, idx) => (
                recent.gauge == undefined ? <div key={idx} /> :
                    <GaugeContextWrapper Sn={recent.gauge.serialNumber} mainFocus={false} key={idx}>
                        <LogContextWrapper log={recent.log}>
                            <Segment key={recent.log.filename}>
                                <Grid verticalAlign='middle'>
                                    <Grid.Column computer={4} tablet={7} mobile={16}>
                                        <Header>
                                            {(recent.gauge.name ? recent.gauge?.name : recent.gauge?.model.shortName) ?? translate.getString("unknown")}
                                        <Header.Subheader>
                                            {recent.gauge?.serialNumber}
                                        </Header.Subheader>
                                        </Header>
                                    </Grid.Column>
                                    <Grid.Column computer={4} tablet={6} mobile={12}>
                                        {!!recent.log.name ?
                                            <Header size='small' content={recent.log.name}
                                                    subheader={moment(recent.log.startTime).format("MM/DD/YYYY HH:mm")}/>
                                            :
                                            <Header size='small'
                                                    content={moment(recent.log.startTime).format("MM/DD/YYYY HH:mm")}
                                                    subheader={localeStore.Strings.startTime}/>
                                        }
                                    </Grid.Column>
                                    <Grid.Column computer={8} tablet={16} mobile={16}>
                                        <Grid columns={2}>
                                            <Grid.Column>
                                                <Button content={localeStore.Strings.device} icon='window maximize'
                                                        size='tiny' fluid
                                                        onClick={() => openDevice(recent.gauge!.serialNumber)}/>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <GraphModal log={recent.log}/>
                                            </Grid.Column>
                                        </Grid>
                                    </Grid.Column>
                                </Grid>
                            </Segment>
                        </LogContextWrapper>
                    </GaugeContextWrapper>
            ))}

            <Divider hidden/>
            </span>
    )
}
