import {createContext, PropsWithChildren, useContext, useEffect, useState} from "react";
import {Log, messageQueue} from "@ametektci/ametek.stcappscommon";
import {APIContext} from "./APIContext";
import {GaugesContext} from "./GaugesContext";
import deviceHubConnection from "../signalr/DeviceHubConnection";
import {GaugeBase} from "../Models/GaugeBase";
import {ConfigBase} from "../Models/Config/ConfigBase";
import {PropertiesFromDeviceAgent} from "../Models/PropertiesFromDeviceAgent";
import {ConnectedDevice} from "../Models/ConnectedDevice";
import localeStore from "../language/LocaleStore";

export interface logCache {
    [key: string]: Array<Log>
}

export const LogsContext = createContext({
    gaugeLogs: {} as logCache,
    uploadedLogs: {} as logCache,
    archivedLogs: {} as logCache,
    recentLogs: [] as Array<Log>,
    loadGauge: (sn: string) => Promise.resolve(),
    reloadGauge: (sn: string) => Promise.resolve(),
    loadArchived: (sn: string) => Promise.resolve(),
    getAllRunTags: (sn: string, archived: boolean) => [] as Array<string>,
    requestUploadLog: (sn: string, filename: string) => {
    },
    toggleLogArchived: (sn: string, logId: number, isArchived: boolean) => {
    },
    deleteLog: (sn: string, logId: number) => {
    },
    updateName: (sn: string, logId: number, name: string) => {
    },
    updateTags: (sn: string, logId: number, tags: string) => {
    },
    updateSensorName: (sn: string, logId: number, sensorPos: number, name: string) => {
    },
})

export function LogsContextWrapper(props: PropsWithChildren) {
    const api = useContext(APIContext)
    const gaugesContext = useContext(GaugesContext)

    const [gaugeLogs, setGaugeLogs] = useState<logCache>({})
    const [uploadedLogs, setUploadedLogs] = useState<logCache>({})
    const [archivedLogs, setArchivedLogs] = useState<logCache>({})
    const [recentLogs, setRecentLogs] = useState<Array<Log>>([])

    useEffect(() => {
        api.GetRecentLogs(5, 365).then((logs) => setRecentLogs(logs))
    }, [gaugesContext.gaugesLoaded])
    useEffect(() => {
        connectToSignals()
        return breakSignalConnections
    })
    const deleteLog = (sn: string, logId: number) => {
        setUploadedLogs(ul => ({...ul, [sn]: ul[sn]?.filter(l => l.logId !== logId)}))
        setArchivedLogs(al => ({...al, [sn]: al[sn]?.filter(l => l.logId !== logId)}))
    }
    const toggleLogArchived = (sn: string, logId: number, isArchived: boolean) => {
        if (isArchived) {
            let log = uploadedLogs[sn]?.find(l => l.logId == logId)
            if (log == undefined)
                return
            log = {...log, archived: isArchived}
            setUploadedLogs(ul => ({...ul, [sn]: ul[sn]?.filter(l => l.logId != logId)}))
            setArchivedLogs(al => ({...al, [sn]: al[sn]?.concat([log!])}))
        } else {
            let log = archivedLogs[sn]?.find(l => l.logId == logId)
            if (log == undefined)
                return
            log = {...log, archived: isArchived}
            setArchivedLogs(al => ({...al, [sn]: al[sn]?.filter(l => l.logId != logId)}))
            setUploadedLogs(ul => ({...ul, [sn]: ul[sn]?.concat([log!])}))
        }
    }
    const reloadGauge = async(sn: string) => {
        let logs = await api.GetLogs(sn, false)
        setUploadedLogs((old) => ({...old, [sn]: logs}))
    }
    const loadGauge = async (sn: string) => {
        if (uploadedLogs[sn] != undefined)
            return
        await reloadGauge(sn)
    }
    const loadArchived = async (sn: string) => {
        if (archivedLogs[sn] != undefined)
            return
        let logs = await api.GetLogs(sn, true)
        setArchivedLogs((old) => ({...old, [sn]: logs}))
    }
    const getAllRunTags = (sn: string, archived: boolean) => {
        let logs = [] as Array<Log>
        if (archived)
            logs = archivedLogs[sn] ?? []
        else
            logs = (uploadedLogs[sn] ?? []).concat(gaugeLogs[sn]?.filter(l => l.logId == null || l.logId == 0) ?? [])
        let allRunTags = logs.flatMap(l => !!l.runTags ? l.runTags.split(',') : []);
        // find the unique values;
        return [...new Set(allRunTags)];
    }
    const updateName = (sn: string, logId: number, newName: string) => {
        setUploadedLogs(ul => ({...ul, [sn]: ul[sn]?.map(l => l.logId == logId ? {...l, name: newName} : l)}))
        setArchivedLogs(al => ({...al, [sn]: al[sn]?.map(l => l.logId == logId ? {...l, name: newName} : l)}))
    }
    const updateTags = (sn: string, logId: number, tags: string) => {
        setUploadedLogs(ul => ({...ul, [sn]: ul[sn]?.map(l => l.logId == logId ? {...l, runTags: tags} : l)}))
        setArchivedLogs(al => ({...al, [sn]: al[sn]?.map(l => l.logId == logId ? {...l, runTags: tags} : l)}))
    }
    const updateSensorName = (sn: string, logId: number, sensorPosition: number, name: string) => {
        setUploadedLogs(ul => ({
            ...ul, [sn]: ul[sn]?.map(l =>
                l.logId == logId ? {
                    ...l, sensors: l.sensors.map((s) => (
                        s.sensorPosition == sensorPosition ? {...s, name: name} : s
                    ))
                } : l)
        }))
        setArchivedLogs(al => ({
            ...al, [sn]: al[sn]?.map(l =>
                l.logId == logId ? {
                    ...l, sensors: l.sensors.map((s, idx) => (
                        idx == sensorPosition ? {...s, name: name} : s
                    ))
                } : l)
        }))
    }
    const requestUploadLog = (serialNumber: string, logFileName: string) => {
        setGaugeLogs(gl => ({
            ...gl, [serialNumber]: gl[serialNumber].map(l => l.filename == logFileName ? {...l, uploading: true} : l)
        }))
        messageQueue.sendMessage(localeStore.Strings.requestedLogUpload.replace("###", serialNumber.toString()), 5000)
        deviceHubConnection.requestUploadLog(serialNumber, logFileName);
    }
    const logsAvailable = (sn: string, newLogs: Array<Log>) => {
        setGaugeLogs((gl) => {
            let allLogs = newLogs
            if (gl[sn] != null)
                allLogs = allLogs.concat(gl[sn].filter(known => !newLogs.some(l => l.filename == known.filename)))
            return {
                ...gl, [sn]: allLogs
            }
        })
    }
    const onDeviceConnected = (connectedGauge: GaugeBase, logs: Array<Log>, gaugeConfig: ConfigBase, deviceAgentVersion: string, deviceAgentConnectionId: any, properties: PropertiesFromDeviceAgent | undefined) => {
        logsAvailable(connectedGauge.serialNumber, logs)
    }
    const onDeviceDisconnected = (serial: string) => {
        setGaugeLogs(gl => {
            let {[serial]: _, ...newGL} = {...gl}
            return newGL
        })
    }
    const onReportConnected = (connectedDevices: ConnectedDevice[], deviceAgentVersion: string, connectionId: string) => {
        for (let device of connectedDevices) {
            logsAvailable(device.SerialNumber, device.Logs)
        }
    }
    const onClientDisconnected = (connectionId: string) => {
        let impacted = gaugesContext.gauges.filter(g => g.deviceAgentConnectionId == connectionId).map(g => g.serialNumber)
        for (let device of impacted) {
            onDeviceDisconnected(device)
        }
    }
    const onGaugeLogsAvailable = (serialNumber: string, logs: Array<Log>) =>
    {
        logsAvailable(serialNumber, logs)
    }
    ///DeletedLogFromDevice is a success parameter. If this is false, then there's nothing for us to do but complain.
    const onReportDeleted = (serialNumber: string, logId: number, deletedLogFromDevice: boolean) => {
        if (deletedLogFromDevice) {
            {
                setGaugeLogs(gl => {
                    //handles potential race condition. If a log is deleted and then the gauge goes offline, this could theoretically be null.
                    if (gaugeLogs[serialNumber] != null)
                        return {...gl, [serialNumber]: gl[serialNumber].filter(l => l.logId != logId )}
                    return gl
                })
            }
            messageQueue.sendSuccess(localeStore.Strings.confirmLogDeletionFromDevice)
        }
        else
            messageQueue.sendError(localeStore.Strings.errorDeletingLogFromDevice);
    }
    const onReportUploaded = (serialNumber: string, log: Log, success: boolean) => {
        setGaugeLogs(gl => {
            //handles potential race condition. If a log is deleted and then the gauge goes offline, this could theoretically be null.
            if (gaugeLogs[serialNumber] != null)
                return {...gl,
                    [serialNumber]: gl[serialNumber].map(l => l.name == log.name ? {
                        ...l,
                        logId: success? log.logId: l.logId,
                        uploading: false
                    } : l)
                }
            return gl
        })
        if (!success) {
            messageQueue.sendError(localeStore.Strings.errorUploadingLog.replace("***", log.name ?? log.filename))
            return;
        }
        if (uploadedLogs[serialNumber] == undefined)
            return //We haven't downloaded the logs yet, so we should get the freshly uploaded log with the rest.
        setUploadedLogs(ul => {
            return {...ul, [serialNumber]: ul[serialNumber].concat([log])}
        })
        messageQueue.sendSuccess(localeStore.Strings.successfullyUploadedLog.replace("***", log.name ?? log.filename))
    }
    const connectToSignals = () => {
        deviceHubConnection.connection?.on('DeviceConnected', onDeviceConnected)
        deviceHubConnection.connection?.on('DeviceDisconnected', onDeviceDisconnected)
        deviceHubConnection.connection?.on('ReportAllConnected', onReportConnected)
        deviceHubConnection.connection?.on('ClientDisconnected', onClientDisconnected)
        deviceHubConnection.connection?.on('ReportLogDeleted', onReportDeleted)
        deviceHubConnection.connection?.on('ReportLogUploaded', onReportUploaded)
        deviceHubConnection.connection?.on("gaugeLogsAvailable", onGaugeLogsAvailable)
    }
    const breakSignalConnections = () => {
        deviceHubConnection.connection?.off('DeviceConnected', onDeviceConnected)
        deviceHubConnection.connection?.off('DeviceDisconnected', onDeviceDisconnected)
        deviceHubConnection.connection?.off('ReportAllConnected', onReportConnected)
        deviceHubConnection.connection?.off('ClientDisconnected', onClientDisconnected)
        deviceHubConnection.connection?.off('ReportLogDeleted', onReportDeleted)
        deviceHubConnection.connection?.off('ReportLogUploaded', onReportUploaded)
        deviceHubConnection.connection?.off("gaugeLogsAvailable", onGaugeLogsAvailable)
    }
    return (<LogsContext.Provider value={{
        gaugeLogs,
        uploadedLogs,
        archivedLogs,
        recentLogs,
        getAllRunTags,
        loadGauge,
        reloadGauge,
        loadArchived,
        requestUploadLog,
        toggleLogArchived,
        deleteLog,
        updateName,
        updateTags,
        updateSensorName,
    }}>
        {props.children}
    </LogsContext.Provider>)
}