import { Component } from 'react';
import {
    Divider,
    Grid,
    Header,
    Label,
    Segment
} from "semantic-ui-react";
import {PressureSensorConfigBase} from "../../Models/Config/PressureSensorConfigBase";
import {SensorConfig} from "../../Models/Config/SensorConfig";
import localeStore from "../../language/LocaleStore";
import UnitsSegment from "../GeneralComponents/UnitsSegment";
import GeneralSensorSettings from "../GeneralComponents/GeneralSensorSettings";
import UserSpanSegment from "../GeneralComponents/UserSpanSegment";
import {SensorUnits} from "../../Models/Config/SensorUnits";
export interface PressureSensorConfigProps {
    sensor: string
    config: PressureSensorConfigBase
    handleChange: (config: SensorConfig, valid: boolean) => void
}
export interface PressureSensorConfigState {
    userspanError: string | null
    zeroLimitError: string | null
    filterTimeError: string | null
}
export default class PressureSensorConfig extends Component<PressureSensorConfigProps, PressureSensorConfigState> {
    constructor(props: PressureSensorConfigProps) {
        super(props);

        this.state = {
            userspanError : null,
            zeroLimitError : null,
            filterTimeError : null
        }
    }
    
    areSettingsValid = () => {
        return (!(this.state.userspanError || this.state.filterTimeError || this.state.zeroLimitError))
    }
    onUserSpanChange = (sensor : SensorConfig, valid: boolean) => {
        if (!valid)
            this.setState({userspanError: "anything"})
        this.props.handleChange(sensor, valid)
    }
    onGeneralSettingsChange = (sensor : PressureSensorConfigBase, valid: boolean) =>
    {
        this.props.handleChange(sensor, valid)
    }
    onSensorUnitsChange = (newUnits: SensorUnits) => {
        let newconfig = {...this.props.config, units: newUnits}
        this.props.handleChange(newconfig, this.areSettingsValid())
    }

    render() {
        let sensor = this.props.config as PressureSensorConfigBase;
        return (
            <Segment>
                <Header size='huge'>
                    {`${this.props.sensor.toUpperCase()} ${localeStore.Strings.sensor}`}
                    <Label basic color='black' size='large'>SN: {sensor.serialNumber}</Label>
                    <Label basic color='black' size='large'>{localeStore.Strings.model}: {sensor.partNumber}</Label>
                </Header>

                <Divider hidden />

                <Grid>
                    <Grid.Column width={10}>
                        <UnitsSegment 
                            sensorUnits={sensor.units}
                            onSensorUnitsChange={this.onSensorUnitsChange}
                        />
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <UserSpanSegment sensor={sensor} onUserSpanChange={this.onUserSpanChange} />
                        <GeneralSensorSettings sensor={sensor} settingsChange={this.onGeneralSettingsChange} />
                    </Grid.Column>
                </Grid>
            </Segment>
        )
    }
}
