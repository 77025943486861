import graphLines from "../images/Feature Preview/graphingLinesBackground.png";
import {Button, Container, Divider, Grid, Image, Segment, Transition} from "semantic-ui-react";
import {AuthContext, Images} from "@ametektci/ametek.stcappscommon";
import localeStore from "../language/LocaleStore";
import {useContext, useEffect, useState} from "react";

export function MainSplashBox() {

    const auth = useContext(AuthContext)
    const signUp = async () => {
        let url = await auth.GetSignUpLink()
        window.location.href = url!
    }
    const [getStartedBounceTrigger, setGetStartedBounceTrigger] = useState(true)
    useEffect(() => {
        setInterval(() => setGetStartedBounceTrigger(!getStartedBounceTrigger), 5000)
    },[])
    return (
        <div className='splashFirstBox' style={{
            backgroundImage: `url(${graphLines})`,
            margin:0,
            flexGrow: 1,
            paddingTop: '6em',
        }}>
            <Grid>
                <Grid.Column largeScreen={2} tablet={1} mobile={1}/>
                <Grid.Column largeScreen={8} computer={12} tablet={14} mobile={14}>
                    <Segment size='huge' style={{marginTop: '75px'}}>
                        <Image size='huge' src={Images.ccwLogoBlack}/>

                        <Divider hidden/>

                        <Container>
                            {localeStore.Strings.applicationDescription}
                        </Container>
                    </Segment>
                </Grid.Column>
                <Grid.Column largeScreen={6} tablet={1} mobile={1}/>
            </Grid>

            <Transition animation='pulse' duration={1000} visible={getStartedBounceTrigger}>
                <Container textAlign='center' className='getStartedButtonContainer'>
                    <Button content={localeStore.Strings.getStarted} size='massive' color='green' onClick={signUp}/>
                </Container>
            </Transition>
        </div>
)
}