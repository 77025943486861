//Date/Time,Sample Count,Reading,Events
import {Log} from "@ametektci/ametek.stcappscommon";
import Papa, {ParseResult} from "papaparse";
import {CompoundReading, TimestampedMeasurements} from "../../../Models/DataLogging";
import {PressureValue, Unit} from "../../../utils/PressureValue";
import {TemperatureValue} from "../../../utils/TemperatureValue";
import {CustomUnit} from "../../../Models/Config/CustomUnit";
export type TypeOneLogsColumns = TypeOneMultiReadingColumns | TypeOneSingleReadingColumns
interface TypeOneSingleReadingColumns extends TypeOneLogMetadataColumns {
    Reading: number
}
interface TypeOneMultiReadingColumns extends TypeOneLogMetadataColumns {
    Average: number
    "Low Reading" : number,
    "High Reading": number
}
interface TypeOneLogMetadataColumns {
    "Date/Time": Date | string //ISO format might not be correctly recognized - the DATE function may take spaces, but not the T as the splitter.
    "Sample Count": number,
    Events?: string,
}
//Future Dev:
//I'm not happy to transform the log like this, 
//But changing the log like this is required to set the label for the Y Axis to the correct unit.
//I'd like to make it so that we set that somewhere else,
//but now is not an appropriate time to rewrite the code that generates the graph.
function convertSensors(log: Log, pressureUnit: string, temperatureUnit: string)
{
        for (let sensor = 0; sensor < log.sensors.length; sensor++) {
        let unit = log.sensors[sensor].unit.toLowerCase() ?? "psi"
        if (PressureValue.isValidUnit(unit)) {
            log.sensors[sensor].unit = pressureUnit;
        } else if (TemperatureValue.isValidUnit(unit)) {
            log.sensors[sensor].unit = temperatureUnit;
        } else {
            console.log("Couldn't determine unit", unit);
        }
    }
}
export function ConvertTypeOneLogs(log: Log, csvData: string, pressureUnit: string, temperatureUnit: string, resolution: number, currentCustomUnit: CustomUnit, callback: (results: Array<TimestampedMeasurements>) => void) {
    let [trimmed,logUnit, slope, offset] = TrimTypeOneHeaders(csvData)
    convertSensors(log, pressureUnit, temperatureUnit)
    Papa.parse<TypeOneLogsColumns>(trimmed, {
        header: true,
        dynamicTyping: true,
        worker: true,
        complete(results: ParseResult<TypeOneLogsColumns>) {
            try
            {
                TypeOneLogsParsed(results, logUnit.toLowerCase() as Unit,pressureUnit.toLowerCase() as Unit, slope, offset, resolution, currentCustomUnit, callback)
            }
            catch
            {
                callback([])
            }
        }
    })
    
}
export function TrimTypeOneHeaders(raw: string) : [string, string, number, number] {
    let lines = raw.split("\n")
    let unit = "psi"
    let slope = 1
    let offset = 0
    while (lines[0].split(",")[0] === "0")
    {
        let currentLine = lines[0].split(",")
        if (currentLine[1] == "Current Unit")
            unit = currentLine[2]
        if (currentLine[1] == "User Slope" )
            slope = Number.parseFloat(currentLine[2])
        if (currentLine[1] == "User Offset")
            offset = Number.parseFloat(currentLine[2])
        lines.shift()
    }
    return [lines.join("\n"), unit, slope, offset]
}
function TypeOneLogsParsed(results: ParseResult<TypeOneLogsColumns>, logUnit: Unit, pressureUnit: Unit, slope: number, offset: number, resolution: number, currentCustomUnit : CustomUnit, callback: (output: Array<TimestampedMeasurements>) => void)
{
    let converted: Array<TimestampedMeasurements> = []
    for (let line of results.data)
    {
        let reading: CompoundReading | undefined

        reading = getReadingFromLine(line, logUnit ?? "psi", pressureUnit, {slope: slope.toString(), resolution: resolution, offset: offset.toString()}, currentCustomUnit, resolution)
        
        if (reading == undefined)
            continue //Skip any bad lines that may have made it in.
        if (typeof line["Date/Time"] === 'string')
        {
            converted.push({
                date: new Date(line["Date/Time"].replace('T',' ')),
                min: reading[0],
                max: reading[2],
                avg: reading[1]
            })
        }
        else
        {
            converted.push({
                date: line["Date/Time"],
                min: reading[0],
                max: reading[2],
                avg: reading[1]
            })
        }
    }
    callback(converted)
}
function getReadingFromLine(line: TypeOneLogsColumns, logUnit: Unit, pressureUnit: Unit, loggedWith: CustomUnit, showIn: CustomUnit, resolution: number) : CompoundReading | undefined
{
    let reading: CompoundReading | undefined
    if ("Reading" in line && line.Reading != null)
    {
        let temp = new PressureValue(line.Reading, loggedWith, logUnit.toLowerCase() as Unit).getValue(pressureUnit, showIn, resolution )
        reading = [+temp, +temp, +temp]
    }
    if ("Average" in line && line.Average != null)
    {
        let min = new PressureValue(line["Low Reading"], loggedWith, logUnit).getValue(pressureUnit, showIn, resolution)
        let average = new PressureValue(line.Average, loggedWith, logUnit).getValue(pressureUnit, showIn, resolution)
        let max = new PressureValue(line["High Reading"],loggedWith, logUnit).getValue(pressureUnit, showIn, resolution)
        reading = [+min, +average, +max]
    }
    return reading
}