import {Container, Grid, Header, Image, List, Segment} from "semantic-ui-react";
import {EnvironmentConfigurationContext, Images, Products, updatesStore} from "@ametektci/ametek.stcappscommon";
import localeStore from "../language/LocaleStore";
import {useContext} from "react";

export function FooterBox()
{
    const env = useContext(EnvironmentConfigurationContext)
    return (
        <Segment inverted vertical style={{ padding: '4em 0em', flexGrow: 0 }}>
            <Container>
                <Grid divided inverted stackable>
                    <Grid.Row>
                        <Grid.Column width={6}>
                            <Image src={Images.stcLogo} size='medium'/>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Header inverted as='h4' content={localeStore.Strings.applicationName} />
                            <List link inverted>
                                <List.Item as='a' content={localeStore.Strings.organizationManagement} href={env.env['managementConsoleUrl']} target={"_blank"}/>
                                {/*Offline client is not ready.*/}
                                {/*<List.Item as='a' content={localeStore.Strings.offlineAppName} onClick={() => updatesStore.downloadUpdateFile(Products.OfflineClient)} />*/}
                                <List.Item as='a' content={localeStore.Strings.deviceAgent} onClick={() => updatesStore.downloadUpdateFile(Products.DeviceAgent)}/>
                                <List.Item as='a' content={localeStore.Strings.documentation} href={env.env['documentationUrl']} target={"_blank"}/>
                            </List>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Header inverted as='h4' content='AMETEK' />
                            <List link inverted>
                                <List.Item as='a' href='https://www.ametekcalibration.com/' content={localeStore.Strings.ametekCalibration} target={"_blank"} />
                            </List>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </Segment>
    )
}