import {createContext, PropsWithChildren, useContext, useState} from "react";
import axios from "axios";
import {EnvironmentConfigurationContext} from "@ametektci/ametek.stcappscommon";

export const UpdatesContext = createContext({
    downloadUpdateFile: (product: string, hardware: VersionInfo) => {},
    latestUpdate: (product: string, hardware: VersionInfo) => {return undefined as undefined | Update}
})
let alreadyRequested: Array<string> = []
export function UpdatesContextWrapper(props: PropsWithChildren)
{
    const env = useContext(EnvironmentConfigurationContext)
    const [updates, setUpdates] = useState<{[key: string]: Update}>({})
    const inProgressLock = "UPDATE_IN_PROGRESS_"
    //Internal: allows us  to request the string. Ensure you're calling from within a lock.
    const getLatestUpdate = async (product: string) =>
    {
        if (alreadyRequested.includes(product))
            return //we have already looked this up
        alreadyRequested.push(product)
        //I wanted to do an axios instance for this, however an axios instance with the base url changes the format of the response.
        //I think it has something to do with the authority and the path in the headers changing?
        let response = await axios.get<UpdateResponse>(env.env['updatesUrl']+"/ProductUpdate", {
            params: {
                CurrentVersion: JSON.stringify({Major: 0, Minor: 0, Patch: 1}),
                NewestVersionOnly: true,
                Product: product == "HPC50" ? "HPC50 Series Chassis" : product, //HPC50 was built out before the current gauge naming scheme, and thus needs special handling.
                ProductHardwareVersion: JSON.stringify({Major: 1, Minor: 0, Patch: 0}),
            },
            headers: {
                Accept: "application/json, text/plain"
            }
        })
        if (response.data.productUpdates)
        {
            let update = response.data.productUpdates[0]
            setUpdates( (AvailableUpdates) => ({...AvailableUpdates, [product]: update}))
        }
        
    }
    //Internal: Creates a lock for loading the latest update.
    const loadLatestUpdate = async (product: string) => {
        await navigator.locks.request(inProgressLock + product, async () => await getLatestUpdate(product))
    }
    const latestUpdate = (product: string) =>
    {
        if (!updates[product])
            loadLatestUpdate(product)
        return updates[product]
    }
    const downloadUpdateFile = (product: string) => {
        if (updates[product])
            window.open(updates[product].packageUri, "_blank")
    }
    return (<UpdatesContext.Provider value={{
        downloadUpdateFile,
        latestUpdate,
    }}>
        {props.children}
    </UpdatesContext.Provider>)
}
export interface UpdatesWrapperProps extends PropsWithChildren
{
    updatesUrl: string
}
export interface UpdateResponse {
    "productUpdate": {
        "newestVersionOnly": boolean,
        "product": string
    },
    "productUpdates": Array<Update>
}
export interface Update {
    buildNumber: number
    majorVersion: number
    minorVersion: number
    patchVersion: number
    releaseDate: Date
    packageUri: string
    packageHash: string
    product: string
    releaseName: string
    releaseNote: string
}
export interface VersionInfo
{
    Major: number
    Minor: number
    Patch: number
    Build: number
}