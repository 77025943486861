import {useEffect} from "react";
import CookieConsent from "../Cookies/CookieConsent";
import {MainSplashBox} from "./MainSplashBox";
import {HeaderBox} from "./HeaderBox"
import {FeaturesBox} from "./FeaturesBox";
import {FooterBox} from "./FooterBox";

export default function SplashPage() {

    useEffect(() => {
        CookieConsent()
    }, [])

    return (
        <div style={{display: "flex", flexDirection: "column", minHeight: "100vh"}}>
            <HeaderBox/>
            <MainSplashBox/>
            <FeaturesBox/>
            <FooterBox/>

        </div>
    )
}
