import LogList from "../gauge/LogList";
import EnableLoggingOnDevice from "../gauge/EnableLoggingOnDevice";
import {Message} from "semantic-ui-react";
import localeStore from "../language/LocaleStore";
import {useContext} from "react";
import {GaugeContext} from "../contexts/GaugeContext";

export default function DeviceLogs() {
    const gaugeContext = useContext(GaugeContext)
    const gauge = gaugeContext.Gauge

    if (gauge.supportsLogging == false) //Old devices like the XP2I can be configured, but logs aren't read from the gauge.
    {
        return <Message header={localeStore.Strings.deviceNotSupported}
                        content={localeStore.Strings.uploadingLogsFromUnitNotSupported.replace("***", gauge.model.shortName)}/>
    }
    if (gauge.dataLogPurchased == "FORBIDDEN")
        return <Message header={localeStore.Strings.dataLoggingNotEnabled} content={localeStore.Strings.dataLoggingCannotBeEnabled} />
    if (!gauge.dataLogEnabled) {
        if (gauge.dataLogPurchased == "BOUGHT")
            return <Message header={localeStore.Strings.dataLoggingNotYetEnabled} content={localeStore.Strings.dataLoggingWillBeEnabled}></Message>
        if (gauge.model.shortName != "HPC50")
            return <EnableLoggingOnDevice gauge={gauge}/>
        else
            return <Message header={localeStore.Strings.dataLoggingNotEnabled}
                            content={localeStore.Strings.dataLoggingRequiredToGenerateLogs}/>
    }
    return <LogList />
}