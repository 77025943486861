//DEV:
//You may be wondering, where do these numbers come from?!
//For some of these, I don't know. They were left by the previous dev. Some of them look like they have lost accuracy?
//Others were pulled from XP2I firmware.
import {CustomUnit} from "../Models/Config/CustomUnit";

export const conversions = {
    'psi' : '1',
    'psi g' : '1',
    'psi a' : '1',
    
    'inh2o' : '27.6805992126465',
    "inh2o4c" : "27.68059969",
    'inh2o-4c' : '27.68059969',
    
    'inh2o60f' : '27.70699859',
    'inh2o-60f' : '27.70699859',
    
    'inh2o68f' : '27.72920132',
    'inh2o_68f' : '27.6806',
    'inh2o-68f': '27.6806',
    
    'mmh2o' : '703.086975097656',
    "mmh2o4c" : "703.086975097656",
    'mmh2o-4c' : '703.086975097656',
    
    'mmh2o60f' : '703.773',
    'mmh2o-60f' : '703.773',
    
    'mmh2o68f' : '704.339',
    'mmh2o_68f' : '704.339',
    'mmh2o-68f': '704.339',
    
    'inhg' : '2.03602004051208',
    'mmhg' : '51.714900970459',
    'kpa' : '6.89480018615723',
    'mpa' : '0.00689479988068342',
    
    'kgcmsq' : '0.0703070014715195',
    'kg/cm2' : '0.0703070014715195',
    'kgcm2' : '0.0703070014715195',
    
    'bar' : '0.0689480006694794',
    'mbar' : '68.948',
};

export type Unit = keyof typeof conversions | "user"
export class PressureValue {
    _value: number
    _factor: number
    _offset: number
    constructor(value : number, loggedWith: CustomUnit, unit: Unit ='psi') {
        let factor = +loggedWith.slope
        let offset = +loggedWith.offset
        this._factor = 1
        this._offset = 0
        if (unit === 'psi')
            this._value = value;
        else if (unit == 'user')
        {
            this._value = (value - offset)/factor
            this._factor = factor == 0? 1 : 0 //Factor = 0 will create problems. if this does happen, use 1 instead to prevent a crash.
            this._offset = offset
        }
        else
            this._value = value / this.getMultiplier(unit);
    }

    getMultiplier(unit : Unit) {
        if (unit == "user")
            return 1
        let conversion : string = conversions[unit];

        if (!conversion)
            throw new Error("Unknown unit " + unit);

        return Number.parseFloat(conversion);
    }

    getValue(unit: Unit='psi', showIn: CustomUnit, decimalPlaces = 0) {
        let val : number
        if (unit == 'user')
        {
            val = (this._value * (+showIn.slope)) +(+showIn.offset);
            return val.toFixed(showIn.resolution)
        }
        else val = this._value * this.getMultiplier(unit);
        return val.toFixed(decimalPlaces)
    }

    static isValidUnit(unit : string) {
        return (unit in conversions || unit == "user");
    }
}
//For spacing reasons, equivalent sensors share a line.
const RESOLUTIONS =
{
    "15PSI": 1, "1BARA": 1, "1BAR": 1, "100KPA": 1, "100KPAA": 1, "110KPAA": 1, "1,1BARA":1, "16PSIA":1,
    "30PSI": 2, "2BAR": 2, "200KPA": 2,
    "100PSI": 3, "100PSIA": 3, "7BAR": 3, "7BARA": 3, "10BAR":3, "10BARA": 3, "700KPA": 3, "700KPAA": 3, "1KKPA":3, "1KKPAA": 3,
    "300PSI": 4, "20BAR": 4, "2KKPA": 4,
    "500PSI": 5, "500PSIA": 5, "30BAR": 5, "30BARA": 5, "3KKPA": 5, "3KKPAA": 5,
    "1KPSI": 6, "1KPSIA": 6, "70BAR": 6, "70BARA": 6, "7KKPA": 6, "7KKPAA": 6,
    "2KPSI": 7, "2KPSIA": 7, "140BAR": 7, "140BARA": 7, "14KKPA": 7, "14KKPAA": 7,
    "3KPSI": 8, "3KPSIA": 8, "200BAR": 8, "200BARA": 8, "20KKPA": 8, "20KKPAA": 8,
    "5KPSI": 9, "5KPSIA": 9, "300BAR": 9, "300BARA": 9, "30KKPA": 9, "30KKPAA": 9,
    "10KPSI": 10, "10KPSIA": 10, "700BAR": 10, "700BARA": 10, "70KKPA": 10, "70KKPAA": 10,
    //We EITHER have 1000BAR or 1KBAR. I'm updating this far enough in advance to be uncertain which one we will be using.
    "15KPSI":11, "15KPSIA": 11, "1000BAR":11, "1KBAR":11, "1000BARA": 11,"1KBARA": 11, "100KKPA": 11, "100KKPAA": 11,
}
// Returns the number of units after the decimal to show
export function getResolution(series: string,resolutionRange: string, targetUnit: Unit, customUnits: Array<CustomUnit>)
{
    if (series != "xp3i")
        return 0
    if (!(resolutionRange in RESOLUTIONS))
        return 0
    let sensor = resolutionRange as keyof typeof RESOLUTIONS
    switch (targetUnit.toLowerCase())
    {
        case "user":
            return customUnits[0]?.resolution ?? 5
        case "bar":
            switch (RESOLUTIONS[sensor])
            {
                case 1: case 2: case 3:
                    return 4
                case 4: case 5: case 6:
                    return 3
                default:
                    return 2
            }
        case "mbar":
            switch (RESOLUTIONS[sensor])
            {
                case 1: case 2: case 3:
                    return 1
                default:
                    return 0
            }
        case "psi": case "psi a": case "psi g":
            switch (RESOLUTIONS[sensor])
            {
                case 1: case 2:
                    return 3
                case 3: case 4: case 5:
                    return 2
                case 6: case 7: case 8: case 9:
                    return 1
                default:
                    return 0
            }
        case "inh2o": case "inh2o4c": case'inh2o60f': case 'inh2o68f': case 'inh2o_68f': 
            switch (RESOLUTIONS[sensor])
            {
                case 1: case 2:
                return 2
                case 3: case 4:
                    return  1
                default:
                    return 0
            }
        case "mmh2o":
            return 0
        case "inhg":
            switch (RESOLUTIONS[sensor])
            {
                case 1: case 2:
                    return 3
                case 3: case 4:
                    return 2
                case 5: case 6: case 7: case 8:
                    return 1
                default:
                    return 0
            }
        case "mmhg":
            switch (RESOLUTIONS[sensor])
            {
                case 1:
                    return 2
                case 2: case 3:
                    return 1
                default:
                    return 0
            }
        case "kpa":
            switch (RESOLUTIONS[sensor])
            {
                case 1: case 2: case 3:
                    return 2
                case 4: case 5: case 6:
                    return 1
                default:
                    return 0
            }
        case "mpa":
            switch (RESOLUTIONS[sensor])
            {
                case 1: case 2: case 3:
                    return 5
                case 4: case 5: case 6:
                    return 4
                default: return 3
            }
        case "kgcmsq": case "kgcm2":
        {
            switch (RESOLUTIONS[sensor])
            {
                case 1: case 2: case 3:
                    return 4
                case 4: case 5: case 6:
                    return 3
                default:
                    return 2
            }
        }
    }
    return 0
}