import {Grid} from "semantic-ui-react";
import UnitsSegment from "../GeneralComponents/UnitsSegment";
import {PressureSensorConfigBase} from "../../Models/Config/PressureSensorConfigBase";
import PeaksAndAverages from "../GeneralComponents/PeaksAndAverages";
import {PeaksAndAveraging} from "../../Models/Config/PeaksAndAveraging";
import GeneralSensorSettings from "../GeneralComponents/GeneralSensorSettings";
import {useState} from "react";
import {SensorUnits} from "../../Models/Config/SensorUnits";
import {SpecialFeatureControls} from "../GeneralComponents/SpecialFeatureControls";

export interface ReadingsPageProps {
    peaksAndAverages?: PeaksAndAveraging

    onUpdatePeaksAndAverages(newPeaks: PeaksAndAveraging): void

    sensor: PressureSensorConfigBase

    onUnitChange(sensor: PressureSensorConfigBase, isValid: boolean): void
}

export default function ReadingsPage(props: ReadingsPageProps) {
    const [generalSettingsValid, setGeneralSettingsValid] = useState(true)
    const [specialFeaturesValid, setSpecialFeaturesValid] = useState(true)
    const onUpdatePeaksAndAverages = (newPeaks: PeaksAndAveraging) => {
        props.onUpdatePeaksAndAverages(newPeaks)
    }

    const onGeneralSettingsChange = (sensor: PressureSensorConfigBase, valid: boolean) => {
        setGeneralSettingsValid(valid)
        props.onUnitChange(sensor, valid && specialFeaturesValid)
    }
    const onSpecialFeaturesChange = (sensor: PressureSensorConfigBase, valid: boolean) => {
        setSpecialFeaturesValid(valid)
        props.onUnitChange(sensor, valid && generalSettingsValid)
    }
    const onSensorUnitsChange = (newUnits: SensorUnits) => {
        let newSensor = {...props.sensor, units: newUnits}
        props.onUnitChange(newSensor, generalSettingsValid)
    }

    return (
        <Grid>
            <Grid.Column width={10}>
                <UnitsSegment
                    sensorUnits={props.sensor.units}
                    onSensorUnitsChange={onSensorUnitsChange}
                />
            </Grid.Column>
            <Grid.Column width={6}>
                {props.peaksAndAverages ?
                    <PeaksAndAverages peakAndAverage={props.peaksAndAverages} onChange={onUpdatePeaksAndAverages}/>
                    : undefined
                }
                <GeneralSensorSettings sensor={props.sensor} settingsChange={onGeneralSettingsChange} />
                <SpecialFeatureControls psvEnabled={props.peaksAndAverages?.enablePSV} togglePSV={(newPSV) => onUpdatePeaksAndAverages({...props.peaksAndAverages!, enablePSV: newPSV})} sensor={props.sensor} settingsChange={onSpecialFeaturesChange} />
            </Grid.Column>
        </Grid>
    )
}