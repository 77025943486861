import {useContext, useState} from 'react';
import {Button, Modal, Form, Header} from 'semantic-ui-react';
import localeStore from "../language/LocaleStore";
import {SelectFileToUpload, UploadableFile} from "../utils/SelectFileToUpload";
import {CertificateContext} from "../contexts/CertificateContext";
import {CurrentCertificates} from "./CurrentCertificates";
export interface CertificateManagementModalProps {
    onCertificatesUpdated : () => void
}
export default function CertificateManagementModal(props: CertificateManagementModalProps) {
    const certificateContext = useContext(CertificateContext)
    const [visible, setvisible] = useState(false)
    const certificates = certificateContext.certificates
    const [uploadable, setUploadable] = useState<UploadableFile>({fileName:'', filePath: '', fileContent: null})
    const [loading, setLoading] = useState(false)
    const [expiresAt, setExpiresAt] = useState<string>("")

    const open = () => setvisible(true)
    const close = () => {
        if ( ! loading)
            setvisible(false)
    };
    const uploadCertificate = () => {
        if (loading || ! uploadable.fileName || !uploadable.filePath || !expiresAt)
        {
            console.error("Certificate not fully defined", uploadable, expiresAt, loading)
            return;
        }

        setLoading(true)
        console.log("uploading")
        certificateContext.uploadCertificate(uploadable.fileName, uploadable.fileContent![0], expiresAt).then(() => {
            setLoading(false)
        });
        setUploadable({fileName: "", filePath: "", fileContent: null})
        setExpiresAt("")
    };
    
        return (
            <Modal size='small' open={visible}
                   trigger={<Button icon='lock' content={localeStore.Strings.manageCertificates} size='small' onClick={open}/>} >
                <Modal.Header>{localeStore.Strings.manageCertificates}</Modal.Header>
                <Modal.Content>
                    <CurrentCertificates loading={loading} />

                    <Header size='small' content={localeStore.Strings.uploadNewCertificate} dividing/>

                    <Form>
                        <SelectFileToUpload setUploadableFile={setUploadable} file={uploadable} acceptableFileTypes={'.pfx'} key={certificates.length} />
                        <br/>
                        <Form.Input type='date' label={localeStore.Strings.expiresAt} value={expiresAt} name='expiresAt' onChange={(e, data) => setExpiresAt(data.value)}/>

                        <Button type='submit' icon='upload' content={localeStore.Strings.upload} color='blue'
                                loading={loading} disabled={loading || ! uploadable.fileName || !uploadable.filePath || !expiresAt}
                                onClick={uploadCertificate}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={close} content={localeStore.Strings.done} disabled={loading} />
                </Modal.Actions>
            </Modal>
        )
}