import {MenuItem, Popup} from "semantic-ui-react";

export interface MenuItemWithPopupOption {
    Name: string
    Disabled: boolean
    popup?: string
}

export interface MenuItemWithPopupsProps {
    entry: MenuItemWithPopupOption
    onSelected: (name: string) => void
    currentPage: string
}

export function MenuItemWithPopups(props: MenuItemWithPopupsProps) {
    return (
        <Popup disabled={!props.entry.popup} trigger={
            <MenuItem
                disabled={props.entry.Disabled}
                onClick={() => props.onSelected(props.entry.Name)}
                active={props.entry.Name === props.currentPage}
            >
                {props.entry.Name}
            </MenuItem>
        } position={"top center"}>{props.entry.popup}</Popup>
    )
}