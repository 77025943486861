import {Button, Divider, Grid, Header, Label, Menu, Message, Popup, Segment} from "semantic-ui-react";
import localeStore from "../language/LocaleStore";
import {Products, updatesStore} from "@ametektci/ametek.stcappscommon";
import React, {useContext, useEffect, useState} from "react";
import {GaugeContext} from "../contexts/GaugeContext";
import GaugeDetails from "./GaugeDetails";
import ConfigurationModal from "../gaugeconfiguration/ConfigurationModal";
import {MenuItemWithPopupOption, MenuItemWithPopups} from "../utils/MenuItemWithPopups";
import {LanguageContext} from "../contexts/LanguageContext";
import DeviceLogs from "./DeviceLogs";
import {GaugesContext} from "../contexts/GaugesContext";

export interface displayPage extends MenuItemWithPopupOption {
    Page: React.ReactElement,
    Position: number
}

export function GaugeManagementPage() {
    const translate = useContext(LanguageContext)
    const gaugesContext = useContext(GaugesContext)
    const gaugeContext = useContext(GaugeContext)
    //const [latestFirmware, setLatestFirmware] = useState(updatesStore.getLatestUpdateVersion(props.gauge.model.shortName))
    const latestDeviceAgentUpdate = updatesStore.getLatestUpdateVersion(Products.DeviceAgent)
    const allPages : Array<displayPage> = [
        {
            Name: translate.getString("detailsMenuOption"), 
            Page: <GaugeDetails/>, 
            Disabled: false,
            Position: 0,
        },
        {
            Name: translate.getString("logs"),
            Page: <DeviceLogs/>,
            Disabled: false,
            Position: 1,
        },
        {
            Name: translate.getString("configureGaugeMenuOption"), 
            Page: <ConfigurationModal onClose={() => setPage(allPages[0].Name)}/>, 
            Disabled: false,
            popup:  !gaugeContext.Gauge?.connected? translate.getString("readOnlyUntilConnected") : undefined,
            Position: 2,
        },
    ]
    const [page, setPage] = useState(allPages[0].Name)
    

    useEffect(() => {
        setPage(allPages[0].Name)
    }, [gaugeContext.Gauge?.serialNumber])
    
    if (!gaugesContext.gaugesLoaded && !gaugesContext.demoMode)
    return (
        <Segment>
            <Header>{translate.getString("loadingOrganizationHeader")}</Header>
            <Message>{translate.getString("loadingOrganizationMessage")}</Message>
        </Segment>
    )
    return (
        <Segment>
            <Grid verticalAlign='middle' padded={true}>
                <Grid.Row>
                    <Grid.Column width={9}>
                        <Header size='large' content={gaugeContext.Gauge.name? gaugeContext.Gauge.name:  gaugeContext.Gauge!.model.name} subheader={gaugeContext.Gauge!.serialNumber} />
                    </Grid.Column>
                    <Grid.Column width={7} textAlign='right'>

                        {gaugeContext.Gauge!.connected ?
                            <span>
                                {!gaugeContext.Gauge!.connectedDeviceAgentVersion || !latestDeviceAgentUpdate || gaugeContext.Gauge!.connectedDeviceAgentVersion.equals(latestDeviceAgentUpdate.simpleVersion) ||
                                gaugeContext.Gauge!.connectedDeviceAgentVersion.isNewerThan(latestDeviceAgentUpdate.simpleVersion) ? null :
                                    <Popup position='bottom right' wide hoverable
                                           trigger={<Label color='orange' pointing='right'
                                                           content={localeStore.Strings.deviceAgentUpdateAvailable}/>}>
                                        <p>{localeStore.Strings.deviceAgentOutOfDate}</p>

                                        <Divider hidden/>

                                        <Button content={localeStore.Strings.downloadDeviceAgent} color='red'
                                                icon='download'
                                                fluid size='tiny'
                                                onClick={() => updatesStore.downloadUpdateFile(Products.DeviceAgent)}/>
                                    </Popup>
                                }

                                <Label color='green' size='large' content={localeStore.Strings.connected}/>
                            </span>
                            :
                            <Label color='red' size='large' content={localeStore.Strings.notConnected} basic/>
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Menu fluid={true} pointing={true} tabular={true}>
                {allPages.map((pag) =>
                    <MenuItemWithPopups
                        key={pag.Name} 
                        entry={pag} 
                        onSelected={setPage}
                        currentPage={page}
                    />
                    
                )}
            </Menu>
            {allPages.find(p => p.Name == page)?.Page}
        </Segment>)
}