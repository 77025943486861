import {useContext, useState} from 'react';
import {Header, Button, Modal, Checkbox, Grid} from 'semantic-ui-react';
import localeStore from "../language/LocaleStore";
import {LogContext} from "../contexts/LogContext";
import {LogsContext} from "../contexts/LogsContext";
import {GaugeContext} from "../contexts/GaugeContext";

export interface DeleteLogProps {
    disabled: boolean
    closeModal: any
}

const initialCheckedBoxValues = {
    deleteFromCcw: false,
    deleteFromDevice: false,
}
export default function DeleteLog(props: DeleteLogProps) {
    const gaugeContext = useContext(GaugeContext)
    const logsContext = useContext(LogsContext)
    const logContext = useContext(LogContext)
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [checkedBox, setCheckedBox] = useState(initialCheckedBoxValues)

    const open = () => {
        setVisible(true)
    }
    const close = () => {
        if (!loading)
            setVisible(false)
    };

    const handleCheckBoxChange = (e: any, {checked}: any) => {
        const {name} = e.target;
        setCheckedBox({
            ...checkedBox, [name]: checked
        });
    }

    const deleteLog = () => {
        setLoading(true)

        //only delete it from ccw db
        if (checkedBox.deleteFromCcw && !checkedBox.deleteFromDevice) {
            logContext.deleteLog().then((success) => {
                setLoading(false)
                setVisible(false)
                closeModal(success)
            })
        }
        //only delete it from the device
        else if (!checkedBox.deleteFromCcw && checkedBox.deleteFromDevice) {
            logContext.requestDeleteLog()
            setLoading(false)
            setVisible(false)
            closeModal(true)
        }

        //delete everywhere
        else if (checkedBox.deleteFromCcw && checkedBox.deleteFromDevice) {
            logContext.requestDeleteLog()
            logContext.deleteLog().then((result) => {
                setLoading(false)
                setVisible(false)
                closeModal(result)
            })
        
        }
    };
    const closeModal = (deletionComplete: boolean) => {
        //want to close modal if no errors occurred
        if (deletionComplete)
            props.closeModal()
    }
    return (
        <Modal size='tiny' open={visible}
               trigger={
                   <Button color='red' size='small' disabled={loading || props.disabled}
                           onClick={open} 
                           content={localeStore.Strings.delete}
                           icon={'delete'}
                   />}>
            <Modal.Header>{localeStore.Strings.confirmLogDeletion}</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Header content={localeStore.Strings.deletionIsPermanent} color='red'/>

                    <Grid container divided>
                        <Grid.Row>
                            <Checkbox
                                toggle
                                label={localeStore.Strings.deleteFromCcw}
                                id={1}
                                name={"deleteFromCcw"}
                                onClick={handleCheckBoxChange}
                            />
                        </Grid.Row>
                        {gaugeContext.Gauge.connected && !gaugeContext.lock.lockConfig && logsContext.gaugeLogs[gaugeContext.Gauge.serialNumber]?.some(l => l.logId == logContext.log.logId)?
                            <Grid.Row>
                                <Checkbox
                                    toggle
                                    label={localeStore.Strings.deleteFromDevice}
                                    id={2}
                                    name={"deleteFromDevice"}
                                    onClick={handleCheckBoxChange}
                                />
                            </Grid.Row> : null
                        }
                    </Grid>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={close} disabled={loading} content={localeStore.Strings.cancel}/>
                <Button negative onClick={deleteLog} loading={loading}
                        disabled={loading || !Object.values(checkedBox).some(val => val === true)}
                        content={localeStore.Strings.delete}/>
            </Modal.Actions>
        </Modal>
    )
}