import {useContext} from 'react';
import {Button, Label, Popup, Transition} from 'semantic-ui-react';
import localeStore from "../language/LocaleStore";
import {GaugesContext} from "../contexts/GaugesContext";

export default function DemoModeStatus() {
    const gaugeContext = useContext(GaugesContext)
        return (
            <div>
                <Transition visible={gaugeContext.demoMode} animation='vertical flip' duration={300}>
                    <div>
                        <Popup mouseLeaveDelay={1000} trigger={
                                <Label content={localeStore.Strings.demoModeEnabled} size='large'
                                       color='yellow' basic className='transparentBackground'/> }>
                            <Button content={localeStore.Strings.exitDemoMode} floated='right' color='yellow'
                                    onClick={() => gaugeContext.setDemoMode(false)}/>
                        </Popup>
                    </div>
                </Transition>
            </div>
        )
}
