import {Form, Icon} from "semantic-ui-react";
import {GaugeContext} from "../../contexts/GaugeContext";
import {useContext} from "react";
import {LanguageContext} from "../../contexts/LanguageContext";
export function GaugePasswordEntry() {
    const translate = useContext(LanguageContext)
    const gaugeContext = useContext(GaugeContext)
    //The XP3I has a character limit of 16, and supports the characters described in this regex
    const validCharsRegex = RegExp("^[A-Za-z0-9]$")
    const passwordInput = (input: string) => {
        var newPass = input.split("", 16).filter(c => validCharsRegex.test(c)).join("")
        gaugeContext.lock.setPassword(newPass)
    }
    return (
        <Form>
            <Form.Group inline={true} >
            <Form.Input
                type={"password"}
                value={gaugeContext.lock.password} 
                onChange={(e, {value}) => passwordInput(value)}
                autoComplete={"gauge-password"}
                icon={gaugeContext.lock.lockConfig? undefined: <Icon name={"check"} />}
            />
            <Form.Button 
                disabled={!gaugeContext.lock.lockConfig ?? gaugeContext.lock.passwordSettingChangeInProgress} 
                onClick={gaugeContext.lock.challengePassword}
            >
                {translate.getString("manageConfiguration")}</Form.Button>
            </Form.Group>
        </Form>
    )
}