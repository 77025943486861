import {useContext} from 'react';
import {
    Image,
    Grid,
} from 'semantic-ui-react';
import GaugeInfoTable from "../gauge/GaugeInfoTable";
import DeviceLogs from "./DeviceLogs";
import {GaugeContext} from "../contexts/GaugeContext";
import {QuickActions} from "../gauge/QuickActions";

export default function GaugeDetails () {
    const gaugeContextData = useContext(GaugeContext);
    const gauge = gaugeContextData.Gauge;
    
   
    
        return (
            <div>
                <Grid>
                    <Grid.Row>
                    <Grid.Column width={6} verticalAlign='middle'>
                        <Image src={gauge.picture()} size='medium' centered/>
                    </Grid.Column>
                    <Grid.Column width={10}>
                        <GaugeInfoTable/>
                    </Grid.Column>
                    </Grid.Row>
                </Grid>
                <QuickActions/>
                <DeviceLogs/>

            </div>
        )
}
