import {useContext, useState} from 'react';
import { Icon, Header, Button, Modal} from 'semantic-ui-react';
import localeStore from "../language/LocaleStore";
import {Certificate} from "../Models/Graphing/Certificate";
import {CertificateContext} from "../contexts/CertificateContext";
export interface DeleteCertificateProps
{
    certificate: Certificate
    disabled: boolean
}
export default function DeleteCertificate(props: DeleteCertificateProps) {
    const certificateContext = useContext(CertificateContext)
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)

    const open = () => setVisible(true);
    const close = () => {
        if ( ! loading)
            setVisible(false)
    };

    const deleteCert = () => {
        setLoading(true)

        certificateContext.deleteCertificate(props.certificate.certificateId).then((success) => {
            if (success)
            {
                setLoading(false)
                setVisible(false)
            }
        });
    };

        return (
            <Modal size='tiny' open={visible}
                   trigger={
                       <Button animated color='red' size='small' disabled={loading || props.disabled}
                               onClick={open}>
                           <Button.Content visible content={localeStore.Strings.delete}/>
                           <Button.Content hidden>
                               <Icon name='delete' />
                           </Button.Content>
                       </Button>} >
                <Modal.Header>{localeStore.Strings.confirmCertificateDeletion}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Header content={localeStore.Strings.deletionIsPermanent} color='red'/>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={close} disabled={loading}
                            content={localeStore.Strings.cancel} />
                    <Button negative disabled={loading} loading={loading}
                            onClick={deleteCert} content={localeStore.Strings.delete} />
                </Modal.Actions>
            </Modal>
        )
}