import {useContext, useState} from 'react';
import {Button, Modal, Header, Grid, Dropdown} from 'semantic-ui-react';
import localeStore from "../language/LocaleStore";
import {UserContext} from "../contexts/UserContext";
import {units} from "../utils/UnitDisplay";
import {conversions, Unit} from "../utils/PressureValue";

const pressureUnits = units.filter(u => u.unit.toLowerCase() in conversions || u.unit.toLowerCase() == "user" ).map(u => ({
    key: u.unit,
    value: u.unit.toLowerCase(),
    text: u.display
}))
export const colorOptions = [
    { key: 1, value: 1, text: localeStore.Strings.standard, colors: ['#ED1C24', '#0E497F', '#21BA45', '#A333C8'], content : (
        <span>
            {localeStore.Strings.standard}
            <div className='graphColorBlock' style={{backgroundColor: '#ED1C24'}}/><div className='graphColorBlock' style={{backgroundColor: '#0E497F'}}/>
            <div className='graphColorBlock' style={{backgroundColor: '#21BA45'}}/><div className='graphColorBlock' style={{backgroundColor: '#A333C8'}}/>
        </span>
    )},
    { key: 2, value: 2, text: localeStore.Strings.colorBlind, colors: ['#EF6C00', '#283593', '#FFD54F', '#7986CB'], content : (
            <span>
            {localeStore.Strings.colorBlind}
            <div className='graphColorBlock' style={{backgroundColor: '#EF6C00'}}/><div className='graphColorBlock' style={{backgroundColor: '#283593'}}/>
            <div className='graphColorBlock' style={{backgroundColor: '#FFD54F'}}/><div className='graphColorBlock' style={{backgroundColor: '#7986CB'}}/>
        </span>
    )}
];

const xAxisFormatOptions = [
    { key : 'us', text : 'MM/DD/YY HH:mm:ss', value : 'us' },
    { key : 'international', text : 'YY-MM-DD HH:mm:ss', value : 'international' },
    { key : 'duration', text : localeStore.Strings.duration, value : 'duration' },
];
export type xAxisFormat = typeof xAxisFormatOptions[number]["key"]
export interface GraphSettingsProps {
    disabled: boolean
}
export default function GraphSettings(props:GraphSettingsProps) {
    const userContext = useContext(UserContext)
    const pressureUnit = (userContext.graphUnit??userContext.pressureUnit)
    const [visible, setVisible] = useState(false)
    const [currentPressure, setCurrentPressure] = useState(pressureUnit)
    const [color, setColor] = useState(userContext.graphColorCode)
    const [xAxisFormat, setXAxisFormat] = useState(userContext.xAxisFormat)
    

    const open = () => {
        setVisible(true)
        setCurrentPressure(pressureUnit)
        setXAxisFormat(userContext.xAxisFormat)
    }
    const close = () => setVisible(false)

    const submitChanges = () => {
        userContext.setGraphUnit(currentPressure)
        userContext.setGraphColors(color);
        userContext.setXAxisFormat(xAxisFormat)
        setVisible(false)
    };
        return (
            <Modal size='tiny' open={visible}
                   trigger={<Button icon='settings' content={localeStore.Strings.graphSettings} disabled={props.disabled} onClick={open}/> } >
                <Modal.Header>{localeStore.Strings.graphSettings}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>

                        <Header content={localeStore.Strings.units} color='blue' dividing size='small'/>
                        <Grid>
                            <Grid.Column width={8}>
                                <Header size='tiny' content={localeStore.Strings.pressureUnit}/>
                                <Dropdown selection fluid name='pressureUnit' value={currentPressure}
                                          options={pressureUnits} onChange={(e, {value}) => setCurrentPressure(value as Unit)} />
                            </Grid.Column>
                        </Grid>

                        <Header content={localeStore.Strings.colors} color='blue' dividing size='small'/>
                        <Dropdown 
                            selection 
                            fluid 
                            options={colorOptions} 
                            value={color} 
                            onChange={(e, {value}) => setColor(value as number)}
                        />

                        <Header content={localeStore.Strings.xAxisFormat} color='blue' dividing size='small'/>
                        <Dropdown selection fluid options={xAxisFormatOptions} value={xAxisFormat} onChange={(e, {value}) => setXAxisFormat(value as xAxisFormat)}/>

                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={close} content={localeStore.Strings.cancel} />
                    <Button color='blue' onClick={submitChanges} content={localeStore.Strings.confirm} />
                </Modal.Actions>
            </Modal>
        )
}
