import {useContext, useState} from 'react';
import {Modal, Button, Divider, Icon, Form, Header, Popup} from 'semantic-ui-react';
import {Log} from "@ametektci/ametek.stcappscommon";
import CertificateManagementModal from "../certificates/CertificateManagementModal";
import localeStore from "../language/LocaleStore";
import {LogContext} from "../contexts/LogContext";
import {CertificateContext} from "../contexts/CertificateContext";
import {ZoomInfo} from "../Models/Requests/GeneratePDFRequest";
export interface CreateReportModalProps {
    log: Log
    disabled: boolean
    zoomInfo?: ZoomInfo
}
export default function CreateReportModal (props: CreateReportModalProps) {
    const certificateContext = useContext(CertificateContext)
    const logContext = useContext(LogContext)
    const [modalOpen, setModalOpen] = useState(false)
    const [loading,setLoading] = useState(false)
    const [includeDp, setIncludeDp] = useState(true)
    const [rotateGraph, setRotateGraph] = useState(false)
    const [certificatePassword,setCertificatePassword] = useState('')
    const certificates= certificateContext.certificates
    const [selectedCertificate, setSelectedCertificate] = useState<string | null>()
    const [signReport, setSignReport] = useState(false)
    const [maintainZoom, setMaitnainZoom] = useState(true)
    const canUseZoom = props.zoomInfo != null
    const updateCertificateOptions = () => {
        setSelectedCertificate(selectedCertificate
            ? certificates.find(c => c.name === selectedCertificate) || certificates.length > 0 ? certificates[0].name : null
            : certificates.length > 0 ? certificates[0].name : null)
    };

    const open = () => {
        setModalOpen(true)
        setLoading(false)
        setSelectedCertificate(certificates.length > 0 ? certificates[0].name : null)
        setSignReport(certificates.length > 0)
        setCertificatePassword('')
    };

    const close = () => {
        if ( ! loading){
            setModalOpen(false)
        }
    };

    const toggleIncludeDp = () => setIncludeDp(!includeDp)
    const toggleSignReport = () => setSignReport(!signReport)

    const downloadPdfReport = () => {
        // check the download conditions here, if the enter button was clicked, the button disable would be bypassed
        if(loading || (!certificatePassword && signReport)) return;

        setLoading(true)
        GeneratePDFReport()
    };
    
    const GeneratePDFReport = async () => {
        let password = signReport ? certificatePassword : null;
        let generated = await logContext.generatePdfLogReport( rotateGraph, includeDp, selectedCertificate as string | null,
                password, maintainZoom? props.zoomInfo : undefined)
        setLoading(false)
        setModalOpen(!generated)
    }
    
        return (
            <Modal open={modalOpen} size='tiny'
                   trigger={
                       <Popup content={localeStore.Strings.logTooLargeToShow} disabled={logContext.supportedSize} openOnTriggerMouseEnter trigger={
                           <span>
                <Button color={"blue"} content={localeStore.Strings.pdfReport} icon='file pdf' onClick={open} disabled={props.disabled ||! logContext.supportedSize}/>
                           </span>}/>
                           }>
                <Modal.Header>Create PDF Report</Modal.Header>
                <Modal.Content>

                    <Form>
                        {canUseZoom? <>
                            <Form.Checkbox toggle label={localeStore.Strings.maintainZoom} onChange={() => setMaitnainZoom(!maintainZoom)} checked={maintainZoom}/>
                            <Divider hidden/>
                        </> : null}
                        <Form.Checkbox toggle label={localeStore.Strings.renderGraphInPortrate} onChange={() => setRotateGraph(!rotateGraph)} checked={rotateGraph}/>
                        <Divider hidden/>
                        <Form.Checkbox toggle label={localeStore.Strings.includeDataPoints} onChange={toggleIncludeDp} checked={includeDp}/>
                        <Divider hidden/>
                        <Form.Checkbox toggle label={localeStore.Strings.digitallySignReport} onChange={toggleSignReport} checked={signReport}/>
                        <Divider hidden/>
                        

                        {signReport ?
                            <span>
                                {certificates.length > 0 ?
                                    <span>
                                        <Form.Dropdown selection label={localeStore.Strings.certificate} onChange={(e, data) => setSelectedCertificate(data.value as string)} name='selectedCertificate'
                                                       value={selectedCertificate as string}
                                                       options={certificates.map(cert => ({
                                                                    key : cert.name, text : cert.name, value : cert.name
                                                                }))} />

                                        <Form.Input label={localeStore.Strings.certificatePassword} placeholder={localeStore.Strings.certificatePassword}
                                                    name='certificatePassword' onChange={(e, data) => setCertificatePassword(data.value)} value={certificatePassword}
                                                    type='password' />
                                        <Divider hidden/>
                                    </span>
                                    :
                                    <Header size='tiny' textAlign='center' disabled content={localeStore.Strings.noCertificatesFound}/>
                                }

                                <CertificateManagementModal onCertificatesUpdated={updateCertificateOptions} />
                            </span>
                            : null
                        }
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button disabled={loading} onClick={close} content={localeStore.Strings.cancel}/>

                    <Button animated color='blue' size='small' disabled={loading || (!certificatePassword && signReport) }
                            loading={loading} onClick={downloadPdfReport} >
                        <Button.Content visible content={localeStore.Strings.downloadPdfReport}/>
                        <Button.Content hidden>
                            <Icon name='download' />
                        </Button.Content>
                    </Button>
                </Modal.Actions>
            </Modal>
        )
}
