import {useContext, useEffect, useState} from 'react';
import {Button, Modal, Form, Header} from 'semantic-ui-react';
import localeStore from "../language/LocaleStore";
import {UserContext} from "../contexts/UserContext";
export interface ResizeGraphModalProps {
    pressure: [number, number]
    time: [Date, Date]
    updateGraphRange: (date: [Date,Date],pressure: [number,number]) => void
}
export default function ResizeGraphModal(props: ResizeGraphModalProps) {
    const userContext = useContext(UserContext)
    const currentLow = (+props.pressure[0].toFixed(5)).toString()
    const currentHigh = (+props.pressure[1].toFixed(5)).toString()
    const startDate = props.time[0]
    const endDate = props.time[1]
    const [visible, setVisible] = useState(false)
    const [lowRange, setLowRange] = useState(currentLow)
    const [highRange, setHighRange] = useState(currentHigh)
        useEffect(() => {
        setLowRange(currentLow)
        setHighRange(currentHigh)
        console.log("updated due to new values")
    }, [props.pressure, props.time])

    const open = () => {
        setVisible(true)
        document.getElementById("lowRangeInput")?.focus()
    }    
    const close = () => setVisible(false);

    const changeSelection = () => {
        props.updateGraphRange([startDate,endDate], [+lowRange, +highRange]);
        setVisible(false)
    };
    
        return (
            <Modal size='tiny' open={visible}
                   trigger={<Button content={localeStore.Strings.adjustRange} onClick={open}/>} >
                <Modal.Header>{localeStore.Strings.changeChartScale}</Modal.Header>
                <Modal.Content>
                    <Form onSubmit={changeSelection}>
                        <Header content={localeStore.Strings.pressureRange} dividing />
                        <Form.Group>
                            <Form.Input 
                                labelPosition={"left corner"}
                                label={`${localeStore.Strings.lowRange} (${userContext.pressureUnit})`} value={lowRange}
                                    onChange={(e, data) => setLowRange(data.value)} type='number'/>
                            <Form.Input 
                                labelPosition={"left corner"}
                                label={`${localeStore.Strings.highRange} (${userContext.pressureUnit})`} value={highRange}
                                    onChange={(e, data) => setHighRange(data.value)} type='number' />
                        </Form.Group>
                        </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={close} content={localeStore.Strings.cancel} />
                    <Button onClick={changeSelection} content={localeStore.Strings.scale} />
                </Modal.Actions>
            </Modal>
        )
}
