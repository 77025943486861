import {Divider, Dropdown, Header, Input, Segment} from "semantic-ui-react";
import {GeneralConfig} from "../../Models/Config/GeneralConfig";
import localeStore from "../../language/LocaleStore";
import {useContext} from "react";
import {GaugeContext} from "../../contexts/GaugeContext";
import {LanguageContext} from "../../contexts/LanguageContext";

const automaticShutoffOptions = [
    {key: '5', text: localeStore.Strings.xMinutes.replace('###', '5'), value: 5},
    {key: '10', text: localeStore.Strings.xMinutes.replace('###', '10'), value: 10},
    {key: '20', text: localeStore.Strings.xMinutes.replace('###', '20'), value: 20},
    {key: '30', text: localeStore.Strings.xMinutes.replace('###', '30'), value: 30},
    {key: '40', text: localeStore.Strings.xMinutes.replace('###', '40'), value: 40},
    {key: '50', text: localeStore.Strings.xMinutes.replace('###', '50'), value: 50},
    {key: '60', text: localeStore.Strings.oneHour, value: 60},
    {key: '0', text: localeStore.Strings.alwaysOn, value: 0},
];

export interface GeneralChasisConfigProps {
    config: GeneralConfig

    handleChange(newConfig: GeneralConfig): void;
}

export default function GeneralChasisConfig(props: GeneralChasisConfigProps) {
    const gaugeContext = useContext(GaugeContext)
    const translate = useContext(LanguageContext)
    const disableControls = gaugeContext.lock.lockConfig || !gaugeContext.Gauge.connected
    const handleMessageStoreChange = (message: string) => {
        let newConfig = Object.assign({}, props.config)
        newConfig.messageStore = gaugeContext.toAcceptedString(message)
        newConfig.messageStore = newConfig.messageStore.substring(0, 240)
        props.handleChange(newConfig)
    }
    const handleNameChange = (name: string) => {
        let newconfig = {...props.config, name: gaugeContext.toAcceptedString(name.substring(0,63))}
        props.handleChange(newconfig)
    }
    const handleAutomaticShutoffChange = (autoShuttoff: number) => {
        let newConfig = Object.assign({}, props.config)
        newConfig.automaticShutoff = autoShuttoff
        props.handleChange(newConfig)
    }

    return <Segment>
        <Header content={"General Settings"}/>
        <Divider/>
        {props.config.name == null ? undefined :
            <div>
                <Header content={translate.getString("gaugeNameHeader")} dividing size={"small"}/>
                <Input
                    fluid placeholder={translate.getString("gaugeNamePlaceholder")}
                    value={props.config.name}
                    onChange={(e, {value}) => handleNameChange(value)}
                    disabled={disableControls}
                />
            </div>
        }
        <Header content={localeStore.Strings.messageStore} dividing size='small'/>
        <Input
            fluid placeholder={localeStore.Strings.messageStore}
            name='messageStore'
            onChange={(e, data) => handleMessageStoreChange(data.value)}
            value={props.config.messageStore}
            disabled={disableControls}
        />
        <Header content={localeStore.Strings.automaticShutoff} dividing size='small'/>
        <Dropdown
            selection options={automaticShutoffOptions}
            name='automaticShutoff'
            onChange={(e, data) => handleAutomaticShutoffChange(data.value as number)}
            value={props.config.automaticShutoff}
            disabled={disableControls}
        />
    </Segment>
}