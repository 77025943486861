import {useContext, useEffect, useState} from "react";
import {GaugeContext} from "../../contexts/GaugeContext";
import {DeviceAgentFeatureFilter} from "../../utils/DeviceAgentFeatureFilter";
import {SimpleVersion} from "@ametektci/ametek.stcappscommon";
import {PasswordModal} from "../../utils/PasswordModal";
import {Button} from "semantic-ui-react";
import localeStore from "../../language/LocaleStore";

export function UpdatePasswordSection()
{
    const [passwordModalVisible, setPasswordModalVisible] = useState(false)
    const gauge = useContext(GaugeContext).Gauge
    useEffect(() => {
        setPasswordModalVisible(false)
    }, [gauge]);
    return (
        <DeviceAgentFeatureFilter
            requiredVersion={new SimpleVersion(1, 6, 0, 707)}
            doesHave={
                <PasswordModal
                    visible={passwordModalVisible}
                    setVisible={setPasswordModalVisible}
                    trigger={
                        <Button
                            onClick={() => setPasswordModalVisible(true)}
                            disabled={!gauge.connected}
                        >
                            {gauge.locked ? localeStore.Strings.UnlockButtonLabel : localeStore.Strings.LockButtonLabel}
                        </Button>
                    }
                />
            }
            doesNotHave={<Button
                disabled={true}
            >
                {gauge.locked ? localeStore.Strings.UnlockButtonLabel : localeStore.Strings.LockButtonLabel}
            </Button>}
        />
    )
}