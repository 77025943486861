import './index.css';
import 'semantic-ui-css/semantic.min.css'
import AppWithAuth from "./AppWithAuth";
import localeStore from "./language/LocaleStore";
import {ExportEnvironmentSettings} from "./utils/EnvironmentSettings";
import {SetLanguage, SetEnvironment} from "@ametektci/ametek.stcappscommon";
import {createRoot} from "react-dom/client";
import config from 'devextreme/core/config';
import {DevExtreme_Licence } from './DevExtremeLicence';

SetLanguage(localeStore.getCurrentLanguage())
SetEnvironment(ExportEnvironmentSettings())
config({ licenseKey : DevExtreme_Licence });

const root = createRoot(document.getElementById('root')!)
root.render(<AppWithAuth />)
