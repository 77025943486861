import {Dropdown, Form, Header, Segment} from "semantic-ui-react";
import localeStore from "../../language/LocaleStore";
import {CustomUnit} from "../../Models/Config/CustomUnit";
import {resolutionOptions} from "../../utils/resolutionOptions";
import {useContext, useEffect, useState} from "react";
import {GaugeContext} from "../../contexts/GaugeContext";
import {LanguageContext} from "../../contexts/LanguageContext";
import {UserContext} from "../../contexts/UserContext";
import {units} from "../../utils/UnitDisplay";
import {PressureValue, Unit} from "../../utils/PressureValue";
import {CustomUnitBestFit} from "./CustomUnitBestFit";

export interface CustomUnitConfigurationProps {
    customUnit: CustomUnit
    onChange: (unit: CustomUnit) => void
}
export interface CustomUnitWizardProps {
    currentUnit: CustomUnit
    open: boolean, 
    onclose: () => void, 
    setUnit: (newUnit: CustomUnit) => void
}
export default function CustomUnitConfiguration(props: CustomUnitConfigurationProps) {
    const gaugeContext = useContext(GaugeContext)
    const userContext = useContext(UserContext)
    const translate = useContext(LanguageContext)
    const disableControls = gaugeContext.lock.lockConfig || !gaugeContext.Gauge.connected
    const [testValue, setTestValue] = useState(100)
    const unit = userContext.pressureUnit.toLowerCase() != "user"? units.find(u => u.unit == userContext.pressureUnit.toUpperCase()) : units.find( u => u.unit == "PSI")
    const pressureUnit = new PressureValue(testValue, {slope: '1', resolution: 1, offset:'0'}, unit?.unit.toLowerCase() as Unit)
    const [bestFitOpen, setBestFitOpen] = useState(false)
    useEffect(() => {
        setBestFitOpen(false)
    }, [disableControls])
    const onSetUnit = (unit: CustomUnit) => {
        props.onChange(unit)
    }
    const onSlopeChange = (newSlope: String) => {
        let newCustom = Object.assign(props.customUnit)
        newCustom.slope = newSlope
        props.onChange(newCustom)
    }
    const onOffsetChange = (newOffset: string) => {
        props.onChange({
            ...props.customUnit, offset: newOffset
        })
    }
    const onResolutionChange = (newResolution: string) => {
        props.onChange({
            ...props.customUnit, resolution: Number.parseFloat(newResolution)
        })
    }
    const calculateNewPressure = () =>
    {
        let result = pressureUnit.getValue("user", {slope: props.customUnit.slope, offset: props.customUnit.offset, resolution: props.customUnit.resolution})
        return result
    }
    return (
        <Segment>
            <Form>
                <Header content={localeStore.Strings.customUserUnit} dividing size='small'/>
                <Header content={localeStore.Strings.value} dividing size='tiny'/>
                <Form.Group inline={true} widths={"equal"}>
                    <Form.Input
                        size='small'
                        label={localeStore.Strings.factor}
                        labelPosition='left corner'
                        type='number'
                        onChange={(e, value) => onSlopeChange(value.value)}
                        value={props.customUnit.slope}
                        disabled={disableControls}
                    />
                    <Form.Input
                        size='small'
                        label={localeStore.Strings.offset}
                        labelPosition='left corner'
                        type='number'
                        onChange={(e, data) => onOffsetChange(data.value)}
                        value={props.customUnit.offset}
                        disabled={disableControls}
                    />
                </Form.Group >
                {disableControls? null : <Form.Group >
                    <Form.Button content={translate.getString("linearFitModalButton")} onClick={() =>setBestFitOpen(true)}/>
                </Form.Group>
                }
                <Header content={localeStore.Strings.resolution} dividing size='tiny'/>
                <Dropdown
                    selection
                    options={resolutionOptions}
                    name='userDefinedUnit.resolution'
                    onChange={(e, data) => onResolutionChange(data.value as string)}
                    value={props.customUnit.resolution}
                    disabled={disableControls}
                />
                <Header content={translate.getString("testUnit")} dividing size='tiny'/>
                <Form.Group inline={true} widths={"equal"} >
                    <Form.Input
                        size={"small"}
                        label={unit?.display}
                        labelPosition={"left corner"}
                        type={"number"}
                        value={testValue}
                        onChange={(e, {value}) => setTestValue(parseInt(value))}
                    />
                    <Form.Input
                        size={"small"}
                        label={translate.getString("exampleUserUnitLabel")}
                        labelPosition={"left corner"}
                        readOnly
                        value={calculateNewPressure()}
                        
                    />
                </Form.Group>
            </Form>
            <CustomUnitBestFit 
                open={bestFitOpen}
                onclose={() => setBestFitOpen(false)}
                setUnit={onSetUnit}
                currentUnit={props.customUnit}
            />
        </Segment>
    )
}