import {createContext, PropsWithChildren, useContext, useEffect, useState} from "react";
import {
    AuthContext,
    DateUtils,
    Log,
    messageQueue,
} from "@ametektci/ametek.stcappscommon";
import {TimestampedMeasurements} from "../Models/DataLogging";
import axios, {AxiosResponse} from "axios";
import {GetEnvironmentSetting} from "../utils/EnvironmentSettings";
import localeStore from "../language/LocaleStore";
import deviceHubConnection from "../signalr/DeviceHubConnection";
import {GaugeContext} from "./GaugeContext";
import {GeneratePDFRequest, GeneratePdfResponse, ZoomInfo} from "../Models/Requests/GeneratePDFRequest";
import download from "downloadjs";
import {UserContext} from "./UserContext";
import {APIContext} from "./APIContext";
import {LanguageContext} from "./LanguageContext";
import {LogsContext} from "./LogsContext";
import {ConvertTypeOneLogs} from "../logs/datalogging/Conversions/TypeOne";
import {conversions, getResolution, Unit} from "../utils/PressureValue";
import {CustomUnit} from "../Models/Config/CustomUnit";
import {GaugesContext} from "./GaugesContext";
export const LogContext = createContext({
    log: { logId: 0 } as Log,
    loadedFileData : undefined as TimestampedMeasurements[] | undefined,
    loadedLogId: undefined as undefined | number,
    logLoadError: undefined as string | undefined,
    supportedSize: false as boolean,
    deleteLog: () => Promise.resolve(false),
    requestDeleteLog: () => {},
    renameSensor: (sensorPos: number, name: string) => Promise.resolve(),
    updateLogTags: (tags: Array<string>) => Promise.resolve(),
    renameLog: (name: string) => Promise.resolve(),
    archiveLog: () => Promise.resolve(false),
    loadLog: () => Promise.resolve(),
    downloadLogCsvFile: () => Promise.resolve(),
    downloadLogExcelFile: () => Promise.resolve(),
    getLoadedFileData: () => [] as TimestampedMeasurements[],
    generatePdfLogReport: (rotateGraph: boolean, includeDataPoints: boolean, certificateName: string | null, certificatePassword: string | null, zoomInfo?: ZoomInfo) => Promise.resolve(false as boolean),
})


//Used for storage outside LogContext.
let fileDataCache = {} as { [key: string]: string }

export interface logContextWrapperProps extends PropsWithChildren {
    log: Log
}
export function LogContextWrapper(props: logContextWrapperProps) {
    const auth = useContext(AuthContext)
    const api = useContext(APIContext)
    const demo = useContext(GaugesContext).demoMode
    const translate = useContext(LanguageContext)
    const userContext = useContext(UserContext)
    const logsContext = useContext(LogsContext)
    const gaugeContext = useContext(GaugeContext)
    const serial = gaugeContext.Gauge.serialNumber
    const log = props.log
    const [size, setSize] = useState(0)
    useEffect(() => {
        if (log.uploaded)
            api.GetFileURL(log.filename, gaugeContext.Gauge.serialNumber, demo).then(([_,newSize]) => {
                setSize(newSize)
            })
    }, [props.log.filename, log.uploaded])
    const deleteLogRequest = async () =>{
        let deletedFromDb = false
        await axios.post(GetEnvironmentSetting('CcwApiUrl') + "/DeleteLog", {
                LogId: log.logId,
            },
            {headers:{
                    "Authorization":"bearer "+ await auth.getAccessToken()
                }}
        ).then((response) => {
            logsContext.deleteLog(gaugeContext.Gauge.serialNumber, log.logId);
            messageQueue.sendSuccess(localeStore.Strings.logDeletedConfirmationMessage);
            deletedFromDb = true
        }).catch((error) => {
            console.log(error);
            messageQueue.sendError(localeStore.Strings.errorDeletingLog);
        })
        return deletedFromDb;
    }
    const deleteLog = async () => {
        return await deleteLogRequest()
    }
    const requestDeleteLog = () => {
        deviceHubConnection.requestDeleteLog(serial,log.logId,log.filename,gaugeContext.Gauge.locked ? gaugeContext.lock.password : undefined);
    }

    const renameSensor = async (sensorPosition: number, name: string) => {
        await axios.post(GetEnvironmentSetting('CcwApiUrl') + "/UpdateSensorName", {
                LogId: log.logId,
                SensorPosition: sensorPosition,
                Name: name
            },
            {headers: {
                    "Authorization":"bearer " + await auth.getAccessToken()
                }}).then((response) => {
            if (response.data.success) {
                gaugeContext.updateSensorName(log.logId, sensorPosition, name);
            } else {
                console.log(response.data.errorMessage);
                messageQueue.sendError(localeStore.Strings.errorRenamingSensor);
            }
        }).catch((error) => {
            console.log(error);
            messageQueue.sendError(localeStore.Strings.errorRenamingSensor);
        })
    }
    const updateLogTags = async (tags:Array<string>)=> {
        try
        {
            let response = await axios.post(GetEnvironmentSetting('CcwApiUrl') + "/Log/Tags", {
                LogId: log.logId,
                RunTags: tags
            },
            {headers: {
                    "Authorization":"bearer " + await auth.getAccessToken()
                }})
            if (response.status == 204 || response.status == 200) {
                gaugeContext.updateLogTags(log.logId, tags.join(","));
            } else {
                console.log(response.data.errorMessage);
                messageQueue.sendError(localeStore.Strings.errorUpdatingLogTags);
            }
        }
        catch (error) 
        {
            console.log(error);
            messageQueue.sendError(localeStore.Strings.errorUpdatingLogTags);
        }
    }
    const renameLog = async (name: string) => {
        // if in demo mode
        if (log.logId === -1) {
            gaugeContext.updateLogName(log.logId, name);
            return;
        }

        axios.post(GetEnvironmentSetting('CcwApiUrl') + "/UpdateLog", {
                LogId: log.logId,
                Name: name
            },
            {headers: {
                    "Authorization":"bearer " + await auth.getAccessToken()
                }}).then((response) => {
            if (response.data.success) {
                gaugeContext.updateLogName(log.logId, name);
            } else {
                console.log(response.data.errorMessage);
                messageQueue.sendError(localeStore.Strings.errorRenamingLog);
            }
        }).catch((error) => {
            console.log(error);
            messageQueue.sendError(localeStore.Strings.errorRenamingLog);
        })
    }
    const archiveLog = async () => {
        return axios.post(GetEnvironmentSetting('CcwApiUrl') + "/ArchiveLog", {
                LogId: log.logId,
                IsArchived: !log.archived
            },
            {headers: {
                    "Authorization":"bearer " + await auth.getAccessToken()
                }}).then((response) => {
            if (response.data.success) {
                // update the data store
                logsContext.toggleLogArchived(gaugeContext.Gauge.serialNumber, log.logId, !log.archived)
                // announce changes
                messageQueue.sendSuccess(!log.archived ? localeStore.Strings.logArchived : localeStore.Strings.logRemovedFromArchive);
                return true
            } else {
                console.log(response.data.errorMessage);
                messageQueue.sendError(localeStore.Strings.errorArchivingLog);
                return false
            }
        }).catch((error) => {
            console.log(error);
            messageQueue.sendError(localeStore.Strings.errorArchivingLog);
            return true
        })
    }
    //uses actual fileData here
    const [loadedFileData, setLoadedFileData] = useState<TimestampedMeasurements[]>()
    const [loadedLogId, setLoadedLogId] = useState<number>()
    const [logLoadError, setLogLoadError] = useState<string | undefined>()
    const loadLog = async () => {
        setLoadedLogId(undefined)
        // check and see if we've already loaded this log
        let cachedFileData = fileDataCache[log.logId];

        // if the log has already been loaded, pull it from memory
        if (cachedFileData) {
            ConvertUnitData(cachedFileData,(results) => {
                setLoadedFileData(results)
                setLoadedLogId(log.logId)
                setLogLoadError(undefined)
            });
            return;
        }

        try {
            let fileUrl = await api.GetFileURL(log.filename, gaugeContext.Gauge.serialNumber, demo)
                if (fileUrl[1] >=4)
                {
                    setLoadedLogId(log.logId)
                    setLogLoadError(translate.getString('logTooLargeToShow'))
                    setLoadedFileData(undefined)
                    return;
                    //complain about file size
                }
            let fileDataResponse = await axios.get(fileUrl[0])
                .catch(error => console.error('could not load file data', error));
            // file encoding off of the hpc50 is USC-2 LE
            let fileData = fileDataResponse?.data.replaceAll("\r", ""); //replacing \r turns any windows line endings into linux line endings. This fixes a lot of parse problems.

            // save to the cache
            fileDataCache[log.logId] = fileData;

            ConvertUnitData(fileData, (results) => {
                setLoadedFileData(results)
                setLoadedLogId(log.logId)
                setLogLoadError(undefined)
            });
        } catch (error) {
            console.error(error)

            messageQueue.sendError(localeStore.Strings.errorLoadingLog);
            setLoadedLogId(log.logId)
            setLoadedFileData(undefined)
            setLogLoadError(translate.getString("errorLoadingLog"))
        }
    }
    const ConvertUnitData = (csvData: string, callback:(results: Array<TimestampedMeasurements>) => void ) => {
        let gaugeInfo = gaugeContext.Gauge.model.name.split("-")
        let customUnits = gaugeContext.getCustomUnits()
        let dataPoints = csvData.split("\n",20)
        if ((dataPoints[0].trim() == ''))
            dataPoints.shift()
        let firstLine = dataPoints[0].split(",")
        let resolution = getResolution(gaugeInfo[0].toLowerCase(),gaugeInfo[1].toUpperCase(),(userContext.graphUnit??userContext.pressureUnit).toLowerCase() as Unit, customUnits)
        switch (firstLine[firstLine.length -1]) {
            case "Beta":
            case "1":
                ConvertTypeOneLogs(log, csvData, (userContext.graphUnit??userContext.pressureUnit), userContext.temperatureUnit, resolution, customUnits[0] ?? {offset: "0", slope: "1", resolution: 4}, callback)
                break
            default:
                console.error("Error: Log type not recognized. ", firstLine)
                callback([])
                break //Log standard could not be determined
        }
    }
    const downloadLogCsvFile = async () => {
            await api.GetFileURL(log.filename, gaugeContext.Gauge.serialNumber, demo)
                .then( async ([url, size]) => {
                    return (await axios.get(url, {responseType: 'blob'})).data
                })
                .then(file => {
                    download(file, (log.name ?? log.filename)+".csv", 'text/csv');
        }).catch(error => {
            console.log(error);
            messageQueue.sendError(localeStore.Strings.errorLoadingLog);
        });
    }
    const downloadLogExcelFile = async () => {
        await api.DownloadExcel(log.logId)
            .then(file => {
                download("data:application/xlsx;base64," + file, (log.name ?? log.filename)+".xlsx", 'application/xls');
            }).catch(error => {
                console.error(error);
                let code = error?.response?.data?.responseStatus?.errorCode
                switch (code)
                {
                    case "FileTooLargeException":
                        messageQueue.sendError(localeStore.Strings.logTooLargeToConvert)
                        break;
                    default:
                        messageQueue.sendError(localeStore.Strings.errorLoadingLog);
                        break;
                }
            
        });
    }
    const getLoadedFileData = () => {
        return loadedFileData ?? [];
    }
    const generatePdfLogReport = async (rotateGraph: boolean, includeDataPoints: boolean, certificateName: string | null, certificatePassword: string | null, zoomInfo?: ZoomInfo) => {
        let pressureUnit = (userContext.graphUnit??userContext.pressureUnit)
        let unit : CustomUnit
        if (pressureUnit.toLowerCase() == "user")
            unit = gaugeContext.getCustomUnits()[0]!
        else 
            unit = {
                slope: conversions[pressureUnit.toLowerCase() as keyof typeof conversions],
                offset: "0",
                resolution: getResolution("xp3i", gaugeContext.Gauge.model.name.split("-")[1], pressureUnit, gaugeContext.getCustomUnits())
            }
        let fixedZoomInfo = zoomInfo;
        if (fixedZoomInfo != null) {
            //Don't fall for the trap here. We need the *local* time, not the UTC time.
            fixedZoomInfo.timeRange[0] = new Date(fixedZoomInfo.timeRange[0].getTime() - fixedZoomInfo.timeRange[0].getTimezoneOffset() * 60000);
            fixedZoomInfo.timeRange[1] = new Date(fixedZoomInfo.timeRange[1].getTime() - fixedZoomInfo.timeRange[1].getTimezoneOffset() * 60000);
        }
        
        return await axios.post<GeneratePdfResponse, AxiosResponse<GeneratePdfResponse>, GeneratePDFRequest>(GetEnvironmentSetting('CcwApiUrl') + "/GeneratePdf", {
                CertificateName: certificateName,
                CertificatePassword: certificatePassword,
                IncludeDataPoints: includeDataPoints,
                LogId: log.logId,
                PressureUnit: pressureUnit,
                TemperatureUnit: userContext.temperatureUnit,
                portraitGraph:  rotateGraph,
                customPressureUnit: unit,
            colors: userContext.graphColors,
            zoomInfo: fixedZoomInfo,
            dateFormat: userContext.xAxisFormat,
            },
            {
                headers: {
                    "Authorization":"bearer " + await auth.getAccessToken()
                }
            }).then((response) => {
            if (response.data.success) {
                let filename = `CrystalControlWebReport-${DateUtils.FormatFileSafeTimeStamp(log.startTime)}.pdf`;
                download("data:application/pdf;base64," + response.data.pdf, filename, 'application/pdf');
                messageQueue.sendSuccess(localeStore.Strings.pdfReportGenerated)
            } else {
                messageQueue.sendError(response.data.errorMessage, localeStore.Strings.errorGeneratingPdf);
            }
            return response.data.success

        }).catch((error) => {
            console.log(error);
            messageQueue.sendError(localeStore.Strings.errorGeneratingPdf);
            return false
        })
    }
    
    //End uses actual fileData
    
    return (
        <LogContext.Provider value={ {
            log,
            loadedFileData,
            loadedLogId,
            logLoadError,
            supportedSize: size < 4,
            deleteLog,
            requestDeleteLog,
            renameSensor,
            updateLogTags,
            renameLog,
            archiveLog,
            loadLog,
            downloadLogCsvFile,
            downloadLogExcelFile,
            getLoadedFileData,
            generatePdfLogReport,
        } } >
            {props.children}
        </LogContext.Provider>
    )

}