import {SimpleVersion} from "@ametektci/ametek.stcappscommon";
import React, {useContext} from "react";
import {GaugeContext} from "../contexts/GaugeContext";
import {Popup} from "semantic-ui-react";
import {LanguageContext} from "../contexts/LanguageContext";

interface DeviceAgentFeatureFilterProps
{
    requiredVersion: SimpleVersion
    doesNotHave: React.ReactNode
    doesHave: React.ReactNode
}
export function DeviceAgentFeatureFilter(props: DeviceAgentFeatureFilterProps)
{
    const translate = useContext(LanguageContext)
    const gauge = useContext(GaugeContext).Gauge!
    if (gauge.connectedDeviceAgentVersion?.isNewerThan(props.requiredVersion) ?? false)
        return <div>{props.doesHave}</div>
    return <Popup position={"bottom center"} trigger={<div>{props.doesNotHave}</div>} content={translate.getString("deviceAgentDoesNotSupportFeature").replace("###",props.requiredVersion.toString() )} />
}