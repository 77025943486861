export interface BlueSnapShopper {
    vaultedShopperId: number,
    firstName: string,
    lastName : string,
    country: string,
    state: string,
    city: string,
    address: string,
    address2: string,
    email: string,
    zip: string,
    phone: string,
    companyName: string,
    shippingContactInfo:ShippingContactInfo
}
export interface ShippingContactInfo{
    country: string,
    state: string,
    city: string,
    zip:string,
    address1: string,
    address2: string,
}

export const DefaultShopper : BlueSnapShopper ={
    vaultedShopperId: 0,
    firstName: "",
    lastName : "",
    country: "us",
    state: "",
    city: "",
    address: "",
    address2: "",
    email: "",
    zip: "",
    phone: "",
    companyName: "",
    shippingContactInfo:{
        country: "us",
        state: "",
        city: "",
        address1: "",
        address2: "",
        zip: "",
    }
}


