import {ShopperContext} from "./shopperContext";
import {ShopperInfo} from "./ShopperInfo";
import {BlueSnapShopper} from "../../Models/BlueSnapShopper";
import {Country, State} from "../../Models/LocationModels";
import {Translatable} from "../../contexts/LanguageContext";
interface ManageShopperProps {
    shopper: BlueSnapShopper
    updateShopper: (shopper: BlueSnapShopper) => Promise<void>
    countries: Array<Country>
    states: Array<State>
    translate: (a: Translatable) => string
    summaryOnly: boolean
    isOpen: (a: boolean) => void
}
export function ManageShopper(props: ManageShopperProps)
{
    return (
        <ShopperContext.Provider value={{
            shopper: props.shopper,
            updateShopper: props.updateShopper,
            countries: props.countries,
            states: props.states,
            translate: props.translate,
        }}>
            <ShopperInfo summaryOnly={props.summaryOnly} isOpen={props.isOpen}/>
        </ShopperContext.Provider>
    )
}