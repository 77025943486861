import { Component } from 'react';
import {Divider, Dropdown, Grid, Header, Input, Label, Segment} from "semantic-ui-react";
import localeStore from "../../language/LocaleStore";
import {SensorConfig} from "../../Models/Config/SensorConfig";

const resolutionOptions = [
    { key : '5', text : '1.00000', value : 5 },
    { key : '4', text : '1.0000', value : 4 },
    { key : '3', text : '1.000', value : 3 },
    { key : '2', text : '1.00', value : 2 },
    { key : '1', text : '1.0', value : 1 },
    { key : '0', text : '1', value : 0 },
];
export interface ElectricalSensorConfigProps {
    sensor: string
    config: SensorConfig
    handleChange: (config: SensorConfig, valid: boolean) => void
    sensorName: string
    sensorUnit: string
}
export interface ElectricalSensorConfigState {
    userspanError: string | null
    offsetError: null | string
}
export default class ElectricalSensorConfig extends Component<ElectricalSensorConfigProps,ElectricalSensorConfigState> {
    constructor(props : ElectricalSensorConfigProps) {
        super(props);

        this.state = {
            userspanError : null,
            offsetError : null
        }
    }

    isValid = () => {
        return !(this.state.userspanError || this.state.offsetError)
    } 
    onUserSpanChange = (newSpan: string) => {
        let newConfig = Object.assign({}, this.props.config)
        newConfig.userspan = Number(newSpan)
        let valid =  0.95 <= newConfig.userspan && newConfig.userspan <= 1.05;
        this.setState({userspanError : valid ? null : localeStore.Strings.userspanValidation});
        this.props.handleChange(newConfig, this.isValid() && valid)
    }
    onOffsetChange = (newOffset: string) => {
        let newConfig = Object.assign({}, this.props.config)
        newConfig.offset = Number(newOffset)
        let valid =  -1 <= newConfig.userspan && newConfig.userspan <= 1;
        this.setState({offsetError : valid ? null : localeStore.Strings.offsetValidation});
        this.props.handleChange(newConfig, this.isValid() && valid)
    }
    onResolutiuonChange = (newResolution : string) => {
        let newConfig = Object.assign({}, this.props.config)
        newConfig.resolution = Number(newResolution)
        this.props.handleChange(newConfig, this.isValid())
    }
    
    render() {
        let sensor = this.props.config;
        return (
            <Segment>
                <Header size='huge'>
                    {this.props.sensorName}
                    <Label basic color='black' size='large'>SN: {sensor.serialNumber}</Label>
                </Header>

                <Divider hidden />

                <Grid>
                    <Grid.Column width={10}>
                        <Segment>
                            <Header content={localeStore.Strings.calibrationData} />

                            <Header content={localeStore.Strings.userspan} dividing size='small'/>
                            <Input type='number' name='userspan' error={!!this.state.userspanError}
                                   step={0.01} onChange={(e, data) => this.onUserSpanChange(data.value)} value={sensor.userspan.toFixed(sensor.resolution)} />

                            <Header content={localeStore.Strings.offset} dividing size='small'/>
                            {/*todo: show the appropriate number of digits  */}
                            <Input type='number' name='offset' error={!!this.state.offsetError}
                                   label={this.props.sensorUnit} labelPosition='right' step={0.01}
                                   onChange={(e, data) => this.onOffsetChange(data.value)} value={sensor.offset}/>

                        </Segment>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Segment>
                            <Header content={localeStore.Strings.generalSettings} />

                            <Header content={localeStore.Strings.resolution} dividing size='small' />
                            <Dropdown selection fluid options={resolutionOptions} name='resolution'
                                      onChange={(e, data) => this.onResolutiuonChange(data.value as string)} value={sensor.resolution} />

                        </Segment>
                    </Grid.Column>
                </Grid>
            </Segment>
        )
    }
}
