import {Log} from "@ametektci/ametek.stcappscommon";
import localstore from "../../language/LocaleStore"
import moment, {MomentInput} from "moment";
import {DataSeries, GraphSettings} from "../../Models/DataLogging";
import {convertUnitToReadable} from "../../utils/UnitDisplay";

let moduleStore = localstore
export class ChartUtils {
    static CalculateGraphHeight() : number {
        if (800 < window.screen.availHeight)
            return 500;
        if (650 < window.screen.availHeight)
            return 400;
        return 300;
    }

    /**
     * @return {string}
     */
    static WhiteOrBlackText(bgColor : string) : "#000" | "#FFF" {
        let color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
        let r = parseInt(color.substring(0, 2), 16); // hexToR
        let g = parseInt(color.substring(2, 4), 16); // hexToG
        let b = parseInt(color.substring(4, 6), 16); // hexToB
        return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
            '#000' : '#FFF';
    }

    static GenerateGraphSettingsForLog(log : Log) : GraphSettings {
        let availableAxes = ['y', 'y2', 'y3', 'y4'];
        let labels = [moduleStore.Strings.time];
        let labelHeaders : Array<string> = [];
        let visibility : Array<boolean>= [];
        let series : DataSeries = {};

        for (let sensor of log.sensors) {
            let name = sensor.name ?? moduleStore.Strings.readings ;
            let unit = sensor.unit;
            labels.push(name);
            labelHeaders.push(convertUnitToReadable(unit) as string);
            visibility.push(true);
            series[name] = {axis: availableAxes.shift() as string};
        }

        return {labels, labelHeaders, visibility, series};
    }
    static getTimeString(firstXValue : MomentInput, lastDate: MomentInput)
    {
        let totalDuraton = moment(lastDate).diff(moment(firstXValue), "seconds")
        let fullString = "ss.S"
        if (totalDuraton < 60)
            return fullString
        fullString = "mm:" + fullString
        if (totalDuraton < 3600)
            return  fullString
        fullString = "HH:" + fullString
        if (totalDuraton < (3600*24))
            return fullString
        fullString = "[{day} days] "+fullString
        return fullString
    }
    static GetDateTimeValueFormatter(format : string, firstXValue : MomentInput, lastDate: MomentInput) {
        if (format.toLowerCase() === 'duration') {
            let fullString = this.getTimeString(firstXValue, lastDate)
            return (xValue: MomentInput) => moment.utc(moment.duration(moment(xValue).diff(moment(firstXValue))).asMilliseconds())
                .format(fullString.replace("{day}", moment(xValue).diff(moment(firstXValue), 'days').toString()))
        }
        if (format.toLowerCase() === 'us')
            return (xValue: MomentInput) => moment(xValue).format("MM/DD/YY HH:mm:ss:S")
        if (format.toLowerCase() === 'international')
            return (xValue: MomentInput) => moment(xValue).format("YY-MM-DD HH:mm:ss:S")

        throw new Error("Unknown Datetime Value Format, see method for valid options");
    }
}
