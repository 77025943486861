import {Button, Dropdown, Header, Input, Message, Segment} from "semantic-ui-react";
import localeStore from "../../language/LocaleStore";
import moment from "moment/moment";
import {DateUtils} from "@ametektci/ametek.stcappscommon";
import {useContext, useState} from "react";
import {CalibrationDates} from "../../Models/Config/CalibrationDates";
import {GaugeContext} from "../../contexts/GaugeContext";
import {LanguageContext} from "../../contexts/LanguageContext";


const warningTypeOptions = [
    {key: 'disabled', text: localeStore.Strings.disabled, value: 0},
    {key: 'alternateWithReadings', text: localeStore.Strings.alternateWithReadings, value: 1},
    {key: 'always', text: localeStore.Strings.alwaysOn, value: 2},
    {key: 'startup', text: localeStore.Strings.startup, value: 3},
];

export interface GaugeCalibrationDatesProps {
    config: CalibrationDates

    handleChange(newConfig: CalibrationDates, valid: boolean): void;
}

export default function GaugeCalibrationDates(props: GaugeCalibrationDatesProps) {
    const translate = useContext(LanguageContext)
    const gaugeContext = useContext(GaugeContext)
    const disableControls = gaugeContext.lock.lockConfig || !gaugeContext.Gauge.connected
    const [warningPeriodError, setWarningPeriodError] = useState<string | null>()
    const [calDateError, setCalDateError] = useState<string | null>()
    const [editDates, setEditDates] = useState(false)
    const [calDueError, setCalDueError] = useState<string | null>()
    const toggleEditDates = () => setEditDates(!editDates)
    const allValid = () => {
        return !(calDateError || calDateError || warningPeriodError)
    }
    const handleCalibrationWarningDaysChange = (warningDays: string) => {
        let value = Number(warningDays)
        let valid = 0 <= value;
        setWarningPeriodError(valid ? null : localeStore.Strings.warningPeriodValidation)
        let newConfig = Object.assign({}, props.config)
        newConfig.calibrationWarningDays = value
        props.handleChange(newConfig, allValid())
    }
    const handleCalibrationDateChange = (calibrationDate: string) => {
        let valid = 0 <= moment(calibrationDate).diff('2014-10-01');
        setCalDateError(valid ? null : localeStore.Strings.calDateValidation)
        let newConfig = Object.assign({}, props.config)
        newConfig.calibrationDate = calibrationDate
        props.handleChange(newConfig, allValid())
    }
    const handleCalibrationDueChange = (newDue: string) => {
        let valid = 0 <= moment(newDue).diff(props.config.calibrationDate);
        setCalDueError(valid ? null : localeStore.Strings.calDueValidation);
        let newConfig = Object.assign(props.config)
        newConfig.calibrationDue = newDue
        props.handleChange(newConfig, allValid())
    }
    const handleCalibrationWarningType = (type: number) => {
        let newConfig = Object.assign({}, props.config)
        newConfig.calibrationWarningType = type
        props.handleChange(newConfig, allValid())
    }

    return (
        <Segment>
            <Header content={localeStore.Strings.userCalibrationDates}/>

            <Header content={localeStore.Strings.calibrationDate} dividing size='small'/>
            {editDates ?
                <Input type='date' name='calibrationDate' error={!!calDateError}
                       value={moment(props.config.calibrationDate).format('YYYY-MM-DD')}
                       min='2024-01-01'
                       max={'2099-12-01'}
                       onChange={(e, data) => handleCalibrationDateChange(data.value)}/>
                :
                DateUtils.FormatDate(props.config.calibrationDate)
            }

            <Button content={editDates ? localeStore.Strings.done : localeStore.Strings.edit}
                    floated='right' onClick={toggleEditDates}
                    disabled={!!calDateError || !!calDueError || disableControls}/>

            <Message error content={calDateError} hidden={!calDateError}/>

            <Header content={localeStore.Strings.calibrationDue} dividing size='small'/>
            {editDates ?
                <Input type='date' name='calibrationDue' error={!!calDueError}
                       value={moment(props.config.calibrationDue).format('YYYY-MM-DD')}
                       min={moment(props.config.calibrationDate).format('YYYY-MM-DD')}
                       max={'2099-12-01'}
                       onChange={(e, data) => handleCalibrationDueChange(data.value)}/>
                :
                props.config.calibrationDue == null || props.config.calibrationDue == "" ? translate.getString("notSet") : DateUtils.FormatDate(props.config.calibrationDue)
            }

            <Message error content={calDueError} hidden={!calDueError}/>

            {props.config.calibrationDue == null || props.config.calibrationDue == "" ? null : <>
                <Header content={localeStore.Strings.warningType} dividing size='small'/>
                <Dropdown
                    selection fluid options={warningTypeOptions} name='calibrationWarningType'
                    value={props.config.calibrationWarningType}
                    onChange={(e, data) => handleCalibrationWarningType(data.value as number)}
                    disabled={disableControls}
                />

                <Header content={localeStore.Strings.warningPeriod} dividing size='small'/>
                <Input fluid label='Days' labelPosition='right' name='calibrationWarningDays'
                       type='number' min={0} error={!!warningPeriodError}
                       value={props.config.calibrationWarningDays}
                       onChange={(e, data) => handleCalibrationWarningDaysChange(data.value)}
                       disabled={disableControls}
                />
            </>
            }
            <Message error content={warningPeriodError} hidden={!warningPeriodError}/>
        </Segment>
    )
}