import {useContext, useState} from 'react';
import {Input, Button, Modal, Container, Grid} from 'semantic-ui-react';
import localeStore from "../../language/LocaleStore";
import {LanguageContext} from "../../contexts/LanguageContext";

export interface UserspanProps {
    currentUserspan: number
    onComplete: (value: number) => void
    disabled: boolean
}

export interface UserspanState {
    visible: boolean,
    step: string,
    midActual: number,
    midIndicated: number,
    fullActual: number,
    fullIndicated: number,
    userspan: number,
    validUserspan: boolean
    loading: boolean
}

export default function UserspanWizard(props: UserspanProps) {
    const translate = useContext(LanguageContext)
    const [visible, setVisible] = useState(false)
    const [step, setStep] = useState("start")

    const [fullActual, setFullActual] = useState('2000')
    const [fullIndicated, setFullIndicated] = useState('2000')

    const [midActual, setMidActual] = useState('1000')
    const [midIndicated, setMidIndicated] = useState('1000')

    const userspan = ((2 * (+fullActual)) / ((+fullIndicated) + ((+midIndicated) * (+fullActual) / (+midActual)))) * props.currentUserspan;
    const validUserspan= 0.95 <= userspan && userspan <= 1.05

    const open = () => {
        setVisible(true)
        setStep('start')
        setMidActual('1000')
        setMidIndicated('1000')
        setFullActual('2000')
        setFullIndicated('2000')
    }
    const close = () => {
        setVisible(false)
    }

    const stepBack = () => {
        let newStep = 'start';
        if (step === 'midPoint')
            newStep = 'start';
        else if (step === 'fullSpan')
            newStep = 'midPoint';
        else if (step === 'finish')
            newStep = 'midPoint';
        setStep(newStep)
    }

    const stepNext = () => {
        if (step === 'start')
            setStep('midPoint')
        else if (step === 'midPoint')
            setStep('fullSpan')
        else if (step === 'fullSpan') {
            setStep('finish')
        } else if (step === 'finish' && validUserspan) {
            props.onComplete(userspan);
            close();
        }
    }

    const showStart = () => {
        return (
            <Container style={{"white-space": "pre-line"}}>
                {translate.getString("userspanWizardDescription")}
            </Container>
        )
    }

    const showMidPoint = () => {
        return (
            <Container>
                {translate.getString("userspanWizardMidpointInstructions")}
                <br/><br/>
                <Grid verticalAlign='middle'>
                    <Grid.Row>
                        <Grid.Column width={7}>
                            {translate.getString("userspanWizardAppliedInstructions")}
                        </Grid.Column>
                        <Grid.Column width={9}>
                            <Input fluid type='number' onChange={(e, {value}) => setMidActual(value)} name='midActual'
                                   value={midActual}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={7}>
                            {translate.getString("userspanWizardIndicatedInstructions")}
                        </Grid.Column>
                        <Grid.Column width={9}>
                            <Input fluid type='number' onChange={(e, {value}) => setMidIndicated(value)}
                                   name='midIndicated' value={midIndicated}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        )
    }

    const showFullSpan = () => {
        return (
            <Container>
                {translate.getString("userspanWizardFullScaleInstructions")}
                <br/><br/>
                <Grid verticalAlign='middle'>
                    <Grid.Row>
                        <Grid.Column width={7}>
                            {translate.getString("userspanWizardAppliedInstructions")}
                        </Grid.Column>
                        <Grid.Column width={9}>
                            <Input fluid type='number' onChange={(e, {value}) => setFullActual(value)} name='fullActual'
                                   value={fullActual}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={7}>
                            {translate.getString("userspanWizardIndicatedInstructions")}
                        </Grid.Column>
                        <Grid.Column width={9}>
                            <Input fluid type='number' onChange={(e, {value}) => setFullIndicated(value)}
                                   name='fullIndicated' value={fullIndicated}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        )
    }

    const showFinish = () => {
        return (
            <Container style={{"white-space": "pre-line"}}>
                {(translate.getString(validUserspan ? "userspanWizardGoodUserspan" : "userspanWizardBadUserspan")).replace("###", userspan.toFixed(5))}
            </Container>
        )
    }

    return (
        <Modal size='tiny' open={visible}
               trigger={<Button onClick={open} content='Userspan Wizard' disabled={props.disabled}/>}>
            <Modal.Header>Userspan Wizard</Modal.Header>
            <Modal.Content>
                {step === 'start' ? showStart() : null}
                {step === 'midPoint' ? showMidPoint() : null}
                {step === 'fullSpan' ? showFullSpan() : null}
                {step === 'finish' ? showFinish() : null}
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={stepBack} content='Back' disabled={step === 'start'}/>
                <Button onClick={stepNext} color='blue' content={step === 'finish' ? 'Finish' : 'Next'}
                        disabled={!validUserspan && step === 'finish'}/>
                <Button onClick={close} content={localeStore.Strings.cancel}/>
            </Modal.Actions>
        </Modal>
    )
}