import {Button, Dimmer, Form, Grid, Header, Icon, Loader} from "semantic-ui-react";
import {ChartUtils} from "../../datalogging/ChartUtils";
import {LogDetailsHeader} from "../LogDetailsHeader";
import {DefaultGraphViewRef, GraphView, GraphViewRef} from "./GraphView";
import {SelectedPointViewer} from "./SelectedPointViewer";
import {useContext, useRef, useState} from "react";
import {LogContext} from "../../../contexts/LogContext";
import {TimestampedMeasurements} from "../../../Models/DataLogging";
import {UserContext} from "../../../contexts/UserContext";
import {LanguageContext} from "../../../contexts/LanguageContext";
import ResizeGraphModal from "../../ResizeGraphModal";

export interface GraphModalContentProps {
    error: string | undefined,
    dataLoaded: boolean,
    loading: boolean,
    setTimeRange: (value: [Date, Date]) => void
    setPressureRange: (value: [number, number]) => void
    timeRange: [Date, Date],
    pressureRange: [number, number],
}

export function GraphModalContent(props: GraphModalContentProps) {
    const translate = useContext(LanguageContext)
    const userContext = useContext(UserContext)
    const logContext = useContext(LogContext)
    const data = logContext.loadedFileData ?? []
    const firstDate = data[0]?.date ?? new Date()
    const lastDate = data[data.length-1]?.date ?? new Date()
    const [hoveredPoint, setHoveredPoint] = useState<TimestampedMeasurements>()
    const graphViewRef = useRef<GraphViewRef>(DefaultGraphViewRef);
    const onGraphReset = () => {
            graphViewRef.current?.resetGraph();
    }
    const onGraphResize = (date: [Date,Date], pressure: [number,number]) => {
        graphViewRef.current?.setRange(date, pressure)
    }
    return (
        <div>
            <br/>
            <Grid verticalAlign='middle'>
                <Grid.Column width={6}>
                    <LogDetailsHeader/>
                </Grid.Column>
                <Grid.Column width={10}>
                    <SelectedPointViewer 
                        firstDate={firstDate}
                        lastDate={lastDate}
                        point={hoveredPoint}
                    />
                </Grid.Column>
            </Grid>

            <Dimmer.Dimmable style={{minHeight: ChartUtils.CalculateGraphHeight() + 115}}>

                <Dimmer active={props.loading} inverted>
                    <Loader size='massive'/>
                </Dimmer>

                {props.error ?
                    <Header icon size='huge' textAlign='center' style={{marginTop: '0'}}>
                        <Icon name='chart area' color='red'/>
                        {props.error.split("\n").map(errLine => <p>{errLine}</p>)}
                    </Header>
                    : null
                }

                {props.dataLoaded ?
                    <GraphView setHoveredPoint={setHoveredPoint} setPressureRange={props.setPressureRange} setTimeRange={props.setTimeRange}  ref={graphViewRef} />
                    : null
                }
                <div style={{textAlign: 'center', paddingTop:"1em"}}>
                    <Button content={translate.getString("resetZoom")} onClick={onGraphReset} />
                    <ResizeGraphModal 
                        pressure={props.pressureRange}
                        time={props.timeRange} 
                         updateGraphRange={onGraphResize} 
                    />
                </div>
            </Dimmer.Dimmable>
            <Form>
                <Form.Checkbox toggle onClick={() => userContext.onSetDragToZoom(!userContext.dragToZoom)} label={translate.getString(userContext.dragToZoom ? "dragToZoom" : "dragToPan")} checked={userContext.dragToZoom}/>
            </Form>
        </div>

    )
}