import Chart, {
    ArgumentAxis, AxisLabel,
    Series, ValueAxis,
    ZoomAndPan,
} from 'devextreme-react/chart';
import {PointHoverChangedEvent, ZoomEndEvent, DoneEvent} from "devextreme/viz/chart"
import {forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {LogContext} from "../../../contexts/LogContext";
import {UserContext} from "../../../contexts/UserContext";
import {ChartUtils} from "../../datalogging/ChartUtils";
import {convertUnitToReadable} from "../../../utils/UnitDisplay";
import {TimestampedMeasurements} from "../../../Models/DataLogging";
import {MomentInput} from "moment";
/*
    DEV:
    Be careful with functions here. You want to keep as much of what you pass to the graph as static as possible.
    It's better to define a reused function with a weird set of parameters than to try to map the parameters at the last moment.
    ANY change to the information passed in to the graph will cause a noticeable freeze that will be tricky to detect.
    
 */
type functionTakingTimestampedMeasurement = (p: TimestampedMeasurements | undefined) => TimestampedMeasurements | undefined

interface GraphViewProps {
    setHoveredPoint: (p: TimestampedMeasurements | undefined | functionTakingTimestampedMeasurement) => void,
    setPressureRange: (value: [number, number]) => void,
    setTimeRange: (value: [Date, Date]) => void,
}
export interface GraphViewRef {
    resetGraph: () => void
    setRange: (date: [Date,Date], pressure: [number, number]) => void,
}
export const DefaultGraphViewRef: GraphViewRef = {
    resetGraph: () => {console.log("Reset Graph not set up!")},
    setRange: (date: [Date,Date], pressure: [number, number]) => {}
}
export const GraphView = forwardRef((props: GraphViewProps, ref ) => {
    const logContext = useContext(LogContext)
    const DataSource = logContext.loadedFileData ?? []
    const userContext = useContext(UserContext)
    const height = ChartUtils.CalculateGraphHeight()
    const colors = userContext.graphColors
    const dragToZoom = userContext.dragToZoom
    const pressure = userContext.graphUnit ?? userContext.pressureUnit
    const readablePressure = convertUnitToReadable(pressure)
    const chartRef = useRef<Chart>(null)
    const [dateFormat, setDateFormat] = useState<(a: {
        value: MomentInput
    }) => string>((e) => ChartUtils.GetDateTimeValueFormatter(userContext.xAxisFormat, DataSource[0]?.date, DataSource[DataSource.length - 1]?.date)(e?.value))
    const numberFormat = useCallback((e : {value: number | string | Date}) => {
        return Intl.NumberFormat(userContext.numberLocale).format(e.value as number)
    }, [userContext.numberLocale]) 
    useEffect(() => {
        let d = ChartUtils.GetDateTimeValueFormatter(userContext.xAxisFormat, DataSource[0]?.date, DataSource[DataSource.length - 1]?.date)
        setDateFormat(() => (e: { value: MomentInput }) => d(e?.value))
    }, [DataSource, userContext.xAxisFormat,])
    
    useImperativeHandle(ref, () => ({
        resetGraph: () => {
            chartRef.current?.instance.resetVisualRange()
            console.log("successful reset click")
        },
        setRange: (date: [Date,Date], pressure: [number, number]) => {
            chartRef.current?.instance.getArgumentAxis().visualRange(date)
            chartRef.current?.instance.getValueAxis().visualRange(pressure)
        }
    }))
    const onDone = useCallback((e : DoneEvent) => {
        let time = e.component.getValueAxis().visualRange()
        let date = e.component.getArgumentAxis().visualRange()
        props.setPressureRange([time.startValue as number, time.endValue as number])
        props.setTimeRange([date.startValue as Date, date.endValue as Date])
    },[])
    const onZoomEnd = useCallback((e: ZoomEndEvent) => {
        //This comparison was suggested by the dev-express team to prevent the use of an undocumented parameter (e.axis.isArgumentAxis). If they ask, the relevant ticket number is T1238491
        if (e.axis === e.component.getArgumentAxis())
            props.setTimeRange([e.rangeStart as Date, e.rangeEnd as Date])
        else
            props.setPressureRange([e.rangeStart as number, e.rangeEnd as number])
    }, [])
    const onHoverChange = useCallback((e: PointHoverChangedEvent) => {
        let hovered = e.target.data as TimestampedMeasurements | undefined
        if (!e.target.isHovered()) {
            props.setHoveredPoint(p => p?.date == hovered?.date ? undefined : p)
            return
        }
        props.setHoveredPoint(hovered)

    }, [])
    return useMemo(() => (
        <Chart
            ref={chartRef}
            dataSource={DataSource}
            id={'graphView'}
            height={height}
            onPointHoverChanged={onHoverChange}
            pointSelectionMode={"single"}
            onZoomEnd={onZoomEnd}
            onDone={onDone}
        >
            <ZoomAndPan
                argumentAxis={"both"}
                dragToZoom={dragToZoom}
                panKey={"ctrl"}
                valueAxis="both"
            />

            <Series
                argumentField="date"
                type="rangearea"
                rangeValue1Field={"min"}
                rangeValue2Field={"max"}
                color={colors[0]}
                showInLegend={false}
                hoverMode={"none"}
                selectionMode={"none"}
            >

            </Series>

            <Series
                name={logContext.log.sensors[0].name ?? ""}
                argumentField={"date"}
                type={"line"}
                valueField={"avg"}
                color={colors[0]}
            >
            </Series>

            <ValueAxis
                title={readablePressure}
                wholeRange={[null,null]}
            >
                <AxisLabel customizeText={numberFormat} />
            </ValueAxis>
            <ArgumentAxis>
                <AxisLabel customizeText={dateFormat} wordWrap={"none"}/>
            </ArgumentAxis>
        </Chart>

    ), [readablePressure, colors, DataSource, dateFormat, dragToZoom, height, logContext.log.sensors, onHoverChange, onZoomEnd, props.setHoveredPoint, props.setPressureRange, props.setTimeRange])
})