import {Grid, Header, Icon, Segment} from "semantic-ui-react";
import localeStore from "../language/LocaleStore";
import {DateUtils} from "@ametektci/ametek.stcappscommon";
import moment from "moment";
import DeleteCertificate from "./DeleteCertificate";
import {useContext} from "react";
import {CertificateContext} from "../contexts/CertificateContext";

interface CurrentCertificatesProps {
    loading: boolean
}
export function CurrentCertificates (props: CurrentCertificatesProps) {
    const certificateContext = useContext(CertificateContext)
    const certificates = certificateContext.certificates
    return (
        <div>
            <Header size='small' content={localeStore.Strings.userCertificates} dividing/>
            {certificates.length === 0 ? <Header size='tiny' textAlign='center' disabled content={localeStore.Strings.noCertificatesFound}/> :
                certificates.map(cert => (
                    <Segment key={cert.certificateId} >
                        <Grid verticalAlign='middle'>
                            <Grid.Column width={1}>
                                <Icon name='lock' color={DateUtils.HasPast(cert.expiresAt) ? 'red' : 'blue'}/>
                            </Grid.Column>
                            <Grid.Column width={5}>
                                <Header size='small' content={cert.name}/>
                            </Grid.Column>
                            <Grid.Column width={5}>
                                <Header size='small' content={moment(cert.expiresAt).format("MM/DD/YYYY")} subheader={localeStore.Strings.expiresAt}
                                        color={DateUtils.HasPast(cert.expiresAt) ? 'red' : undefined}/>
                            </Grid.Column>
                            <Grid.Column width={5} textAlign='right'>
                                <DeleteCertificate certificate={cert} disabled={props.loading} />
                            </Grid.Column>
                        </Grid>
                    </Segment>
                ))}
        </div>
    )
}