import {useContext, useState} from 'react';
import {Button, Modal, Form, InputOnChangeData, Message} from 'semantic-ui-react';
import localeStore from "../language/LocaleStore";
import {Log} from "@ametektci/ametek.stcappscommon";
import {LogContext} from "../contexts/LogContext";
import {GaugesContext} from "../contexts/GaugesContext";
export interface RenameSensorModalProps {
    sensorPosition: number
}
export default function RenameSensorModal (props: RenameSensorModalProps) {
    const logContext = useContext(LogContext)
    const demoMode = useContext(GaugesContext).demoMode
    const [visible,setVisible] = useState(false)
    const [sensorName, setSensorName] = useState("")
    const [error, setError] = useState<string | null>(null)
    const [canChange, setCanChange] = useState(false)
    const [loading, setLoading] = useState(false)
    const open = () => {
        setVisible(true)
        setError(null)
        setSensorName(logContext.log.sensors.find(s => s.sensorPosition === props.sensorPosition)!.name ?? "")
        document.getElementById("sensorNameInput")?.focus();
    }

    const close = () => setVisible(false)

    const handleChange = (e: any, { value }: InputOnChangeData) => {
        let newError = null;
        let newCanChange = true;
        // check for duplicate named sensors - dygraphs doesn't like it
        for (let pos = 1; pos <= 4; pos++) {
            // if the name matches
            if (logContext.log['sensor'+pos+'Name' as keyof Log] === value) {
                newCanChange = false;
                // don't show error message if it just an unchanged value
                if (pos !== props.sensorPosition)
                    newError = localeStore.Strings.sensorsMustHaveUniqueNames
            }
        }
        setSensorName(value)
        setCanChange(newCanChange)
        setError(newError)
    }

    const renameSensor = () => {
        if (sensorName === logContext.log['sensor'+props.sensorPosition+'Name' as keyof Log]) return;
        setLoading(true)

        logContext.renameSensor(props.sensorPosition, sensorName).then(() => {
            setVisible(false)
            setLoading(false)
        });
    };
        return (
            <Modal size='tiny' open={visible}
                   trigger={<Button icon='edit' size='mini' onClick={open} disabled={logContext.log.isDemo}/>} >
                <Modal.Header>{localeStore.Strings.renameSensor}</Modal.Header>
                <Modal.Content>
                    <Form onSubmit={renameSensor}>
                        <Form.Input placeholder={localeStore.Strings.sensorName} value={sensorName}
                                    label={localeStore.Strings.sensorName}
                                    name='sensorName' id='sensorNameInput'
                                    error={error}
                                    fluid onChange={handleChange} />
                    </Form>
                    <Message visible={demoMode} content={localeStore.Strings.demoModeFeatureNotAvailable} hidden={!demoMode} />
                </Modal.Content>
                <Modal.Actions>
                    <Button disabled={loading} onClick={close} content={localeStore.Strings.cancel}/>
                    <Button color={"blue"}
                            disabled={loading || !canChange || demoMode}
                            loading={loading} onClick={renameSensor} content={localeStore.Strings.confirm}/>
                </Modal.Actions>
            </Modal>
        )
}
