import {Dropdown, Header, Input, Label, Message, Popup, Segment} from "semantic-ui-react";
import localeStore from "../../language/LocaleStore";
import {useContext, useState} from "react";
import {PressureSensorConfigBase} from "../../Models/Config/PressureSensorConfigBase";
import {GaugeContext} from "../../contexts/GaugeContext";
import {LanguageContext} from "../../contexts/LanguageContext";
import {units} from "../../utils/UnitDisplay";
import {UserContext} from "../../contexts/UserContext";
import {PressureValue, Unit} from "../../utils/PressureValue";

const resolutionOptions = [
    {key: '5', text: '1.00000', value: 5},
    {key: '4', text: '1.0000', value: 4},
    {key: '3', text: '1.000', value: 3},
    {key: '2', text: '1.00', value: 2},
    {key: '1', text: '1.0', value: 1},
    {key: '0', text: '1', value: 0},
];

export interface GeneralSensorSettingsProps {
    sensor: PressureSensorConfigBase

    settingsChange(sensor: PressureSensorConfigBase, valid: boolean): void
}

export default function GeneralSensorSettings(props: GeneralSensorSettingsProps) {
    const gaugeContext = useContext(GaugeContext)
    const translate = useContext(LanguageContext)
    const userContext = useContext(UserContext)
    const disableControls = gaugeContext.lock.lockConfig || !gaugeContext.Gauge.connected
    const unit = userContext.pressureUnit.toLowerCase() != "user"? units.find(u => u.unit == userContext.pressureUnit.toUpperCase()) : units.find( u => u.unit == "PSI")
    const [zeroLimitError, setZeroLimitError] = useState<string | null>(null)
    const [filterTimeError, setFilterTimeError] = useState<string | null>(null)
    const [resolutionTooltipVisible, setResolutionTooltipVisible] = useState(false)
    const defaultUnitInfo = {resolution: 1, offset: "0", slope: "1"}
    const fromReadablePoint = (asAnyUnit: string | number) => {

        let point = (typeof asAnyUnit === "string") ? Number.parseFloat(asAnyUnit) : asAnyUnit
        let asPressureValue = new PressureValue(point, defaultUnitInfo, unit?.unit.toLowerCase() as Unit)
        return asPressureValue._value
    }
    const toReadablePoint = (asPsi: string | number) => {
        let point = (typeof asPsi == "string")? Number.parseFloat(asPsi) : asPsi
        let asPressureValue = new PressureValue(point, defaultUnitInfo, "psi")
        return +asPressureValue.getValue(unit?.unit.toLowerCase() as Unit,defaultUnitInfo, 5)
    }
    function onZeroLimitChange(newLimit: string) {
        let newConfig = Object.assign({}, props.sensor)

        let value = fromReadablePoint(newLimit);
        let valid = 0 <= value && value <= 1000;
        if (valid)
            setZeroLimitError(null)
        else
            setZeroLimitError(localeStore.Strings.zeroLimitValidation)
        newConfig.zeroLimit = value

        props.settingsChange(newConfig, valid && (filterTimeError == null));
    }

    function onFilterTimeChange(filterTime: string) {
        let value = Number(filterTime);
        // enforce entering the value with at most one digit of precision
        let decimalPrecision = value.toString().indexOf('.') // todo : support , number format
        let precision = decimalPrecision === -1 ? 0 : value.toString().length - decimalPrecision - 1;

        let valid = 0.1 <= value && value <= 5 && precision <= 1;

        if (valid)
            setFilterTimeError(null)
        else
            setFilterTimeError(localeStore.Strings.filterTimeValidation)

        props.settingsChange({...props.sensor, filterTime: value}, valid && (zeroLimitError == null));

    }

    function onResolutionChange(newResolution: string) {
        let newConfig = Object.assign({}, props.sensor)
        newConfig.resolution = Number(newResolution)
        props.settingsChange(newConfig, (zeroLimitError == null) && (filterTimeError == null))
    }

    return (
        <Segment>
            <Header content={localeStore.Strings.generalSettings}/>

            <Popup content={translate.getString("maxResolutionExplanation")}
                   on={"hover"}
                   open={resolutionTooltipVisible}
                   onOpen={() => setResolutionTooltipVisible(true)}
                   onClose={() => setResolutionTooltipVisible(false)}
                   position={"top center"}
                   trigger={
                       <div>
                           <Header content={localeStore.Strings.resolution} dividing size='small'/>
                           <Dropdown
                               selection fluid options={resolutionOptions} name='resolution'
                               onChange={(e, data) => onResolutionChange(data.value as string)}
                               value={props.sensor.resolution}
                               disabled={disableControls}
                           />
                       </div>
                   }/>


            <Header content={localeStore.Strings.zeroLimit} dividing size='small'/>
            <Input
                fluid label={unit?.display} labelPosition='right' type='number' name='zeroLimit'
                error={!!zeroLimitError}
                onChange={(e, {value}) => onZeroLimitChange(value)} 
                value={toReadablePoint(props.sensor.zeroLimit)}
                min={0}
                max={1000}
                disabled={disableControls}
            />
            <Message error content={zeroLimitError} hidden={!zeroLimitError}/>
            {props.sensor.filterTime != null ?
                <div>
                    <Header content={localeStore.Strings.filterTime} dividing size='small'/>
                    <Input
                        fluid type='number' name='filterTime' error={!!filterTimeError}
                        onChange={(e, {value}) => onFilterTimeChange(value)} value={props.sensor.filterTime}
                        disabled={disableControls}
                        min={0.1}
                        max={5}
                        step={0.1}
                    />
                    <Message error content={filterTimeError} hidden={!filterTimeError}/>
                </div>
                : null
            }
            <Header content={localeStore.Strings.overPressureCount} dividing size='small'/>
            <Popup content={localeStore.Strings.overPressureExplanation} trigger={<div>
                <Label content={`${props.sensor.overPressureCount} ${localeStore.Strings.counts}`}/>
            </div>}
            position={"bottom left"}
            />
            

        </Segment>
    )
}