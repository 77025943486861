import {GaugeBase} from "../GaugeBase";
import hpc50 from '../../images/gauges/hpc50/HPC50_400-400.png';
import {TemperatureUnit} from "../units";
import {Gauge} from "@ametektci/ametek.stcappscommon";
import {DLPurchaseValues} from "@ametektci/ametek.stcappscommon/src/DataStructures/Enums";
export class HPC50 implements GaugeBase {
    constructor(item: Gauge | GaugeBase) {
        this.organizationId = item.organizationId
        this.connected = item.connected
        this.dataLogEnabled = item.dataLogEnabled
        this.connectedDeviceAgentVersion = item.connectedDeviceAgentVersion
        this.gaugeId = item.gaugeId
        this.model = item.model
        this.serialNumber = item.serialNumber
        this.calibrationDate = item.calibrationDate
        this.calibrationDue = item.calibrationDue
        this.deviceAgentConnectionId = item.deviceAgentConnectionId
        this.firmwareVersion = item.firmwareVersion
        this.lastSeen = item.lastSeen
        this.runTags = item.runTags
        this.modelId = item.modelId
        this.dataLogPurchased = item.dataLogPurchased
        return this
    }
    organizationId: number
    locked?: boolean | undefined;
    supportsLogging?: boolean | undefined;

    connected: boolean;
    connectedDeviceAgentVersion: any;
    dataLogEnabled: boolean;
    gaugeId: number;
    serialNumber: string;
    model: { name: string, shortName: any, liveLoggingCapable: boolean };
    modelId: number
    singleSensor = false
    picture = () => {
        return hpc50
    }
    supportedTemps = () : Array<TemperatureUnit> => {
        return ["4C","60F","68F"]
    }
    calibrationDate: any;
    calibrationDue: any;
    deviceAgentConnectionId: any;
    firmwareVersion: any;
    lastSeen: any;
    runTags?: string;
    dataLogPurchased: DLPurchaseValues;
}