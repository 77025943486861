import {Message, MessageContent, MessageHeader} from "semantic-ui-react";
import {useContext} from "react";
import {LanguageContext} from "../contexts/LanguageContext";
import {LogsContext} from "../contexts/LogsContext";
import {GaugeContext} from "../contexts/GaugeContext";

export function LogWarning() {
    const translate = useContext(LanguageContext)
    const logsContext = useContext(LogsContext)
    const gaugeContext = useContext(GaugeContext)
    const gaugeLogs = logsContext.gaugeLogs[gaugeContext.Gauge.serialNumber] ?? []
    const totalCount = gaugeLogs.length
    if (totalCount < 50)
        return null //Not a lot of logs to clean up, so no reason to show the waring.
    const uploadedCount = gaugeLogs.filter(l => l.logId != 0 && l.logId != null).length
    return (<Message info >
        <MessageHeader content={translate.getString("logCountWarningHead")} />
        <MessageContent content={translate.getString("logCountWarningMessage").replace("##1", totalCount.toString()).replace("##2", uploadedCount.toString())} />
    </Message> )
}