import {Divider, Dropdown, Header, Segment} from "semantic-ui-react";
import localeStore from "../../language/LocaleStore";
import UnitSelector from "./UnitSelector";
import UnitSelection from "./UnitSelection";
import CustomUnitConfiguration from "./CustomUnitConfig";
import {CustomUnit} from "../../Models/Config/CustomUnit";
import {SensorUnits} from "../../Models/Config/SensorUnits";
import {ConvertTempToReadable} from "../../utils/UnitDisplay";
import {useContext} from "react";
import {GaugeContext} from "../../contexts/GaugeContext";
export interface UnitsSegmentProps {
    sensorUnits: SensorUnits
    onSensorUnitsChange(newUnits: SensorUnits) : void
}
export default function UnitsSegment(props: UnitsSegmentProps) {
    const gaugeContext = useContext(GaugeContext)
    const disableControls = gaugeContext.lock.lockConfig || !gaugeContext.Gauge.connected
    function onCustomUnitChange(unit: CustomUnit) {
        props.onSensorUnitsChange({...props.sensorUnits, userDefinedUnit: unit})
    }

    function onAvailableUnitsChange(availableUnits: Array<string>) {
        let newUnits = {...props.sensorUnits, currentUnits: availableUnits}
        props.onSensorUnitsChange(newUnits)
    }

    function onDefaultUnitChange(newDefault: string) {
        let newUnits = {...props.sensorUnits, defaultUnit: newDefault}
        props.onSensorUnitsChange(newUnits)
    }
    
    const onTemperatureUnitChange = (newTemp: string) => {
        let newUnits = {...props.sensorUnits, waterTemperature: newTemp}
        props.onSensorUnitsChange(newUnits)
    }
    
    let tempOptions = gaugeContext.Gauge.supportedTemps().map(v => {
        return {
            key:v,
            value: v,
            text: ConvertTempToReadable(v)
        }
    })

    return (
        <Segment>
        <Header content={localeStore.Strings.units} />
        { props.sensorUnits.defaultUnit?
            <UnitSelector availableUnits={props.sensorUnits.availableUnits} currentUnit={props.sensorUnits.defaultUnit} setNewCurrentUnit={(newDefault) => onDefaultUnitChange(newDefault) } label={"Default: "} />
            : undefined
        }
            {props.sensorUnits.waterTemperature?
            <div><Header content={localeStore.Strings.waterTemperatureUnit} dividing size='tiny'/> 
            <Dropdown selection 
                      options={tempOptions} name='userDefinedUnit.resolution'
                      onChange={(e, data) => onTemperatureUnitChange(data.value as string)} 
                      value={props.sensorUnits.waterTemperature}
                      disabled={disableControls}
            /></div> : undefined
            }
        <Header content={localeStore.Strings.enabledUnits} dividing size='small'/>
        <UnitSelection handleChange={(availableUnits) => onAvailableUnitsChange(availableUnits)} currentUnits={props.sensorUnits.currentUnits} availableUnits={props.sensorUnits.availableUnits} />
        <Divider />
        {props.sensorUnits.userDefinedUnit? <CustomUnitConfiguration customUnit={props.sensorUnits.userDefinedUnit} onChange={onCustomUnitChange} /> : undefined}


    </Segment>
    )
}