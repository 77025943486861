import {Card, Container, Header, Image} from "semantic-ui-react";
import localeStore from "../language/LocaleStore";
import featureGraphing from "../images/Feature Preview/featureGraphing.PNG";
import featureLogManagement from "../images/Feature Preview/featureLogManagement.PNG";
import featureOrganizationManagement from "../images/Feature Preview/featureManagementConsole.png";
import featurePdf from "../images/Feature Preview/featurePdf.PNG";
import featureDeviceConfiguration from "../images/Feature Preview/featureDeviceConfiguration.PNG";
//import featureOffline from '../images/Feature Preview/featureOffline.png';
export function FeaturesBox() {
    return (<div className='splashSecondBox' style={{
        backgroundColor: '#2e2f2f',
        //minHeight: '700px',
        padding: '70px 0',
        flexGrow: 1
    }}>
        <Container>
            <Header inverted content={localeStore.Strings.features} size='huge'/>

            <Card.Group itemsPerRow={3} stackable>

                <Card>
                    <Image src={featureGraphing} wrapped ui={false}/>
                    <Card.Content header={localeStore.Strings.graphing}
                                  description={localeStore.Strings.graphingDescription}/>
                </Card>

                <Card>
                    <Image src={featureLogManagement} wrapped ui={false}/>
                    <Card.Content header={localeStore.Strings.logManagement}
                                  description={localeStore.Strings.logManagementDescription}/>
                </Card>

                <Card>
                    <Image src={featureOrganizationManagement} wrapped ui={false}/>
                    <Card.Content header={localeStore.Strings.organizationManagement}
                                  description={localeStore.Strings.organizationManagementDescription}/>
                </Card>

                <Card>
                    <Image src={featurePdf} wrapped ui={false}/>
                    <Card.Content header={localeStore.Strings.pdfReporting}
                                  description={localeStore.Strings.pdfReportingDescription}/>
                </Card>

                <Card>
                    <Image src={featureDeviceConfiguration} wrapped ui={false}/>
                    <Card.Content header={localeStore.Strings.deviceConfiguration}
                                  description={localeStore.Strings.deviceConfigurationDescription}/>
                </Card>
                {/* Offline client is not yet ready. Hiding the feature preview until it is.
                                <Card>
                                    <Image src={featureOffline} wrapped ui={false} />
                                    <Card.Content header={localeStore.Strings.offlineAppName}
                                                  description={localeStore.Strings.offlineAppDescription}/>
                                </Card>
                                */}
            </Card.Group>

        </Container>
    </div>)
}