import {Divider, Header, Segment, Form} from "semantic-ui-react";
import localeStore from "../../language/LocaleStore";
import EditRunTags from "./EditRunTags";
import {LoggingConfig} from "../../Models/Config/LoggingConfig";
import {LoggingTypeControl} from "./LoggingTypeControl";
import {useContext, useState} from "react";
import {GaugeContext} from "../../contexts/GaugeContext";
import {LanguageContext} from "../../contexts/LanguageContext";

export interface DataLoggingConfigProps {
    config: LoggingConfig

    handleChange(newConfig: LoggingConfig): void;
}
type Interval = {
    key: number, reversed: boolean, value: number, max: number, text: string
}
export default function DataLoggingConfig(props: DataLoggingConfigProps) {
    const gaugeMaxSupportedTime = 86400000 //24 hours
    const translate = useContext(LanguageContext)
    const gaugeContext = useContext(GaugeContext)
    const disableControls = gaugeContext.lock.lockConfig || !gaugeContext.Gauge.connected
    //Logging interval is calculated from a dropdown and an entry field
    const loggingIntervalTimings : Array<Interval> = [
        {
            key: 0.1, reversed:true, value: 0.1, max:10, text: translate.getString("intervalReadingsPerSecond"), //needs special handling
        },
        {
            key: 1, reversed:false, value: 1, max:60, text: translate.getString("intervalSecondsPerReading")
        },
        {
            key: 60, reversed:false, value: 60, max:60, text: translate.getString("intervalMinutesPerReading")
        },
        {
            key: 3600, reversed:false, value: 3600, max:24, text: translate.getString("intervalHoursPerReading")
        }
    ]
    const adjustReadFrequency = (timeInMilis: number,interval: typeof loggingIntervalTimings[number]) =>
    {
        if (interval.reversed)
            return 1000/timeInMilis
        let inSeconds = timeInMilis/1000;
        return inSeconds / interval.value;
    }
    const UnAdjustReadFrequency = (timeInInterval: number, interval: Interval) => {
        let inMilis = 1000
        let maxForInterval = interval.max*interval.value*1000
        if (interval.reversed)
        {
            if (timeInInterval == 0)
                inMilis = 1000
            else 
                inMilis = inMilis / timeInInterval
        }
        else
        {
            if (timeInInterval < 1)
                timeInInterval = 1
            inMilis = timeInInterval * interval.value * inMilis
        }
            
        //XP3I will only accept numbers between 100 and 86400000. This spans from once a second to once every day.
        //Note: we are only supporting 1, 2 and 5x a second.
        let unadjusted = Math.max(100, Math.min(inMilis,gaugeMaxSupportedTime, maxForInterval))
        console.log("Unadjusted count: ",unadjusted,"max for interval", maxForInterval)
        return unadjusted
    }
    
    const readInMiliseconds = props.config.recordingInterval
    const findBestFitInterval = (readInMilis: number) : Interval =>
    {
        if (readInMilis < 1000)
        {
            //Only one is set up for really small readings
            return loggingIntervalTimings.find(tim => tim.key == 0.1)!
        }
        var allowed = loggingIntervalTimings.filter(lit => !lit.reversed)
        for (let interval of allowed)
        {
            let estimatedAmount = adjustReadFrequency(readInMilis, interval)
            if (estimatedAmount < interval.max)
                return interval
        }
        //Use the largest interval if none fit.
        return loggingIntervalTimings.find(lit => lit.key == 3600)!
    }
    
    const [currentInterval, setCurrentInterval] = useState(findBestFitInterval(readInMiliseconds)!)

    
    const AdjustedReadFrequency = adjustReadFrequency(readInMiliseconds, currentInterval)
    const updateReadingFrequency = (newFrequency: string) => {
        if (newFrequency == "")
            newFrequency = "0"
        let toNumber = Number.parseInt(newFrequency)
        if (Number.isNaN(toNumber)) {
            return
        }
        let unadjusted = UnAdjustReadFrequency(toNumber, currentInterval)
        props.handleChange({...props.config, recordingInterval: unadjusted})
    }
    const updateSelectedInterval = (newInterval: number) =>
    {
        let chosen = loggingIntervalTimings.find(lIT => lIT.value == newInterval)
        if (chosen && chosen.value != currentInterval.value)
        {
            setCurrentInterval(chosen)
            props.handleChange({...props.config, recordingInterval: UnAdjustReadFrequency(1, chosen)})
        }
            
    }
    const handleRunTagsChange = (runTags: string) => {
        let newConfig = Object.assign({}, props.config)
        newConfig.runTags = runTags
        props.handleChange(newConfig)
    }
    const onLoggingTypeChange = (logType: string) => {
        props.handleChange({...props.config, loggingType: logType})
    }

    return (
        <Segment>
            <Header content={"Logging Settings"}/>
            <Divider/>
            <Form>
                <Header content={localeStore.Strings.loggingInterval} dividing size='small'/>
                <Form.Group>
                    <Form.Dropdown
                        selection
                        options={loggingIntervalTimings}
                        value={currentInterval.value}
                        onChange={(e, {value}) => updateSelectedInterval(value as number)}
                        disabled={disableControls}
                    />
                    {
                        currentInterval.reversed? 
                            <Form.Dropdown
                                selection
                                placeholder={translate.getString("select")}
                                options={[
                                    {value: 1, text: 1},
                                    {value: 2, text: 2},
                                    {value: 5, text: 5}
                                ]}
                                value={AdjustedReadFrequency}
                                onChange={(e, {value}) => updateReadingFrequency(value as string)}
                                disabled={disableControls}
                                />
                            :
                            <Form.Input
                                type={"number"}
                                value={AdjustedReadFrequency}
                                onChange={(e, {value}) => updateReadingFrequency(value)}
                                disabled={disableControls}
                                max={currentInterval.max}
                                step={1}
                            />
                    }
                </Form.Group>
            </Form>
            <br/>
            <LoggingTypeControl onChange={onLoggingTypeChange} config={props.config}/>

            <Header content={localeStore.Strings.runTags} dividing size='small'/>
            <EditRunTags runTags={props.config.runTags}
                         handleChange={(e, data) => handleRunTagsChange(data.value)}/>
        </Segment>
    )
}