import localeStore from "../language/LocaleStore";
import {Form, InputOnChangeData} from "semantic-ui-react";
import {ChangeEvent} from "react";

export interface UploadableFile {
    fileName: string
    filePath: string
    fileContent: FileList | null
}

export interface SelectFileToUploadProps {
    acceptableFileTypes: string
    file: UploadableFile
    setUploadableFile: (file: UploadableFile) => void
}

export function SelectFileToUpload(props: SelectFileToUploadProps) {
    const setFileName = (newName: string) => {
        props.setUploadableFile({...props.file, fileName: newName})
    }
    const handleFileSelected = (e: ChangeEvent, {value}: InputOnChangeData) => {
        let elem = e.target as HTMLInputElement
        props.setUploadableFile({...props.file, filePath: value, fileContent: elem.files})
    }
    return (
        <div>
            <Form.Input
                label={localeStore.Strings.name}
                value={props.file.fileName}
                onChange={(e, data) => setFileName(data.value)}
            />
            <Form.Input
                type='file'
                label={localeStore.Strings.browseForFile.replace("***",   props.acceptableFileTypes)}
                accept={props.acceptableFileTypes}
                onChange={handleFileSelected}
            />
        </div>
    )
}