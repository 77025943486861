import {Grid, Menu} from "semantic-ui-react";
import ChassisConfig from "./ChassisConfig";
import {useState} from "react";
import ElectricalSensorConfig from "./ElectricalSensorConfig";
import PressureSensorConfig from "./PressureSensorConfig";
import {ConfigBase} from "../../Models/Config/ConfigBase";
import {SensorConfig} from "../../Models/Config/SensorConfig";
import localeStore from "../../language/LocaleStore";
import {PressureSensorConfigBase} from "../../Models/Config/PressureSensorConfigBase";
import {GaugeBase} from "../../Models/GaugeBase";

export interface MultiSensorLayoutProps {
    config: ConfigBase
    onConfigUpdate(newConfig: ConfigBase, valid: boolean) : void
    gauge: GaugeBase
}
export default function MultiSensorLayout(props: MultiSensorLayoutProps) {
    let [selectedSensor, setSelectedSensor] = useState(localeStore.Strings.chassis)
    const handleSensorSelection = (name: string) => setSelectedSensor(name)
    
    const onChasisConfigUpdate = (config: ConfigBase, valid: boolean) => {
        props.onConfigUpdate({...config}, valid)
    }
    const onSensorConfigUpdate = (sensorConfig: SensorConfig, valid: boolean) => {
        let tempconfig = Object.assign({}, props.config)
        tempconfig.sensors[selectedSensor] = sensorConfig
        props.onConfigUpdate(tempconfig, valid)
    }
    const ShowSensor = () => {
        if (props.config == null)
            return
        if (props.config.sensors == null)
            return
        switch (props.config?.sensors[selectedSensor]?.sensorType) {
            case "electric":
                return <ElectricalSensorConfig config={props.config?.sensors[selectedSensor]}
                                               handleChange={(sensorConfig, valid) => onSensorConfigUpdate(sensorConfig, valid)}
                                               sensorName={localeStore.Strings[selectedSensor as keyof typeof localeStore.Strings]} sensor={selectedSensor} sensorUnit={selectedSensor == "voltage" ? 'V' : "mA"} />
            case "pressure":
                return <PressureSensorConfig config={props.config?.sensors[selectedSensor] as PressureSensorConfigBase} sensor={selectedSensor}
                                             handleChange={(sensorConfig, valid) => onSensorConfigUpdate(sensorConfig,valid)}/>
            default:
                return undefined
        }
    }
    let sensors = props.config?.sensors ?? []
    return (
    <Grid>
        <Grid.Column computer={3} tablet={16}>
            <Menu fluid size='large' tabular={true} pointing={true}>
                <Menu.Item header name={localeStore.Strings.deviceConfiguration}/>

                <Menu.Item onClick={() => handleSensorSelection(localeStore.Strings.chassis)} name={localeStore.Strings.chassis}
                           active={selectedSensor === localeStore.Strings.chassis} />
                {
                    Object.keys(sensors).map((key) => {
                        return <Menu.Item content={key} key={key} active={key == selectedSensor} onClick={() => handleSensorSelection(key)}/>
                    })

                }
            </Menu>
        </Grid.Column>
        <Grid.Column computer={13} tablet={16}>
            {selectedSensor === localeStore.Strings.chassis
                ? <ChassisConfig gauge={props.gauge} config={props.config} handleChange={onChasisConfigUpdate} /> : ShowSensor()}
        </Grid.Column>
    </Grid>
    )
}