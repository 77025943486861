import {useContext, useState} from 'react';
import {Button, Grid, Header, Modal, Icon, Container, Message, Divider, Popup} from 'semantic-ui-react';
import localeStore from "../language/LocaleStore";
import {updatesStore, Products, messageQueue} from '@ametektci/ametek.stcappscommon'
import {GaugeContext} from "../contexts/GaugeContext";
import {LanguageContext} from "../contexts/LanguageContext";
import {UpdatesContext} from "../contexts/UpdatesContext";

export default function UpgradeFirmware() {
    const updates = useContext(UpdatesContext)
    const gaugeContext = useContext(GaugeContext)
    const gauge = gaugeContext.Gauge
    const translate = useContext(LanguageContext)
    const [visible, setVisible] = useState(false)
    const latestFirmware = updates.latestUpdate(gauge.model.shortName, {Major: 0, Minor: 0, Build: 0, Patch: 0})
    const open = () => setVisible(true)
    const close = () => setVisible(false)
    const onXP3iUpgrade = () => {
        setVisible(false)

        //changes the version of the gauge while firmware update finishes
        //this is seen on the gauge details
        gauge.firmwareVersion = localeStore.Strings.firmwareUpgradeInProgress
        if (!latestFirmware) {
            messageQueue.sendError(translate.getString("couldNotFindFirmware"))
            return
        }
        gaugeContext.updateFirmware(latestFirmware)
    }
    if (latestFirmware == undefined)
        return (
            <Popup
                trigger={
                    <div>
                        <Button
                            disabled={true}
                            content={localeStore.Strings.firmwareUpgrade}
                            onClick={open}
                        />
                    </div>
                }
                content={translate.getString("couldNotFindFirmware")}
                on={"hover"}
                position={"bottom center"}
            />
        )
    if (!gauge.connected)
        return (<Popup
            trigger={
                <div>
                    <Button
                        disabled={true}
                        content={localeStore.Strings.firmwareUpgrade}
                        onClick={open}
                    />
                </div>
            }
            content={translate.getString("deviceDisconnected")}
            on={"hover"}
            position={"bottom center"}
        />)
    if (gauge.firmwareVersion == localeStore.Strings.firmwareUpgradeInProgress)
        return (
            <Popup
                trigger={
                    <Button
                        disabled={true}
                        content={localeStore.Strings.firmwareUpgrade}
                        loading={true}
                    />
                }
                content={localeStore.Strings.firmwareUpgradeInProgress}
                on={"hover"}
                position={"bottom center"}
            />
        )
    if (!gaugeContext.updateAvailable)
        return (
            <Popup
                trigger={
                    <div>
                        <Button
                            disabled={true}
                            content={localeStore.Strings.firmwareUpgrade}
                        />
                    </div>
                }
                content={translate.getString("firmwareUpToDate")}
                on={"hover"}
                position={"bottom center"}
            />
        )
    return (
        <Modal
            size='tiny'
            open={visible}
            trigger={
                <Button
                    content={localeStore.Strings.firmwareUpgrade} onClick={open} color={"blue"}
                />
            }>
            <Modal.Header content={localeStore.Strings.firmwareUpgrade}/>
            <Modal.Content>
                <Modal.Description>

                    <Grid columns={3}>
                        <Grid.Column>
                            <Header content={gauge.firmwareVersion.split(".", 4).filter((f, idx) => idx < 3).join(".")}
                                    subheader={localeStore.Strings.currentFirmware} color='yellow'/>
                        </Grid.Column>
                        <Grid.Column>
                            <Container textAlign='center'>
                                <Icon name='arrow right' size='big'/>
                            </Container>
                        </Grid.Column>
                        <Grid.Column>
                            {/*FirmwareUpdateAvailable returns FALSE if latest firmware doesn't exist. This protects this line from being a risk*/}
                            <Header content={latestFirmware!.majorVersion+"."+latestFirmware!.minorVersion+"."+latestFirmware!.patchVersion}
                                    subheader={localeStore.Strings.latestFirmware} color='green'/>
                        </Grid.Column>
                    </Grid>

                    <Divider/>

                    {gauge.model.shortName === "XP3I"
                        ? <Message content={localeStore.Strings.firmwareUpgradeThroughDeviceAgentInstructions}/>
                        : <Message content={localeStore.Strings.firmwareUpgradeInstructions}/>
                    }

                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                {gauge.model.shortName === "XP3I" ?
                    <Button content={localeStore.Strings.firmwareUpgrade} color='blue'
                            disabled={!gaugeContext.updatingFirmware && !gauge.connected}
                            icon='external square alternate'
                            onClick={() => onXP3iUpgrade()}/> :
                    <Button content={localeStore.Strings.downloadCrystalControl} color='green' icon='download'
                            onClick={() => updatesStore.downloadUpdateFile(Products.CrystalControl)}/>
                }
                <Button onClick={close} content={localeStore.Strings.close}/>
            </Modal.Actions>
        </Modal>
    )
}