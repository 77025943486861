import {TemperatureUnit} from "../Models/units";
import localeStore from "../language/LocaleStore";

export type UnitDisplay = { unit: string, display: string }

export const units: UnitDisplay[] = [
    {unit: 'MPA', display: "MPa"},
    {unit: 'KPA', display: "kPa"},
    {unit: 'BAR', display: "bar"},
    {unit: 'MBAR', display: "mbar"},
    {unit: 'PSI', display: "psi"},
    {unit: 'INH2O', display: "inH₂O"},
    {unit: 'INH2O4C', display: "inH₂O 4°C"},
    {unit: 'INH2O60F', display: "inH₂O 60°F"},
    {unit: 'INH2O68F', display: "inH₂O 68°F"},
    {unit: 'MMH2O', display: "mmH₂O"},
    {unit: 'MMH2O4C', display: "mmH₂O 4°C"},
    {unit: 'MMH2O15.6C', display: "mmH₂O 15.6c"},
    {unit: 'MMH2O20C', display: "mmH₂O 20°C"},
    {unit: 'MMHG', display: "mmHg"},
    {unit: 'INHG', display: "inHg"},
    {unit: 'KGCM2', display: "kg/cm²"},
    {unit: 'USER', display: localeStore.Strings.user},
]
export const convertUnitToReadable = (unit: string) => {
    let [unitType, temp] = unit.split("-")
    unitType = units.find(u => u.unit == unitType.toUpperCase())?.display ?? unitType
    if (temp)
    {
        temp = ConvertTempToReadable(temp)
        return (<p>{unitType} <br/> {temp}</p>)
    }
    return unitType
}
export type TemperatureDisplay = { unit: TemperatureUnit, display: string }

export function ConvertTempToReadable (temp: string) : string
{
    let tempFinder = /([0-9]+)([CFK])/
    let tempMatch = temp?.match(tempFinder)
    if (tempMatch?.length != 3)
        return "INVALID TEMPERATURE"

    if(tempMatch.input === "4C"){
        return `${tempMatch[1]}°${tempMatch[2]} (39.2°F)`
    }
    else if(tempMatch.input === "60F"){
        return `15.6°C (${tempMatch[1]}°${tempMatch[2]})`
    }
    else if(tempMatch.input === "68F"){
        return `20°C (${tempMatch[1]}°${tempMatch[2]})`

    }

    return `${tempMatch[1]}°${tempMatch[2]}`
}