import {Button, Dropdown, Input, Modal, ModalActions, Table} from "semantic-ui-react";
import {useContext, useEffect, useState} from "react";
import {LanguageContext} from "../../contexts/LanguageContext";
import {units} from "../../utils/UnitDisplay";
import type {CustomUnitWizardProps} from "./CustomUnitConfig";
import {resolutionOptions} from "../../utils/resolutionOptions";
import {UserContext} from "../../contexts/UserContext";
import {PressureValue, Unit} from "../../utils/PressureValue";

export function CustomUnitBestFit(props: CustomUnitWizardProps) {
    const translate = useContext(LanguageContext)
    const userContext = useContext(UserContext)
    const [precision, setPrecision] = useState(props.currentUnit.resolution)
    const [points, setPoints] = useState<Array<point>>([{userReading: "0", reading: "0"}, {userReading: "1", reading: "1"}])
    const unit = userContext.pressureUnit.toLowerCase() != "user"? units.find(u => u.unit == userContext.pressureUnit.toUpperCase()) : units.find( u => u.unit == "PSI")
    const defaultUnitInfo = {resolution: 1, offset: "0", slope: "1"}
    useEffect(() => {
        setPrecision(props.currentUnit.resolution)
    }, [props.currentUnit])
    const sum = (p1: number, p2: number) => p1 + p2
    const fromReadablePoint = (asAnyUnit: string | number) => {

        let point = (typeof asAnyUnit === "string") ? Number.parseFloat(asAnyUnit) : asAnyUnit
        let asPressureValue = new PressureValue(point, defaultUnitInfo, unit?.unit.toLowerCase() as Unit)
        return asPressureValue._value
    }
    const calculateLine = () => {
        let validPoints = points.filter(p => !isNaN(parseFloat(p.reading)) && !isNaN(parseFloat(p.userReading)))
        //If we have no points to work with, ignore everything and just offer a line.
        if (validPoints.length == 0)
            return [1, 0]
        //If there is only one point, we need only the slope.
        if (validPoints.length == 1) {
            let target = parseFloat(validPoints[0].userReading)
            let source = fromReadablePoint(validPoints[0].reading)
            if (source == 0)
                return [1, target]
            if (target == 0)
                return [0, 0]
            return [target / source, 0]
        }
        //Least squares regression.
        //I tried to make this easy to read rather than efficient to run.
        let sumy = validPoints.map(p => (+p.userReading)).reduce(sum)
        let sumx = validPoints.map(p => fromReadablePoint(p.reading)).reduce(sum)
        let sumxy = validPoints.map(p => (+p.userReading) * fromReadablePoint(p.reading)).reduce(sum)
        let pointCount = validPoints.length
        let sumxsquare = validPoints.map(p => fromReadablePoint(p.reading) * fromReadablePoint(p.reading)).reduce(sum)

        let slope = ((pointCount * sumxy) - (sumx * sumy)) / ((pointCount * sumxsquare) - (sumx * sumx))
        let offset = (sumy - (slope * sumx)) / pointCount
        return [+(slope.toFixed(5)), +(offset.toFixed(5))]
    }
    const [bestFactor, bestOffset] = calculateLine()
    let acceptable = !(Number.isNaN(bestFactor) || Number.isNaN(bestOffset) || bestFactor == 0)
    const getValueAtPoint = (p: point) => {
        let result = NaN
        let deviation = NaN
        if (!isNaN(Number.parseFloat(p.reading))) {
            result = +(fromReadablePoint(p.reading) * bestFactor + bestOffset)
            if (!isNaN(Number.parseFloat(p.userReading)))
                deviation = +(result - (+p.userReading)).toFixed(precision)
        }
        return [result, deviation]
    }
    const addRow = () => {
        setPoints(currentPoints =>
            currentPoints.concat([{reading: '0', userReading: '0'}])
        )
    }
    const removeRow = (idx: number) => {
        setPoints(currentPoints => currentPoints.filter((p, indx) => idx != indx))
    }
    const updateReading = (idx: number, newReading: string) => {
        setPoints(ps => ps.map((p, indx) =>
            indx != idx ? p : {...p, reading: newReading}
        ))
    }
    const updateTarget = (idx: number, newTarget: string) => {
        setPoints(ps => ps.map((p, indx) =>
            indx != idx ? p : {...p, userReading: newTarget}
        ))
    }
    const setUnit = () => {
        props.setUnit({
            resolution: precision,
            slope: bestFactor.toString(),
            offset: bestOffset.toString(),
        })
        props.onclose()
    }
    
    return <Modal open={props.open} onClose={() => props.onclose()}>
        <Modal.Header>
            {translate.getString("linearFitModalHeader")}
        </Modal.Header>
        <Modal.Content>
            <Table unstackable collapsing>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>

                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            {translate.getString("factor")}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            {translate.getString("offset")}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            {translate.getString("resolution")}
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            {translate.getString("newUnit")}
                        </Table.Cell>
                        <Table.Cell>
                            {bestFactor}
                        </Table.Cell>
                        <Table.Cell>
                            {bestOffset}
                        </Table.Cell>
                        <Table.Cell>
                            <Dropdown
                                selection
                                options={resolutionOptions}
                                name='userDefinedUnit.resolution'
                                onChange={(e, {value}) => setPrecision(value as number)}
                                value={precision}
                            />
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
            <br/>
            <Table compact>
                <Table.Header>
                    <Table.HeaderCell>{unit?.display}</Table.HeaderCell>
                    <Table.HeaderCell>{translate.getString("customUserUnitDesired")}</Table.HeaderCell>
                    <Table.HeaderCell>{translate.getString("customUserUnitCalculated")}</Table.HeaderCell>
                    <Table.HeaderCell>{translate.getString("customUserUnitDeviation")}</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                    {points.map((p, idx) => {
                        let [result, deviation] = getValueAtPoint(p)
                        return (<Table.Row key={idx}>
                            <Table.Cell>
                                <Input
                                    onChange={(e, {value}) => updateReading(idx, value)}
                                    value={p.reading}
                                    error={isNaN(parseFloat(p.reading))}
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <Input
                                    onChange={(e, {value}) => updateTarget(idx, value)}
                                    value={p.userReading}
                                    error={isNaN(parseFloat(p.userReading))}
                                />
                            </Table.Cell>
                            <Table.Cell>{result.toFixed(precision)}</Table.Cell>
                            <Table.Cell>{deviation.toFixed(precision)}</Table.Cell>
                            <Table.Cell><Button content={"-"} color={"red"} onClick={() => removeRow(idx)}/>
                            </Table.Cell>
                        </Table.Row>)
                    })}
                </Table.Body>
            </Table>
            <Button content={"+"} color={"green"} onClick={() => addRow()}/>

        </Modal.Content>
        <ModalActions>
            <Button content={translate.getString("cancel")} color={"red"} onClick={() => props.onclose()}/> <Button content={translate.getString("accept")} color={"blue"} disabled={!acceptable} onClick={() => setUnit()} />
        </ModalActions>
    </Modal>
}

interface point {
    reading: string
    userReading: string
}