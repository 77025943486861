import {useContext, useState} from 'react';
import {Button, Modal, Form} from 'semantic-ui-react';
import localeStore from "../language/LocaleStore";
import {LogContext} from "../contexts/LogContext";

export default function RenameLog() {
    const logContext = useContext(LogContext)
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState(logContext.log.name ?? "")

    const open = () => {
        setVisible(true)
    };

    const close = () => {
        if ( ! loading) {
            setVisible(false)
        }
    };

    const handleChange = (e : any, {value} : {value: string}) => {
        // limit the input so that its not too long for the UI, database is set to cut off at 128 chars
        if (value.length < 40)
            setName(value)
    };

    const renameLog = () => {
        setLoading(true)
        logContext.renameLog(name).then(() => {
            setVisible(false)
            setLoading(false)
        });
    };
    if (logContext.log.logId != 0 && logContext.log.logId != null)

        return (
            <Modal size='tiny' open={visible}
                   trigger={<Button circular icon='edit' size='mini' onClick={open}/>}>
                <Modal.Header>{localeStore.Strings.nameLog}</Modal.Header>
                <Modal.Content>
                    <Form onSubmit={renameLog}>
                        <Form.Input placeholder={localeStore.Strings.pressureTest} value={name}
                                    label={'Log Name'}
                                    fluid onChange={handleChange} />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button disabled={loading} onClick={close} content={localeStore.Strings.cancel}/>
                    <Button color='blue' disabled={loading || name === logContext.log.name}
                            loading={loading} onClick={renameLog} content={localeStore.Strings.confirm}/>
                </Modal.Actions>
            </Modal>
        )
    return (<></>)
}