import {Button, Header, Icon, Label, Menu, Modal} from "semantic-ui-react";
import {useContext, useEffect, useState} from "react";
import {LanguageContext, Translatable} from "../contexts/LanguageContext";
import {AppVersion} from "../utils/AppVersion";
import {APIContext} from "../contexts/APIContext";
import deviceHubConnection from "../signalr/DeviceHubConnection";
import {HubConnectionState} from "@microsoft/signalr";
import {messageQueue, ManagementConsoleAPIContext, EnvironmentConfigurationContext } from "@ametektci/ametek.stcappscommon";

export function SiteInfo() {
    const env = useContext(EnvironmentConfigurationContext)
    const translate = useContext(LanguageContext)
    const mgmtApi = useContext(ManagementConsoleAPIContext)
    const api = useContext(APIContext)
    const [open, setOpen] = useState(false)
    const [connectionStatus, setConnectionStatus] = useState(deviceHubConnection.connection?.state ?? HubConnectionState.Disconnected)
    useEffect(() => {
        deviceHubConnection.on("ConnectionStatusChange", forceRefreshConnection)
        forceRefreshConnection()
    }, [deviceHubConnection])
    
    //React was not picking up on Signal R's state changes. This is a workaround.
    const forceRefreshConnection = () => {
        setConnectionStatus(deviceHubConnection.connection?.state ?? HubConnectionState.Disconnected)
    }
    const entries = [
        {label: translate.getString("crystalControlLive"), value: translate.getString("sigR_"+connectionStatus as Translatable)},
        {label: translate.getString("siteVersion"), value: AppVersion},
        {label: translate.getString("apiVersion"), value: api.ccwVersion},
        {label: translate.getString("managementConsoleVersion"), value: mgmtApi.version}
    ]
    const copySiteInformation = () => {
        navigator.clipboard.writeText(
            entries.map(e => e.label + ": " + e.value).join("\n")
        ).then(() => messageQueue.sendSuccess(translate.getString("successfullyCopied")))
            .catch(() => messageQueue.sendError(translate.getString("unableToCopy")))
    }
    return <Modal open={open} size={"small"} trigger={
        <Menu.Item onClick={() => setOpen(true)}>
            {translate.getString("supportButton")}
        </Menu.Item>
    }  >
        <Modal.Header>
            <Header>{translate.getString("connectionInformation")}</Header>
        </Modal.Header>
        <Modal.Content>
            <Header dividing>
                {translate.getString("documentation")}
            </Header>
            <a href={env.env['documentationUrl']} target={"_blank"}>{translate.getString("documentationLink")}</a>
            <Header dividing>
                {translate.getString("contactUs")}
            </Header>
            <a href={"https://www.ametekcalibration.com/contact/contactametektci"}
               target={"_blank"}>{translate.getString("contactAmetekLink")}</a>

            <Header>
                {translate.getString("siteInformation")} &nbsp;
                <Button style={{"box-shadow": "none", "padding-right": ".25em"}} basic onClick={copySiteInformation} icon={<Icon name={"copy outline"} size={"mini"} />} />
            </Header>
            {entries.map(e =>
                <div key={e.label}>
                    <Label>{e.label}:</Label>&nbsp;
                    <span>{e.value}</span>
                </div>
            )}
        </Modal.Content>
        <Modal.Actions>
            <Button color={"red"} onClick={() => setOpen(false)}>{translate.getString("close")}</Button>
        </Modal.Actions>
    </Modal>
}