import {useContext, useEffect, useState} from 'react';
import {Image, Menu, Container, Dropdown, Icon, Label} from 'semantic-ui-react';
import {AuthContext, Images, updatesStore, Products,EnvironmentConfigurationContext} from "@ametektci/ametek.stcappscommon";
import DemoModeStatus from "./DemoModeStatus";
import localeStore from "../language/LocaleStore";
import LanguageSelection from "./LanguageSelection";
import WelcomeTutorial from "../help/WelcomeTutorial";
import {UserContext} from "../contexts/UserContext";
import {SiteInfo} from "./SiteInfo";
import deviceHubConnection from "../signalr/DeviceHubConnection";
import {HubConnectionState} from "@microsoft/signalr";
import {useNavigate} from "react-router-dom";

export default function AppHeader(){
    const env = useContext(EnvironmentConfigurationContext)
    const auth = useContext(AuthContext)
    const userContext = useContext(UserContext)
    const navigate = useNavigate()
    const name = userContext.user?.name ?? auth.getName() ??  ""
    const accessLevel = userContext.accessLevel
    const [connectionStatus, setConnectionStatus] = useState(deviceHubConnection.connection?.state ?? HubConnectionState.Disconnected)
    useEffect(() => {
        deviceHubConnection.on("ConnectionStatusChange", forceRefreshConnection)
        forceRefreshConnection()
    }, [deviceHubConnection])
    //React was not picking up on Signal R's state changes. This is a workaround.
    const forceRefreshConnection = () => {
        setConnectionStatus(deviceHubConnection.connection?.state ?? HubConnectionState.Disconnected)
    }
    const mapStatusToColor = () => {
        if (connectionStatus === "Connected")
            return 'green';
        if (connectionStatus == "Disconnected" || connectionStatus == undefined)
            return 'red';
        return 'yellow';
    };
        return (
            <Menu inverted size='large' attached={true}>
                <Container>
                    <Menu.Item onClick={() => navigate("/")}>
                        <Image src={Images.ccwLogoWhite} size='large' />
                    </Menu.Item>

                    <Menu.Menu position='right'>

                        { accessLevel === 'unauthorized' ?
                            <Menu.Item position='right' className='noBorder'>
                                <div>
                                    <Label content={localeStore.Strings.freeTierAccess} size='large'
                                           color='orange' basic className='transparentBackground'/>
                                </div>
                            </Menu.Item>
                            : null
                        }

                        <Menu.Item position='right' className='noBorder'>
                            <DemoModeStatus />
                        </Menu.Item>

                        <Dropdown item text={name}>
                            <Dropdown.Menu>
                                
                                <Dropdown.Item onClick={() => updatesStore.downloadUpdateFile(Products.DeviceAgent)}>
                                    <Icon name='download' color='red'/>
                                    {localeStore.Strings.downloadDeviceAgent}
                                </Dropdown.Item>
                                <Dropdown.Item href={env.env['managementConsoleUrl'] + "/signIn"} target={"_blank"}>
                                    <Icon name='user' color='red'/>
                                    {localeStore.Strings.organizationManagement}
                                </Dropdown.Item>
                                <Dropdown.Item onClick={async () => {
                                    let signOut = await auth.signOut()
                                    if (signOut)
                                        window.location.href = signOut
                                }}>
                                    <Icon name='sign-out' color='red'/>
                                    {localeStore.Strings.signOut}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        <LanguageSelection />
                        <WelcomeTutorial />
                            <SiteInfo/>
                        <Menu.Item>
                            <Icon name={"wifi"} color={mapStatusToColor()}/>
                        </Menu.Item>
                    </Menu.Menu>
                </Container>
            </Menu>
        )
}
