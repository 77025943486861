import {useContext, useEffect, useState} from 'react';
import {
    Container,
    Header,
    Icon,
    Pagination,
} from 'semantic-ui-react';
import {DateUtils, Log} from '@ametektci/ametek.stcappscommon';
import LogControls from "./LogControls";
import LogListItem from "./LogListItem";
import localeStore from "../language/LocaleStore";
import {GaugeContext} from "../contexts/GaugeContext";
import {LogContextWrapper} from "../contexts/LogContext";
import {LogsContext} from "../contexts/LogsContext";
import {UploadLogFromFile} from "../logs/LogList/UploadLogFromFile";
import {MassLogManagement} from "../logs/LogList/MassLogManagement";
import {LogWarning} from "../logs/LogWarning";

const DefaultNumberOfLogsToDisplay = 5;
export default function LogList() {
    const gaugeContext = useContext(GaugeContext)
    const logsContext = useContext(LogsContext)
    const [numberOfLogsToDisplay, setNumberOfLogsToDisplay] = useState(DefaultNumberOfLogsToDisplay)
    const [filter, setFilter] = useState('all')
    const [sorting, setSorting] = useState('recordTime')
    const [filterRunTags, setFilterRunTags] = useState<Array<string>>([])
    const [activePage, setActivePage] = useState(1)
    useEffect(() => {
        setActivePage(1)
    }, [filter, filterRunTags])
    const serial = gaugeContext.Gauge.serialNumber
    let chosenLogs: Log[]
    switch (filter) {
        case 'archived':
            chosenLogs = logsContext.archivedLogs[serial]
            if (chosenLogs == null)
                logsContext.loadArchived(serial)
            break;
        case 'onDevice':
            chosenLogs = logsContext.gaugeLogs[serial]
            break
        case 'uploaded':
            chosenLogs = logsContext.uploadedLogs[serial]
            if (chosenLogs == null)
                logsContext.loadGauge(serial)
            break;
        default:
            chosenLogs = (logsContext.gaugeLogs[serial]?.filter(l => l.logId == 0 || l.logId == null) ?? []).concat(logsContext.uploadedLogs[serial] ?? [])
            if (logsContext.uploadedLogs[serial] == null)
                logsContext.loadGauge(serial)
            break;
    }
    if (chosenLogs == null)
        chosenLogs = [] //Don't risk broken data
    if (filterRunTags.length > 0) {
        // show only the logs that have at least one selected run tag
        chosenLogs = chosenLogs.filter(l => !!l.runTags && l.runTags.split(',').some(tag => filterRunTags.includes(tag)))
    }
    const invert = (a: Log, b: Log, funct: (a: Log, b: Log) => number) => {
        return -funct(a, b)
    }
    const findActiveLogs = (activePage = 1) => {
        let logs = chosenLogs

        // sort by either name or timestamp
        switch (sorting) {
            case 'recordTime':
                logs = logs.sort(DateUtils.SortLogsByTimestamps);
                break
            case 'oldLogs':
                logs = logs.sort((a, b) => invert(a, b, DateUtils.SortLogsByTimestamps))
                break
            case 'name a-z':
                logs = logs.sort(DateUtils.SortLogsByName)
                break
            case 'name z-a':
                logs = logs.sort((a, b) => invert(a, b, DateUtils.SortLogsByName))
                break
        }
        if (numberOfLogsToDisplay > logs.length -1)
            return logs;
        
        return logs.slice((activePage - 1) * numberOfLogsToDisplay, (activePage) * numberOfLogsToDisplay)
    };
    
    const displayedLogs = findActiveLogs(activePage)
    const pages = Math.max(Math.ceil(chosenLogs.length / numberOfLogsToDisplay), 1)
    useEffect(() => {
        if (pages == 0)
            return
        if (activePage > pages)
            setActivePage(pages)
    }, [pages])

    const changeDisplayedLogs = (newNumberOfLogsToDisplay: number) => {
        setNumberOfLogsToDisplay(newNumberOfLogsToDisplay)
        let firstItem = ((activePage - 1) * numberOfLogsToDisplay) + 1
        let pageFirstItemIsOn = Math.ceil(firstItem / newNumberOfLogsToDisplay)
        console.log(firstItem, pageFirstItemIsOn)
        setActivePage(pageFirstItemIsOn)
    }
    const filterDisplayedLogs = (newFilter: string) => {
        setFilter(newFilter)
    }
    const changeRunTagFilter = (newFilterRunTags: Array<string>) => {
        setFilterRunTags(newFilterRunTags)
    }
    const sortLogs = (newSorting: string) => {
        setSorting(newSorting)
    }
    return (
        <Container>
            <Header textAlign={"center"} size={"large"}>
                <Icon name='area chart' color='red'/>
                {localeStore.Strings.logs}
            </Header>
            <LogWarning/>
            <br/>
            <LogControls changeDisplayedLogs={changeDisplayedLogs}
                         filterDisplayedLogs={filterDisplayedLogs}
                         filter={filter}
                         changeRunTagFilter={changeRunTagFilter}
                         sortLogs={sortLogs}
                         sortBy={sorting}
                         numberOfLogsToDisplay={numberOfLogsToDisplay}
                         gauge={gaugeContext.Gauge!}/>
            <br/>
            <MassLogManagement/>
            <UploadLogFromFile/>
            {displayedLogs.map(log => (
                <LogContextWrapper log={log} key={log.logId + log.filename}>
                    <LogListItem log={log} gauge={gaugeContext.Gauge!}/>
                </LogContextWrapper>
            ))}

            {displayedLogs.length === 0 ?
                    <Header size='medium' textAlign='center' disabled content={logsContext.uploadedLogs[serial] == undefined ? localeStore.Strings.loading :localeStore.Strings.noLogsFound}/>
                : null}

            <Container textAlign='center'>
                {pages <= 1 ? null :
                    <Pagination totalPages={pages}
                                onPageChange={(event, data) => setActivePage(data.activePage as number)}
                                activePage={activePage}
                                firstItem={null} lastItem={null}
                                prevItem={pages > 5 ? undefined : null} nextItem={pages > 5 ? undefined : null}/>
                }
            </Container>
        </Container>
    )
}
