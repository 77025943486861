import {Button, Container, Grid, Segment} from "semantic-ui-react";
import {useContext} from "react";
import {AuthContext} from "@ametektci/ametek.stcappscommon";
import {LanguageContext} from "../contexts/LanguageContext";

export function Notice() {
    const translate = useContext(LanguageContext)
    const auth = useContext(AuthContext)
    const signIn = async () => {
        let link = await auth.authorize()
        if (link)
            window.location.href = link
    }
    const signOut = async () => {
        let link = await auth.signOut()
        if (link)
            window.location.href = link
    }
    if (auth.showSignInExpiring)
        return (<Segment attached={true}>
            <Container>
                <Grid>
                    <Grid.Column width={4}>
                    </Grid.Column>
                    <Grid.Column width={12}>
                        <div style={{display: "flex", flexDirection: "row",}}>
                            <div style={{flexGrow: 3, margin: "auto"}}>
                                {translate.getString("endOfSignInSessionWarning")}
                            </div>
                            <div style={{textAlign: "right"}}>
                                <Button onClick={signIn}>{translate.getString("renewSessionLabel")}</Button>
                                <Button onClick={signOut}>{translate.getString("signOut")}</Button>
                            </div>
                        </div>
                    </Grid.Column>
                </Grid>
            </Container>
        </Segment>)
    return <div/>
}