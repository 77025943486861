import {Log, DateUtils} from '@ametektci/ametek.stcappscommon';
import Papa, {ParseResult} from 'papaparse';
export class DataConversions {
    private static logTypes = [
        {label:"Actual", type:"Actual"},
        {label: "Average",type: "Average"},
        {label: "Average with Peaks", type: "AvgPeaks"},
        {label: "AvgPeaks", type: "AvgPeaks"},
        {label: "Actual-ULP", type:"ULP" },
        {label: "On Demand", type: "OnDemand"},
    ]
    static async ExtractLogs(csvFile: File,finishedParsing: (newLog: Log) => void) : Promise<File>
    {
        csvFile = await csvFile.text().then((text) => {
            let top = text.split("\n", 2)
            if (top[0].includes("Version,1")) {
                if (top[0][0] != "0")
                {
                    //Crystal Connect gives us a CSV which claims to follow the version 1 standard, but does not.
                    //Completely rewriting the file before sending it to AWS ensures that this is the ONLY place we need to account for this issue.
                    csvFile = new File([this.fixCCCSV(text)], csvFile.name, {type: "text/csv"})
                }
                DataConversions.ExtractType1Logs(csvFile, finishedParsing)
            }
            return csvFile
        })
        return csvFile
    }
    static fixCCCSV(contents: string)
    {
        let events = ["Stop","CalWarning", "CalDue", "BatteryLow", "BatteryStop", "XP3i Zero Cleared"]
        let lines = contents
            .replaceAll("\r", "\n")
            .split("\n")
        let data = false;
        let blankCount = 0;
        let sampleCount = 0;
        lines = lines.map(l => {
            if (l.length == 0)
            {
                blankCount++
                if (blankCount > 2)
                    data = true
                return l
            }
            else {
                blankCount = 0
            }
            if (!data)
                return "0,"+l
            let tmp = l.split(",")
            if (tmp.some(t => events.includes(t) || t.includes("XP3i Zeroed at"))) {
                return ""//Not the cleanest way of handling text showing up in the wrong column, but this section of the code is temporary. Crystal Connect is upgrading to a better format soon.
            }
            tmp.splice(0,0, "1")
            tmp.splice(2, 0, sampleCount == 0 ? "Sample Count" : sampleCount.toString())
            if (sampleCount == 0)
                tmp.push("Events")
            sampleCount++
            return tmp.join(",")
        })
        let blobContent = lines.filter(l => l.length != 0).join("\n")
        return new Blob([blobContent])
    }
    static ExtractType1Logs(csvFile: File, finishedParsing: (newLog: Log) => void) {
        Papa.parse<Array<string>>(csvFile, {
            worker: true,
            download: true,
            header: false,
            dynamicTyping: false,
            complete: (data) => {
                DataConversions.TypeOneLogReady(data, finishedParsing)
            }
        })
    }

    static TypeOneLogReady(data: ParseResult<Array<string>>, finishedParsing: (newLog: Log) => void) {
        let logEntry: Log = {
            gaugeId: 0,//
            archived: false,//
            filename: "",//
            isDemo: false,//
            logId: 0,//
            name: "",//
            onDeviceOnly: false,//
            runTags: "",
            sensors: [{
                logId: 0,
                name: "P1",
                unit: "PSI",
                sensorPosition: 1,
            }],
            startTime: "",//
            endTime: "",//
            uploaded: true,
            deleted: false
        }
        let last : Array<string> = []
        for (let line of data.data) {
            switch (line[0]) {
                case "0":
                    switch (line[1].trim()) {
                        case "Logging Type":
                            logEntry.logType = this.logTypes.find(lT => lT.label == line[2].trim())?.type ?? line[2].trim()
                            break
                        case "Run Tags":
                            logEntry.runTags = line.filter((l, idx) => idx > 1).join(",")
                            break
                        case "Current Unit":
                            logEntry.sensors[0].unit = line[2].trim()
                            break
                        case "Start Date/Time":
                            logEntry.startTime = DateUtils.FormatDatePicker( new Date(line[2].replace("T", " ")))
                            break
                        default:
                            break
                    }
                    break
                case "1":
                    last = line
                    break;
            }
        }
        logEntry.endTime = DateUtils.FormatDatePicker(new Date(last[1].replace("T", " ")))
        finishedParsing(logEntry)
    }
}