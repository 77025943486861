import {DateUtils} from '@ametektci/ametek.stcappscommon';
import {XP3I} from "../Models/Gauges/XP3I";
import {XP3IConfig} from "../Models/Config/XP3IConfig";
import {GaugeBase} from "../Models/GaugeBase";
import {ConfigBase} from "../Models/Config/ConfigBase";
export const DEMOSERIAL = "EXAMPLE"
const sampleXP3IConfig: XP3IConfig = {
    name: "Example_Gauge",
    sensors: {
        p1: {
            sensorType: "pressure",
            units: {
                availableUnits: [
                    "USER",
                    "PSI",
                    "KGCM2",
                    "inHG",
                    "MPa",
                    "kPa",
                    "mmHG",
                    "mmH2O-4C",
                    "mmH2O-60F",
                    "mmH2O-68F",
                    "inH2O-4C",
                    "inH2O-60F",
                    "inH2O-68F",
                    "BAR",
                    "mBAR"
                ],
                currentUnits: [
                    "USER",
                    "PSI",
                    "mmH2O-4C",
                    "inH2O-4C",
                    "BAR",
                ],
                userDefinedUnit: {slope: '1', offset: '0', resolution: 1},
            },
            overPressureCount: 0,
            partNumber: "XP3I-100PSI-DD-DL-005953",
            resolution: 5,
            serialNumber: 921184,
            
            userspan: 1,
            keypadUserspan: false,
            zeroLimit: 20,
            rateEnabled: true,
            readingsToAverage: 3,
        }
    },
    peaksAndAveraging: {
        peaks: true,
        average: true,
        maxSamplesToAverage: 10,
        averageSamples: 4
    },
    automaticShutoff: 40,
    calibration: {
        calibrationDate: DateUtils.AddDaysFromNow(-10),
        calibrationDue: DateUtils.AddDaysFromNow(355),
        calibrationWarningDays: 30,
        calibrationWarningType: 0,
    },
    messageStore: "Sample_Message",
    recordingInterval: 1000,
    runTags: "select,tag,for,run",
    loggingType: "Average",
}
export const DemoData: { gauges: Array<GaugeBase>, config: {[key: string]: ConfigBase} } = {
    config: {
        "EXAMPLE": sampleXP3IConfig
    },
    gauges: [
        new XP3I({
            organizationId: -1,
            name: "Example_Gauge",
            gaugeId: -1,
            connected: true,
            serialNumber: DEMOSERIAL,
            firmwareVersion: "1.0.0",
            dataLogEnabled: true,
            modelId: 2,
            model: {
                name: "XP3I-100PSI-DD-DL",
                shortName: "XP3I",
                liveLoggingCapable: false,
            },
            dataLogPurchased: "NOTPURCHASED",
            calibrationDate: DateUtils.AddDaysFromNow(-10),
            calibrationDue: DateUtils.AddDaysFromNow(355),
            lastSeen: "11/01/2019 00:00:00",
        }),
    ]
};
