import languageStrings from './languageStrings.json';
import moment from "moment";
import 'moment/locale/es-us';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/zh-cn';

export const SupportedLanguages = [
    'english',
    'french',
    'german',
    'spanish',
    'chinese' // simplified
];

export const LanguageLocalStorageKey : "chinese" | "english" | "french" | "german" | "spanish" | "selectedLanguage" = 'selectedLanguage';
export const GraphColorsLocalStorageKey = 'graphColor';
export const XAxisFormatLocalStorageKey = 'xAxisFormat';

type entries = keyof typeof languageStrings
class LocaleStore {
    language: "chinese" | "english" | "french" | "german" | "spanish"
    Strings: {[key in entries]: string};
    decimalSeparator: any;
    constructor() {
        // default to browser settings in case user isn't defined
        this.language = this.getSavedLocalStorageLanguage() || this.determineLanguageFromBrowser();
        //@ts-ignore - I need to initialize the object before it can reach the correct form.
        this.Strings = {}
        this.setStrings()
    }
    setStrings() {
        Object.keys(languageStrings).forEach((key) => {
            // @ts-ignore - I'm having some troubles with the type definition here.
                this.Strings[key as entries] = languageStrings[key as entries][this.language] ?? "Translate Me " + languageStrings[key as entries].english
        })
    }

    setUserProfilePreferences = (user : any) => {
        // first use local storage set (from auth components ) then the user setting, then the browser's settings
        this.language = this.getSavedLocalStorageLanguage() || (!!user && user.language) || this.determineLanguageFromBrowser();

        // set the moment date locale
        this.setDateLocale(this.language);
        // set the strings to use
        this.setStrings()
    };

    getSavedLocalStorageLanguage = () : "chinese" | "english" | "french" | "german" | "spanish" => {
        //@ts-ignore can be null or selectedLanguage, which are not fully handled.
        return localStorage.getItem(LanguageLocalStorageKey);
    }

    determineLanguageFromBrowser = () => {
        // use what the browser uses
        let browserLanguage = window.navigator.language;

        if (browserLanguage.startsWith('en'))
            return 'english';
        if (browserLanguage.startsWith('fr'))
            return 'french';
        if (browserLanguage.startsWith('de'))
            return 'german';
        if (browserLanguage.startsWith('es'))
            return 'spanish';
        if (browserLanguage.startsWith('zh'))
            return 'chinese';

        // if its not a supported settings, use english
        return 'english';
    }

    setDateLocale = (language : string) => {
        let dateLocale = 'en-us';

        if (language === 'english')
            dateLocale = 'en-us';
        else if (language === 'french')
            dateLocale = 'fr';
        else if (language === 'german')
            dateLocale = 'de';
        else if (language === 'spanish')
            dateLocale = 'es';
        else if (language === 'chinese')
            dateLocale = 'zh-cn';

        moment.locale(dateLocale);
    }
    getCurrentLanguage = () => {
        return this.language;
    }
    getNumberLocale = () => {
        return this.decimalSeparator === 'si' ? 'fr-FR' : 'en-US';
    }
    getTextDynamic = (text: string) : string =>
    {
        //textResources.Strings has a static typing due to Typescript's ability to handle json files
        //but Typescript is having a little trouble with the check, hence the verbosity.
        //The name will fill in if there is no translation for the name. Luckily,
        //the name will look weird and hopefully be easy to spot in development.
        if (text in this.Strings)
        {
            return this.Strings[text as keyof typeof this.Strings]
        }
        return text
    }
}

const localeStore = new LocaleStore();
export default localeStore;