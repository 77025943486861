import {useContext, useEffect} from 'react';
import SplashPage from "./splash/SplashPage";
import {
    MessageFeed,
    OAuthLanding,
    updatesStore,
    Products,
    AuthContext,
    NewUser,
    VerifySwitch,
    JoinOrg,
} from '@ametektci/ametek.stcappscommon';
import {UserContext} from "./contexts/UserContext";
import {CrystalControlWeb, CrystalControlWebLayout} from "./global/CrystalControlWeb";
import {
    createBrowserRouter,
    RouteObject,
    RouterProvider,
    Navigate
} from "react-router-dom";
import WelcomePanel from "./mainpanel/WelcomePanel";
import {GaugePage} from "./mainpanel/gaugePage";
import {LanguageContext} from "./contexts/LanguageContext";
import {SignInPage} from "@ametektci/ametek.stcappscommon/src/Authentication/SignInPage";

export default function App() {
    const translate = useContext(LanguageContext)
    const auth = useContext(AuthContext)
    const userContext = useContext(UserContext)
    const isNewUser = userContext.accessLevel === 'newUser'
    //App login state

    useEffect(() => {
        // pre load device agent updates
        updatesStore.loadLatestUpdate(Products.DeviceAgent)
    }, [])

    let authPaths = [{
        path: "/oauth",
        element: <CrystalControlWebLayout/>,
        children: [
            {
                path: "",
                element: <OAuthLanding/>
            },
            {
                path: "*",
                element: <OAuthLanding/>
            }
        ]

    },
        {
            path: "signIn",
            element: <SignInPage />
        }]
    let orgSwitch = {
        path: "/join",
        element: <CrystalControlWebLayout/>,
        children: [
            {
                path: "",
                element: <div><VerifySwitch><NewUser/></VerifySwitch></div>
            },
            {
                path: ":code",
                element: <div><WelcomePanel/><VerifySwitch><JoinOrg returnToAppLabel={translate.getString("returnToCrystalControlWeb")}/></VerifySwitch></div>
            }
        ]
    }

    let paths :  RouteObject[] = [
            ...authPaths,
    ]
    if (auth.signedIn) {
        paths.push(orgSwitch)

        if (isNewUser) {
            paths.push({
                path: "/*",
                element: <Navigate to={"/join"}/>,
            })
        } else {
            paths.push(
                {
                    path: "/*",
                    element:
                        isNewUser ? <Navigate to={"/join"}/> : <CrystalControlWeb/>,
                    children:
                        [ //Included but not yet implemented. A 'Not Found' page will be needed in GaugePage. We also need an outlet.
                            {
                                path: "*",
                                element: <WelcomePanel/>,
                            },
                            {
                                path: "gauge", //catches that one gauge with a serial of "". Should never make it to production.
                                element: <GaugePage/>
                            },
                            {
                                path: "gauge/:serial",
                                element: <GaugePage/>
                            },
                        ]
                }
            )
        }
    } else {
        paths.push({
            path: "/*",
            element: <SplashPage/>,
        })
    }
    const router = createBrowserRouter(paths)
    /*
    The router did not seem to be updating its routes when the context changed. To get around this, I've given the router provider itself a key that will change if the routes need to.
     */

    return (
        <div className="App">
            <RouterProvider router={router} key={userContext.accessLevel + auth.signedIn}/>
            <MessageFeed/>
        </div>
    );
}
