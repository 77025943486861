import {GaugeBase} from "../Models/GaugeBase";
import xp3Icon_active from "../images/gauges/xp3i/icon_active.svg";
import xp3Icon_inactive from "../images/gauges/xp3i/icon_inactive.svg";
import xp2Icon_inactive from '../images/gauges/xp2i/icon_inactive.svg'
import xp2Icon_active from '../images/gauges/xp2i/icon_active.svg'
import hpcIcon_green from "../images/gauges/hpc50/HPC50Icon_green.svg";
import hpcIcon_blue from "../images/gauges/hpc50/HPC50Icon_blue.svg";

interface GaugeIconProps {
    gauge: GaugeBase
}
export function GaugeIcon (props: GaugeIconProps) {
    if (props.gauge.connected)
    {
        switch (props.gauge.model.shortName){
            case "HPC50":
                return hpcIcon_green
            case "XP3I":
                return xp3Icon_active
            case "XP2I":
                return xp2Icon_active
        }
    }
    else
    {
        switch (props.gauge.model.shortName){
            case "HPC50":
                return hpcIcon_blue
            case "XP3I":
                return xp3Icon_inactive
            case "XP2I":
                return xp2Icon_inactive
        }
    }
}