import {useState} from 'react';
import {
    Divider,
    Grid,
    Header,
} from "semantic-ui-react";
import localeStore from "../../language/LocaleStore";
import {ConfigBase} from "../../Models/Config/ConfigBase";
import {PeaksAndAveraging} from "../../Models/Config/PeaksAndAveraging";
import {CalibrationDates} from "../../Models/Config/CalibrationDates";
import {GeneralConfig} from "../../Models/Config/GeneralConfig";
import {LoggingConfig} from "../../Models/Config/LoggingConfig";
import DataLoggingConfig from "../GeneralComponents/DataLoggingConfig";
import GeneralChasisConfig from "../GeneralComponents/GeneralChasisConfig";
import PeaksAndAverages from "../GeneralComponents/PeaksAndAverages";
import GaugeCalibrationDates from "../GeneralComponents/GaugeCalibrationDates";
import {GaugeBase} from "../../Models/GaugeBase";

export interface ChasisConfigProps {
    config: ConfigBase
    gauge: GaugeBase
    handleChange: (newConfig: ConfigBase, valid: boolean) => void
}

export default function ChassisConfig(props: ChasisConfigProps) {
    const [calibrationDatesValid, setCalibrationDatesValid] = useState(true)
    const handleCalibrationDatesChange = (newConfig: CalibrationDates, valid: boolean) => {
        setCalibrationDatesValid(valid)
        props.handleChange({...props.config, calibration: newConfig}, valid)
    }
    const handleGeneralSettingsChange = (newConfig: GeneralConfig) => {
        //At the moment, we are just passing in the entire config object
        //So it makes sense to pass the entire object up.
        props.handleChange(newConfig as ConfigBase, calibrationDatesValid)
    }
    const handleDataLoggingSettingsChange = (newConfig: LoggingConfig) => {
        //At the moment, we are just passing in the entire config object
        //So it makes sense to pass the entire object up.
        props.handleChange(newConfig as ConfigBase, calibrationDatesValid)
    }

    const handlePeaksAndAveragesChange = (peaks: PeaksAndAveraging) => {
        let newConfig = Object.assign({}, props.config)
        newConfig.peaksAndAveraging = peaks
        props.handleChange(newConfig, calibrationDatesValid)
    }

    return (
        <div>
            <Header content={localeStore.Strings.chassis} size='huge'/>

            <Divider hidden/>
            
            <Grid>
                <Grid.Column width={10}>
                    <GeneralChasisConfig config={props.config} handleChange={handleGeneralSettingsChange} />
                    {
                        props.gauge.dataLogEnabled? 
                            <DataLoggingConfig config={props.config} handleChange={handleDataLoggingSettingsChange}/>
                            : null
                    }
                </Grid.Column>
                <Grid.Column width={6}>
                    {props.config.peaksAndAveraging ?
                        <PeaksAndAverages peakAndAverage={props.config.peaksAndAveraging}
                                          onChange={(peaks) => handlePeaksAndAveragesChange(peaks)}/>
                        : undefined
                    }
                    <GaugeCalibrationDates config={props.config.calibration} handleChange={handleCalibrationDatesChange}/>

                </Grid.Column>
            </Grid>
        </div>
    )

}
