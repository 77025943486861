import {Header, Icon, Image, Menu, Transition} from 'semantic-ui-react';
import localeStore from "../language/LocaleStore";
import {useContext, useEffect} from "react";
import {GaugesContext} from "../contexts/GaugesContext";
import {GaugeIcon} from "./GaugeIcon";
import {useNavigate, useParams} from "react-router";

export default function Sidebar() {
    const gaugesContext = useContext(GaugesContext)
    const {serial} = useParams()
    const navigate = useNavigate()
    const ready = (gaugesContext.gaugesLoaded || gaugesContext.demoMode)
    useEffect(() => {
        document.addEventListener("keydown", onKeyDown)
        return () => {
            document.removeEventListener("keydown", onKeyDown)
        }
    })
    const onKeyDown = (e: KeyboardEvent) => {
        //e.key is the key that was pressed. We are interested in the number keys.
        //@ts-ignore
        if (e.altKey && !isNaN(e.key)) {
            if (e.key == '0') {
                goToPage(null)
                return
            }
            let sn = gaugesContext.gauges[+e.key - 1]?.serialNumber
            if (sn != null) {
                goToPage(sn)
            }
        }
    }
    const goToPage = (page: string | null) => {
        if (page == null) {
            if (serial == undefined)
                return
            navigate("/")
            return;
        }
        if (page != serial)
            navigate(`/gauge/${page}`)

    }
    useEffect(() => {
        if (gaugesContext.gaugesLoaded)
            goToPage(null)
    }, [gaugesContext.demoMode])
    return (
        <Transition.Group as={Menu} fluid vertical duration={200} animation='vertical flip' icon={false}
                          pointing={"right"}>

            <Menu.Item header>
                <Header content={localeStore.Strings.applicationName} size='small'/>
            </Menu.Item>

            <Menu.Item onClick={() => goToPage(null)} name='Welcome Page'
                       active={serial == undefined}>
                <Icon name='home' size='small' color='blue'/>
                {localeStore.Strings.home}
            </Menu.Item>

            <Menu.Item header>
                <Header content={localeStore.Strings.devices} size='small'/>
            </Menu.Item>

            {gaugesContext.demoMode ?
                <Menu.Item>
                    <Header content={localeStore.Strings.showingDemoData} color='yellow'
                            size='tiny' textAlign='center'/>
                </Menu.Item>
                : null}

            {gaugesContext.gauges.map(g => (
                <Menu.Item
                    name={'device' + g.serialNumber}
                    onClick={() => goToPage(g.serialNumber)}
                    active={serial === g.serialNumber}
                    key={g.serialNumber}
                    icon={<Image src={GaugeIcon({gauge: g})}  style={{"float":"right", "height":"2.5em"}}/>}
                    content={<div><b>{g.name ? g.name : g.model.name}</b>
                        <Header.Subheader>
                            {g.serialNumber}
                        </Header.Subheader>
                </div>}
                    serial={g.serialNumber}
                >
                </Menu.Item>)) //The Serial property exists to make it easier to find a gauge in automated testing It has no additional impacts.
            }

            {gaugesContext.gauges.length < 1 ? ready?
                <Menu.Item disabled content={localeStore.Strings.noDevicesFound}/>
                :
                <Menu.Item loading={!ready} disabled content={localeStore.Strings.loading} />
                : null
            }

        </Transition.Group>
    )
}
