import {GaugeContext} from "../../contexts/GaugeContext";
import {useContext, useEffect, useState} from "react";
import {Button, Checkbox, Form, Header, Message, Modal, Table} from "semantic-ui-react";
import {LogsContext} from "../../contexts/LogsContext";
import {LanguageContext, Translatable} from "../../contexts/LanguageContext";
import moment from "moment/moment";

export function ConfirmDeleteLogs() {
    const translate = useContext(LanguageContext)
    const gaugeContext = useContext(GaugeContext)
    const logsContext = useContext(LogsContext)
    const relevantLogs = logsContext.gaugeLogs[gaugeContext.Gauge.serialNumber]?.filter(l => l.logId != 0 && l.logId != null) ?? []
    const [toDelete, setToDelete] = useState<Array<number>>(relevantLogs.map(l => l.logId))
    const [open, setOpen] = useState(false)
    const addAll = () => {
        setToDelete(relevantLogs.map(l => l.logId))
    }
    useEffect(() => {
        console.log("Relevant logs changed")
        addAll()
    }, [relevantLogs.length])
    const addLog = (logId: number) => {
        setToDelete(toDelete => toDelete.concat([logId]))
    }
    const removeLog = (logId: number) => {
        setToDelete(toDelete => toDelete.filter(td => td != logId))
    }
    const remove = () => {
        toDelete.map(td => gaugeContext.deleteLog(td))
        setOpen(false)
    }
    return (
        <Modal
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            open={open}
            trigger={<Button fluid color={"blue"} content={translate.getString("removeFromGauge")}
                             onClick={() => setOpen(true)}/>}
        >
            <Modal.Content>
                <Modal.Header><Header>{translate.getString("removeFromGauge")}</Header></Modal.Header>
                <Message positive>{translate.getString("removeFromGaugeInfo")}</Message>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                <Checkbox checked={toDelete.length == relevantLogs.length}
                                          onClick={() => toDelete.length == relevantLogs.length ? setToDelete([]) : addAll()}/>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {translate.getString("logName")}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {translate.getString("loggingType")}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {translate.getString("startTime")}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {relevantLogs.map(l => {
                            let checked = toDelete.some(td => td == l.logId)
                            let onChange = checked ? removeLog : addLog
                            return (<Table.Row key={l.logId}>
                                <Table.Cell>
                                    <Form.Checkbox checked={checked} onChange={() => onChange(l.logId)}/>
                                </Table.Cell>
                                <Table.Cell>
                                    {l.name ?? l.filename}
                                </Table.Cell>
                                <Table.Cell>{translate.getString("logType_" + l.logType as Translatable)}</Table.Cell>
                                <Table.Cell>{moment(l.startTime).format("MM/DD/YYYY HH:mm")}</Table.Cell>
                            </Table.Row>)
                        })}
                    </Table.Body>
                </Table>
            </Modal.Content>
            <Modal.Actions>
                <Button color={"blue"} content={translate.getString("accept")} onClick={remove}/>
                <Button color={"red"} content={translate.getString("cancel")} onClick={() => setOpen(false)} />
            </Modal.Actions>
        </Modal>
    )
}