import {useContext, useEffect, useState} from 'react';
import {Button, Confirm, Divider, Grid} from 'semantic-ui-react';
import localeStore from "../language/LocaleStore";
import _ from 'lodash';
import {ConfigBase} from "../Models/Config/ConfigBase";
import MultiSensorLayout from "./MultiSensorLayout/MultiSensorLayout";
import SingleSensorLayout from "./SingleSensorLayout/SingleSensorLayout";
import {GaugeContext} from "../contexts/GaugeContext";
import {AccessWarnings} from "./GeneralComponents/AccessWarnings";

export interface ConfigurationModalProps {
    onClose: () => void
}
export default function ConfigurationModal(props: ConfigurationModalProps) {
    const gaugeContext = useContext(GaugeContext)
    const [loading, setLoading] = useState(false)
    const [config, setConfig] = useState(_.cloneDeep(gaugeContext.Config))
    const [hasError, setHasError] = useState(false)
    const [confirmCloseVisible, setConfirmCloseVisible] = useState(false)
    useEffect(() => {
        setConfig(_.cloneDeep(gaugeContext.Config))
        setHasError(false)
        setConfirmCloseVisible(false)
        setLoading(false)
    }, [gaugeContext.Gauge!.connected, gaugeContext.Config])
    const close = (showConfirm = true) => {
        if (showConfirm && _.isEqual(config, gaugeContext.Config))
            setConfirmCloseVisible(true)
        else {
            setConfig(_.cloneDeep(gaugeContext.Config))
            props.onClose()
        }
    };
    const cancelClose = () => setConfirmCloseVisible(false);
    const onConfigUpdate = (newConfig: ConfigBase, valid: boolean) => {
        setConfig(newConfig)
        setHasError(!valid)
    }
    const updateGauge = () => {
        setLoading(true)
        gaugeContext.updateConfig(config!, gaugeContext.Gauge.locked ? gaugeContext.lock.password : undefined)
    }

    // manually set the menu vertical or horizontal - semantic doesn't have this
    return (
        <Grid columns={"equal"}>
            <Grid.Row>
                <Grid.Column>
                    <AccessWarnings/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    {config && config.sensors ?
                        gaugeContext.Gauge.singleSensor ?
                            <SingleSensorLayout
                                config={config}
                                onConfigUpdate={onConfigUpdate}
                                gauge={gaugeContext.Gauge}
                            />
                            : <MultiSensorLayout
                                config={config}
                                onConfigUpdate={onConfigUpdate}
                                gauge={gaugeContext.Gauge}
                            />
                        : undefined
                    }
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Divider/>
                    <div style={{textAlign: "right"}}>
                        <Confirm open={confirmCloseVisible} onCancel={cancelClose} size='tiny'
                                 cancelButton={localeStore.Strings.no} confirmButton={localeStore.Strings.yes}
                                 content={localeStore.Strings.discardChangesConfirmation}
                                 onConfirm={() => close(false)}/>
                        <Button disabled={loading} onClick={() => close(false)} content={localeStore.Strings.cancel}/>

                        <Button color='blue'
                                disabled={loading || hasError || _.isEqual(config, gaugeContext.Config)}
                                loading={loading} onClick={updateGauge}
                                content={localeStore.Strings.updateDevice}/>


                    </div>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}