import {Button, Container, Form, InputOnChangeData, Message} from "semantic-ui-react";
import localeStore from "../../language/LocaleStore";
import {ChangeEvent, useContext, useState} from "react";
import {GaugeContext} from "../../contexts/GaugeContext";
import {LogContext} from "../../contexts/LogContext";

export interface AddRunTagProps {
    loading: boolean | string
    setLoading: (loading: boolean | string) => void
}

export function AddRunTag(props: AddRunTagProps) {
    const gaugeContext = useContext(GaugeContext)
    const logContext = useContext(LogContext)
    const runTags = logContext.log.runTags ? logContext.log.runTags.split(",") : []
    const [newFieldsVisible, setNewFieldsVisible] = useState(false)
    const [addLabelName, setAddLabelName] = useState('')
    const [addLabelError, setAddLabelError] = useState<string | null>(null)
    const toggleAddNew = () => {
        setNewFieldsVisible(!newFieldsVisible)
    };

    const onAddLabelNameChange = (e: ChangeEvent, {value}: InputOnChangeData) => {
        let addLabelError = null;

        //Commas won't behave as expected, so it's better UX just to keep them out.
        value = value.replace(",", "")
        value = gaugeContext.toAcceptedString(value)

        if (runTags.includes(value))
            addLabelError = localeStore.Strings.runTagAlreadyExists;
        setAddLabelName(value)
        setAddLabelError(addLabelError)
    };

    const addTag = () => {
        props.setLoading('add')

        let newRunTags = runTags.concat([addLabelName]);

        logContext.updateLogTags(newRunTags).then(() => {
            props.setLoading(false)
            setAddLabelName('')
        });
    };
    if (logContext.log.logId == 0 || logContext.log.logId == null)
        return (
            <Button content={localeStore.Strings.notUploaded} color='grey' fluid onClick={toggleAddNew}
                    disabled={true} loading={props.loading != false}/>
        )
    if (newFieldsVisible)
        return (
            <Form onSubmit={addTag}>
                <Form.Input label={localeStore.Strings.name}
                            id='addLabelNameInput'
                            onChange={onAddLabelNameChange}
                            value={addLabelName}/>

                {!!addLabelError ?
                    <Message content={addLabelError} size='small'/>
                    :
                    <Container textAlign='right'>
                        <Button content={localeStore.Strings.add} color='green'
                                loading={props.loading === 'add'} disabled={!addLabelName}/>
                    </Container>
                }

            </Form>
        )
    return (
        <Button content={localeStore.Strings.addRunTag} color='green' fluid onClick={toggleAddNew}
                disabled={props.loading != false} loading={props.loading === 'add'}/>
    )
}