import EventEmitter from 'events';
import {HubConnection, HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
import {messageQueue} from '@ametektci/ametek.stcappscommon';
import {GetEnvironmentSetting} from "../utils/EnvironmentSettings";
import {ConfigBase} from "../Models/Config/ConfigBase";
import localeStore from "../language/LocaleStore";
import {tokenFactory} from "../contexts/GaugesContext";

class DeviceHubConnection extends EventEmitter{
    connected: boolean
    //I am exposing this now so that context wrappers can subscribe to it.
    connection: HubConnection | null;
    constructor() {
        super();
        this.connected = false;
        this.connection = null;
    }
    alertStatusChange = () => {
        this.emit("ConnectionStatusChange")
        console.log("status change signal sent")
    }
    fixConnection = async () => {
        await this.connection?.invoke("DeclareConnectionType", "CrystalControlWeb")
        this.alertStatusChange()
    }
    startConnection = async () => {
        await navigator.locks.request("connectToSignalR", {mode: "exclusive"}, async () => {
            await this.initHubConnection();
        })
    }
    initHubConnection = async () => {
        if (this.connected && this.connection != null)
        {
            console.log("Connection exists and needs to stop existing.")
            this.connected = false;
            
            if (["Connecting", "Connected","Reconnecting"].some(s => s == this.connection?.state))
                await this.connection?.stop()
            this.connection = null
            console.log("Connection destroyed.")
        }
        console.log("Connection is being created")
        this.connection = new HubConnectionBuilder().withUrl(GetEnvironmentSetting('CcwApiUrl')+'/deviceSyncHub', { accessTokenFactory: async () => {
            return await tokenFactory()
            }})
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Information).build();
        this.connection.onclose(this.alertStatusChange)
        this.connection.onreconnecting(this.alertStatusChange)
        this.connection.onreconnected(this.fixConnection)
        this.connection.onreconnecting(this.alertStatusChange)
        try {
            await this.connection.start()
            // setup event handlers
            this.connected = true;
            await this.fixConnection()
            console.log("Connection started")
        }
        catch (error)
        {
            console.log('Unable to connect to DeviceHub', error);
            messageQueue.sendError(localeStore.Strings.unableToConnectToDeviceHub);
            this.connected = false;
        }
        this.connection.onclose(() => {
            this.connected = false;
            console.log("Connection stopped.")
        });
    };

    updateDeviceConfiguration(serialNumber : string, config : ConfigBase, model: string, password: string | undefined) {
        deviceHubConnection.connection?.invoke("UpdateConfiguration", `${serialNumber}`, config, model, {password: password}).catch((err) => {
            messageQueue.sendError(localeStore.Strings.errorUpdatingGauge);
            console.log('Error while attempting to update gauge.', config, err);
        })
    }

    requestUploadLog(deviceSerialNumber : string, logFilename : string) {
        deviceHubConnection.connection?.invoke("RequestLogUpload", `${deviceSerialNumber}`, logFilename).catch((err) => {
            messageQueue.sendError(localeStore.Strings.errorUploadingLog);
            console.log('Error while attempting to upload log', deviceSerialNumber, logFilename, err);
        })
    }

    //calls our method in ccw api
    requestDeleteLog(deviceSerialNumber : string,logId:number, logFilename : string,password:string | undefined) {
        deviceHubConnection.connection?.invoke("RequestLogDeletion", `${deviceSerialNumber}`,logId, logFilename,{password:password}).catch((err) => {
            messageQueue.sendError(localeStore.Strings.errorDeletingLog);
            console.log('Error while attempting to delete log', deviceSerialNumber,logId, logFilename, err);
        })
    }

    enableDataLoggingOnDevice(deviceSerialNumber : string, series: string) {
        deviceHubConnection.connection?.invoke("EnableDataLogging", `${deviceSerialNumber}`, series).catch((err) => {
            messageQueue.sendError(localeStore.Strings.errorEnablingDevice);
            console.log('An Error Occurred while Enabling DataLoggerXP', deviceSerialNumber, err);
        })
    }
    requestFirmwareUpgrade(serialNumber:string,model:string,latestFirmwareVersion:string){
        deviceHubConnection.connection?.invoke("RequestFirmwareUpgrade",serialNumber,model,latestFirmwareVersion).catch((error)=>{
            messageQueue.sendError(localeStore.Strings.errorUpdatingFirmware)
            console.log("error while attempting to upgrade firmware",error)
        })
    }
    updatePassword(serialNumber: string, newPass: string, oldPass: string) {
        console.log("setting new password for", serialNumber)
        deviceHubConnection.connection?.invoke("SetPassword", serialNumber, oldPass, newPass).catch((error) => {
            messageQueue.sendError(localeStore.Strings.errorUpdatingPassword)
            console.log("Error while updating password", error)
        })
    }

    ChallengePassword(serialNumber: string, password: string, passwordNonce: string) {
        console.log("Checking serial number for", serialNumber)
        deviceHubConnection.connection?.invoke("ChallengePassword", serialNumber, password, passwordNonce)
            .catch((error) => {
                console.log("Error checking password", error)
                messageQueue.sendError('Error while checking password')
            })
    }
}

const deviceHubConnection = new DeviceHubConnection();
export default deviceHubConnection;