import {Checkbox, Grid, Header, Input, Message, Segment} from "semantic-ui-react";
import localeStore from "../../language/LocaleStore";
import UserspanWizard from "./UserspanWizard";
import {SensorConfig} from "../../Models/Config/SensorConfig";
import {useContext, useState} from "react";
import {GaugeContext} from "../../contexts/GaugeContext";
import {LanguageContext} from "../../contexts/LanguageContext";
export interface UserSpanSegmentProps {
    sensor: SensorConfig
    onUserSpanChange(newSensor: SensorConfig, valid: boolean): void
}
export default function UserSpanSegment(props: UserSpanSegmentProps)
{
    const translate = useContext(LanguageContext)
    const gaugeContext = useContext(GaugeContext)
    const disableControls = gaugeContext.lock.lockConfig || !gaugeContext.Gauge.connected
    const [userspanError, setUserspanError] = useState<string | null>(null)

    const isValid = () => {
        return !userspanError
    }
    const onUserSpanChange = (newSpan: string) => {
        let newConfig = Object.assign({}, props.sensor)

        let value = Number(newSpan);
        let valid = 0.95 <= value && value <= 1.05;
        if (valid)
            setUserspanError(null)
        else 
            setUserspanError(localeStore.Strings.userspanValidation)
        newConfig.userspan = value
        props.onUserSpanChange(newConfig, isValid())
    }
    const onUserSpanWizard = (newSpan : number) => {
        setUserspanError(null)
        props.onUserSpanChange({...props.sensor, userspan: newSpan}, true)
    }
    if (props.sensor.userspan == null)
        return null //Don't display if the userspan is missing.
    const onToggleKeypadUserspan = () => {
        props.onUserSpanChange({
            ...props.sensor, keypadUserspan: !props.sensor.keypadUserspan
        }, isValid())
    }
    return (
        <Segment>
            <Header content={localeStore.Strings.calibrationData} />

            <Header content={localeStore.Strings.userspan} dividing size='small'/>
                <Grid>
                    <Grid.Column width={8}>
                        <Input fluid type='number' name='userspan' error={!!userspanError}
                               onChange={(e, {value}) => onUserSpanChange(value)} value={props.sensor.userspan.toFixed(4)} step='0.0001' disabled={disableControls}/>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <UserspanWizard currentUserspan={props.sensor.userspan} onComplete={( data ) => onUserSpanWizard(data)} disabled={disableControls}/>
                    </Grid.Column>
                </Grid>
            <Message error content={userspanError} hidden={!userspanError} />
            <Checkbox 
                label={translate.getString("enableKeypadUserspan")} 
                onClick={onToggleKeypadUserspan} 
                checked={props.sensor.keypadUserspan} 
                disabled={disableControls}
            />
        </Segment>
    )
}