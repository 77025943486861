import {DateUtils} from '@ametektci/ametek.stcappscommon';
import { Icon, Label, Popup, Table} from 'semantic-ui-react';
import localeStore from "../language/LocaleStore";
import {useContext} from "react";
import {LanguageContext} from "../contexts/LanguageContext";
import {GaugeContext} from "../contexts/GaugeContext";

export default function GaugeInfoTable() {
    const gaugeContext = useContext(GaugeContext)
    let gauge = gaugeContext.Gauge
    let translate = useContext(LanguageContext)
    let dueStatus = DateUtils.DueStatus(gauge.calibrationDue,gaugeContext.Config?.calibration.calibrationWarningDays ?? 30);
    return (
        <Table basic='very' celled>
            <Table.Body>
                <Table.Row>
                    <Table.Cell width={8}>{localeStore.Strings.serialNumber}</Table.Cell>
                    <Table.Cell width={8}>{gauge.serialNumber}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>{translate.getString("model")}</Table.Cell>
                    <Table.Cell>{gauge.model.name}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>{localeStore.Strings.firmwareVersion}</Table.Cell>
                    <Table.Cell>
                        {gaugeContext.updateAvailable ?  <span><Label color={"red"} content={translate.getString("upgradeAvailable")} />  </span>: null }
                       
                        {gauge.firmwareVersion.split(".", 4).filter((f, idx) => idx < 3).join(".")}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>{localeStore.Strings.calibrationDate}</Table.Cell>
                    <Table.Cell content={DateUtils.FormatDate(gauge.calibrationDate)}/>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>{localeStore.Strings.calibrationDue}</Table.Cell>
                    <Table.Cell error={dueStatus === 'pastDue'} warning={dueStatus === 'warn'}>
                        {dueStatus === 'warn' ? <Popup trigger={<Icon name='wait'/>} position='right center'
                                                       content={localeStore.Strings.calibrationIsDueSoon}/> : null}
                        {dueStatus === 'pastDue' ?
                            <Popup trigger={<Icon name='warning circle'/>} position='right center'
                                   content={localeStore.Strings.calibrationIsDue}/> : null}
                        { gauge.calibrationDue == "" || gauge.calibrationDue == null? translate.getString("notSet") : DateUtils.FormatDate(gauge.calibrationDue)}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>{localeStore.Strings.timeLastConnected}</Table.Cell>
                    <Table.Cell>{gauge.connected ? localeStore.Strings.now :
                        DateUtils.FormatTimeSince(gauge.lastSeen)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>{localeStore.Strings.dataLogger}</Table.Cell>
                    <Table.Cell>{gauge.dataLogEnabled ? localeStore.Strings.enabled : localeStore.Strings.disabled}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        {localeStore.Strings.lockedStatus}
                    </Table.Cell>
                    <Table.Cell>
                        <p>
                            {
                                !gauge.connected ? localeStore.Strings.connectToManage : gauge.locked ? localeStore.Strings.locked : localeStore.Strings.unlocked
                            }
                        </p>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    )
}
